import { useLDClient } from 'launchdarkly-react-client-sdk';

import { identifyUserForLaunchDarkly } from '@/utils/identifyUserForLaunchDarkly';
import { useEffect, useState } from 'react';
import useUser from './user/useUser';

/**
 * Should only be used from App.tsx. Checks for user change and updates the feature flag client to ensure we are showing the correct flags for the given user
 *
 * @returns loading
 */
export const useUserFeatureFlagsSync = () => {
  const { data: user } = useUser();
  const ldClient = useLDClient();
  const [isIdentified, setIsIdentified] = useState(!!ldClient?.getContext().name);

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    if (!!user !== isIdentified) {
      (async () => {
        await identifyUserForLaunchDarkly(ldClient, user);
        setIsIdentified(!!ldClient?.getContext().name);
      })();
    }
  }, [isIdentified, ldClient, user]);

  return {
    loading: !!user && !isIdentified
  };
};
