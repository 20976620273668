import { useQuery, useQueryClient } from '@tanstack/react-query';

import USER_ROLES from '@/constants/userRoles';
import AuthService from '@/services/authentication.service';
import { UserService } from '@/services/user.service';
import type { IUserProfileResponse } from '@/types';
import { Logger } from '@/utils/logger';
import { QUERY_KEY_USER_PROFILE_DATA } from './useGetUserProfile';

const logger = new Logger('useUser');

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  connections: {
    customer?: {
      id: number;
      code: string;
    };
    patient?: {
      code: string;
    };
    // TODO: More connections can be added here when the use case arises
  };
}

const useUser = () => {
  const queryClient = useQueryClient();

  return useQuery<User | null>({
    staleTime: 1000 * 30, // 30 seconds, can increase after testing
    placeholderData: () => {
      const localStorageUser = AuthService.getUser()?.user;
      if (!localStorageUser) {
        return null;
      }

      return {
        id: localStorageUser.id,
        email: localStorageUser.email,
        firstName: localStorageUser.first_name,
        lastName: localStorageUser.last_name || '',
        roleId: localStorageUser.role_id,
        connections: {}
      };
    },
    queryKey: ['user'],
    queryFn: async () => {
      // TODO: When token is stored in Zustand, we can use the enabled check instead of returning null here
      if (!AuthService.getUser()?.token) {
        return null;
      }

      // TODO: Turn user request into a fetchQuery
      const userResponse = UserService.fetchUser();
      const userProfileResponse = queryClient.fetchQuery<IUserProfileResponse>({
        queryKey: [QUERY_KEY_USER_PROFILE_DATA]
      });

      const responses = await Promise.all([userResponse, userProfileResponse]);

      const userData = responses[0].data;
      const userProfileData = responses[1];

      if (!userData || !userProfileData) {
        logger.warn('useData or userProfileData not returned');
        return null;
      }

      return {
        id: userProfileData.user.id,
        email: userProfileData.user.email,
        firstName: userData.first_name,
        lastName: userProfileData.user.last_name,
        roleId: userData.role_id,
        connections: {
          ...(userData.role_id === USER_ROLES.patient && {
            customer: {
              id: userProfileData.patient.id,
              code: userData.patNumber as string
            }
          }),
          ...(userData.role_id === USER_ROLES.patient &&
            userProfileData.patient?.client_code && {
              patient: {
                code: userProfileData.patient.client_code
              }
            })
        }
      };
    }
  });
};

export default useUser;
