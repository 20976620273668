import { getDiscountReasons } from '@/services/data.service';
import { useQuery } from '@tanstack/react-query';

export function usePatientDiscountReasonData() {
  return useQuery({
    queryKey: ['patientDiscountReasons'],
    queryFn: async () => {
      const response = await getDiscountReasons();
      return response.data;
    }
  });
}

export default usePatientDiscountReasonData;
