import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

export const GET_AWAITING_ORDERS_COUNT_QUERY_KEY = ['awaitingOrdersCount'];

/**
 * Retrieves the count of awaiting orders
 */
function useGetAwaitingOrdersCount() {
  return useQuery({
    queryKey: GET_AWAITING_ORDERS_COUNT_QUERY_KEY,
    queryFn: async () => {
      try {
        const response = await OrderService.getAwaitingOrdersCount();
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch awaiting orders: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetAwaitingOrdersCount;
