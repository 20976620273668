import { FF_ENABLE_CONCESSION_DISCOUNT_BANNER } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { useUserProfileData } from '@/hooks/user/useGetUserProfile';
import PasswordReset from '@/pages/profile/PasswordReset';
import ProfileFormLoadingSkeleton from '@/pages/profile/ProfileFormLoadingSkeleton';
import BackButton from '@/ui-library/backButton/BackButton';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { Alert, AlertTitle, Box, Link, Stack, Typography, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { lazy, useEffect, useState } from 'react';
import ShippingAddressForm from './ShippingAddressForm';
import ShippingAddressLoadingSkeleton from './ShippngAddressLoadingSkeleton';

const ProfileForm = lazy(() => import('@/pages/profile/ProfileForm'));

interface IAlert {
  severity: 'success' | 'error';
  message: string;
}

export const AlertResponse = styled(Alert)<{ type: 'success' | 'error' }>(({ theme, type }) => ({
  backgroundColor: type === 'success' ? theme.palette.success.light : theme.palette.error.light,
  color: type === 'success' ? theme.palette.success.dark : theme.palette.error.dark,
  fontWeight: theme.typography.fontWeightBold,
  margin: theme.spacing(4, 0),
  width: '100%',
  '& .MuiAlert-message': {
    padding: theme.spacing(2.5, 0)
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '375px'
  }
}));

const ConcessionBanner = ({ expiryDate }: { expiryDate: string }) => (
  <Alert
    severity="info"
    icon={<SellOutlinedIcon />}
    sx={{
      width: '100%',
      my: 6
    }}
    data-testid="concession-discount-banner"
  >
    <AlertTitle color="info">Concession status active and discounts applied</AlertTitle>
    <Typography variant="inherit">Concession card expiry date: {expiryDate}</Typography>
    <Typography variant="inherit">
      <Link
        href="https://support.umeds.com.au/hc/en-au/articles/10802598035599-Concession-benefits"
        underline="always"
        color="inherit"
      >
        Learn more
      </Link>{' '}
      about uploading a new concession card.
    </Typography>
  </Alert>
);

export default function PatientProfile() {
  const { data, isLoading, isSuccess, isError, getUserState } = useUserProfileData();
  const [alert, setAlert] = useState<IAlert>({ severity: 'success', message: '' });
  const { flags } = useFeatureFlags();

  const isConcessionEnabled = flags[FF_ENABLE_CONCESSION_DISCOUNT_BANNER];
  const shouldShowConcessionBanner = (isConcessionEnabled && data?.concession_status?.valid) || false;
  const concessionExpiryDate = data?.concession_status?.expiry_date
    ? DateTime.fromISO(data.concession_status.expiry_date).toFormat('MMM dd, yyyy')
    : '-';

  const handleClose = () => {
    setAlert({ severity: 'success', message: '' });
  };

  const profileFormContent = () => {
    if (isLoading) {
      return <ProfileFormLoadingSkeleton />;
    }
    if (isSuccess && data) {
      return <ProfileForm data={data} />;
    }
    return null;
  };

  useEffect(() => {
    if (!data && !isLoading && isError) {
      setAlert({ severity: 'error', message: 'Error retrieving profile data' });
    }
  }, [data, isLoading, isError]);

  return (
    <Box>
      <Stack direction="row">
        <BackButton />
        <Typography variant="h5" component="h1" color="primary.main" lineHeight={(theme) => theme.spacing(10)}>
          Profile
        </Typography>
      </Stack>
      {shouldShowConcessionBanner && <ConcessionBanner expiryDate={concessionExpiryDate} />}
      {alert.message && (
        <AlertResponse severity={alert.severity} type={alert.severity} onClose={handleClose}>
          {alert.message}
        </AlertResponse>
      )}

      {profileFormContent()}
      <Typography variant="h5" component="h1" color="primary.main" lineHeight={(theme) => theme.spacing(10)} pt={10}>
        Shipping Address
      </Typography>
      {isLoading ? (
        <ShippingAddressLoadingSkeleton />
      ) : (
        data && <ShippingAddressForm userData={data} getUserState={getUserState} />
      )}
      <PasswordReset />
    </Box>
  );
}
