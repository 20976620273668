import { createSvgIcon } from '@mui/material';

const CheckIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1254_5201)">
      <circle cx="12" cy="12" r="12" fill="#FF9845" />
      <path
        d="M9.32923 15.2293L5.85423 11.7543L4.6709 12.9293L9.32923 17.5876L19.3292 7.5876L18.1542 6.4126L9.32923 15.2293Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1254_5201">
        <rect width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'Check'
);

export default CheckIcon;
