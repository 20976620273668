import type { ReactNode } from 'react';

import { ContentContainer } from './Onboarding.styles';

export type OnboardingContentProps = {
  children: ReactNode;
};

function OnboardingContent({ children }: OnboardingContentProps) {
  return <ContentContainer>{children}</ContentContainer>;
}

export default OnboardingContent;
