import { Box, styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

export const OrderBreakdownContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#EBEEEF',
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2)
  }
}));

export const ProductsContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

export const FeesContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2)
}));

export const ProductItemContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

export const TotalContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2)
}));
