// Note: Consolidating tech-debt until clear theme upstream interface
// is decided for tertiary and secondary colors.
// JIRA: https://montugroup.atlassian.net/browse/MM-1777

export const outOfBandThemeColors = {
  alternaleaf: {
    skyBlue: '#CEEDFF',
    teal: '#194457',
    darkTeal: '#455A64',
    deepRed: '#C53B00',
    lightPurple: '#498CCE',
    pastelYellow: '#F3EEE1',
    deepPink: '#FFC8B9',
    lightBlue: '#2085CB',
    grassGreen: '#008000',
    lightGray: '#CFD2D9',
    faintPurple: '#E9CFE9',
    lightGreen: '#B5F3D1',
    redOrange: '#FFC8B9',
    statusGray: '#E7E7E7',
    deepNavy: '#181B25'
  },
  uMeds: {
    warmWhite: '#FFFCF2',
    merino: '#F2EFE4',
    timberGreen50: '#EBEEEF',
    timberGreen100: '#B4BFC3',
    accordionHeaderColors: {
      background: '#F6F4EC',
      borderTopColor: '#e8e4d1'
    }
  },
  montu: {
    accordionHeaderColors: {
      background: '#E8E4D1',
      borderTopColor: '#E8E4D1'
    }
  }
};
