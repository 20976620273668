import useCustomer from '@/hooks/user/useCustomer';
import { Grid, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { InfoItem } from './InfoItem';
import {
  StyleSubHeading,
  StyledGridItem,
  StyledHeading,
  StyledTokenInputContainer,
  StyledTopHeading,
  StyledWrapper
} from './LandingStyles';
import ApprovalIcon from './TempLandingIcons/ApprovalIcon';
import CheckIcon from './TempLandingIcons/CheckIcon';
import DocumentsIcon from './TempLandingIcons/DocumentsIcon';
import OrdersIcon from './TempLandingIcons/OrderIcon';
import ScriptsIcon from './TempLandingIcons/ScriptsIcon';
import { TokenInput } from './TokenInput';
import { TokenLoading } from './TokenLoading';

export interface SubmitTokenData {
  token: string;
  agreement: boolean;
}

export function Landing() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openTokenDialog, setOpenTokenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false); // todo: this loading state is temporary - will be handled by the query
  const { data: customer } = useCustomer();
  // temp example of hook usage
  // eslint-disable-next-line no-console
  console.log('isExternal', customer?.isExternal);

  const handleTokenSubmit = async (formData: SubmitTokenData) => {
    // todo: hook up with erx mock + reactQuery
    setLoading(true); // Start loading

    try {
      /* mock the response time for animation to show */
      await new Promise((resolve) => setTimeout(resolve, 3000));
      console.log('formData ->', formData);
    } catch (error) {
      console.error('Error submitting token:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledWrapper>
      <Grid container>
        <StyledGridItem item xs={12} md={6}>
          <StyledTopHeading variant="h3" fontWeight={600}>
            Medication at your fingertips
          </StyledTopHeading>
          <Typography variant="subtitle2" my={6}>
            Submit your prescription and let us handle the rest.
          </Typography>

          {isMobile && (
            <StyledTokenInputContainer>
              <Stack spacing={4} height="100%">
                {loading ? (
                  <TokenLoading />
                ) : (
                  <>
                    <Typography variant="subtitle2" fontWeight={600}>
                      Enter your script details
                    </Typography>
                    <TokenInput
                      setOpenTokenDialog={setOpenTokenDialog}
                      openTokenDialog={openTokenDialog}
                      handleTokenSubmit={handleTokenSubmit}
                    />
                  </>
                )}
              </Stack>
            </StyledTokenInputContainer>
          )}

          <Typography variant="h3" fontWeight={600} marginTop={{ xs: 0, md: 150 }} marginBottom={{ xs: 8.5, md: 12 }}>
            How it works
          </Typography>

          <Stack spacing={8}>
            <InfoItem
              icon={<ScriptsIcon />}
              title="Prepare your documents"
              description={
                <>
                  To order your medication with uMeds, we’ll need your{' '}
                  {/* todo: find a better way to have semantic elements (button/link) work in this context, re hover state */}
                  <Link
                    component="button"
                    color="inherit"
                    onClick={() => setOpenTokenDialog(true)}
                    sx={{
                      '&.MuiTypography-root:hover': {
                        color: `${theme.palette.common.black}`
                      }
                    }}
                  >
                    eScript token
                  </Link>{' '}
                  and{' '}
                  <Link
                    color="inherit"
                    href="https://support.umeds.com.au/hc/en-au/articles/10869503095695-Regulations-and-approvals"
                  >
                    SAS/AP approval letter
                  </Link>
                  .
                </>
              }
            />

            <InfoItem
              icon={<DocumentsIcon />}
              title="We review your script"
              description={
                <>
                  We’ll check if we have your prescribed medication in stock. If we do, we’ll guide you to sign up. If
                  not, we’ll let you know why—keeping you informed at every step.
                </>
              }
            />

            <InfoItem
              icon={<ApprovalIcon />}
              title="Upload your approval letter"
              description={
                <>Provide the SAS/AP approval letter from your doctor so that we can dispense your medication. </>
              }
            />

            <InfoItem
              icon={<OrdersIcon />}
              title="Place your order"
              description={
                <>
                  Purchase your medication in just a few clicks. We will deliver it to your door, quickly and securely.
                </>
              }
            />
          </Stack>

          <StyledHeading variant="h3" fontWeight={600}>
            Why uMeds?
          </StyledHeading>
          <StyleSubHeading variant="h5" fontWeight={600}>
            We streamline your prescription delivery.
          </StyleSubHeading>

          <Stack spacing={6}>
            <InfoItem icon={<CheckIcon />} iconSize={20} description={<>Your prescriptions stored in one place.</>} />

            <InfoItem
              icon={<CheckIcon />}
              iconSize={20}
              description={<>Your medication dispensed by trusted pharmacies anywhere in Australia.</>}
            />

            <InfoItem icon={<CheckIcon />} iconSize={20} description={<>Your orders delivered discreetly.</>} />

            <InfoItem
              icon={<CheckIcon />}
              iconSize={20}
              description={<>Your reassurance beyond technical support.</>}
            />
          </Stack>
        </StyledGridItem>

        {!isMobile && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'flex-start'
            }}
          >
            <StyledTokenInputContainer>
              <Stack spacing={4} height="100%">
                {loading ? (
                  <TokenLoading />
                ) : (
                  <>
                    <Typography variant="subtitle2" fontWeight={600}>
                      Enter your script details
                    </Typography>
                    <TokenInput
                      setOpenTokenDialog={setOpenTokenDialog}
                      openTokenDialog={openTokenDialog}
                      handleTokenSubmit={handleTokenSubmit}
                    />
                  </>
                )}
              </Stack>
            </StyledTokenInputContainer>
          </Grid>
        )}
      </Grid>
    </StyledWrapper>
  );
}
