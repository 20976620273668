import { styled } from '@mui/material';
import type { ReactNode } from 'react';

import { PartnerContainer as Container } from './PortalPartner.styles';

const AlertContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(8),
    gap: theme.spacing(4)
  },
  [theme.breakpoints.down('md')]: {
    padding: 0
  },
  '&:empty': {
    display: 'none'
  }
}));

export type PartnerAlertProps = {
  children?: ReactNode;
};

function PartnerAlert({ children }: PartnerAlertProps) {
  if (!children) {
    return null;
  }

  return <AlertContainer data-testid="portal-partner-layout__alert">{children}</AlertContainer>;
}

export default PartnerAlert;
