import { useEffect, useState } from 'react';

import type { Card } from '@/components/braze/components/ContentCard';
import useUser from '@/hooks/user/useUser';

import useCustomer from '@/hooks/user/useCustomer';
import { changeUser, init, updateUserAttributes } from '@/services/braze.service';

let isInitialized = false;

/**
 * Inits Braze user tracking
 *
 * @returns brazeContentCards
 */
export function useBraze() {
  const [brazeContentCards, setBrazeContentCards] = useState<Card[]>([]);
  const { data: user } = useUser();
  const { data: customer } = useCustomer();

  useEffect(() => {
    if (!isInitialized) {
      init({ contentCardsSubscriber: ({ cards }) => setBrazeContentCards(cards as Card[]) });
      isInitialized = true;
    }
  }, [user]);

  useEffect(() => {
    if (user?.email) {
      changeUser(user.email);
    }
  }, [user?.email]);

  useEffect(() => {
    if (user && customer) {
      updateUserAttributes({ user, customer });
    }
  }, [customer, user]);

  return { brazeContentCards };
}
