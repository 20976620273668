import axios from 'axios';
import type { ErrorInfo } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import { datadogRum } from '@datadog/browser-rum';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import { ErrorFallback } from './ErrorFallback';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';
import TsrReactQueryProvider from './providers/ts-rest-react-query-provider';
import getQueryClient from './utils/getQueryClient';
import { Logger } from './utils/logger';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const queryClient = getQueryClient();

const logError = (error: Error, info: ErrorInfo) => {
  const logger = new Logger('ErrorBoundary');
  logger.error({ message: error.message, componentStack: info.componentStack });

  const renderingError = new Error(error.message);
  renderingError.name = `ErrorBoundary`;
  renderingError.stack = info.componentStack ?? undefined;
  renderingError.cause = error;

  datadogRum.addError(renderingError);
};

function AppWrapper() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <QueryClientProvider client={queryClient}>
        <TsrReactQueryProvider>
          <FeatureFlagProvider>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </FeatureFlagProvider>
        </TsrReactQueryProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

const root = createRoot(document.getElementById('root')!);

root.render(<AppWrapper />);
