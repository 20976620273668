import type { LDClient } from 'launchdarkly-js-client-sdk';
import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';

import type { User } from '@/hooks/user/useUser';
import { Logger } from '@/utils/logger';

const logger = new Logger('identifyUserForLaunchDarkly');

export const anonymousUserContext = {
  kind: 'user',
  anonymous: true,
  key: 'shared-anonymous'
};

export const makeUserIdentityContext = (user?: User | null) => {
  if (!user) {
    return anonymousUserContext;
  }

  const ldUserName = `${user.firstName} ${user.lastName}`;

  const userContext: LDSingleKindContext = {
    kind: 'user',
    key: `${user.id}`,
    name: ldUserName,
    email: user.email,
    role: user.roleId
  };

  return userContext;
};

export const identifyUserForLaunchDarkly = async (ldClient: LDClient, user?: User | null) => {
  await ldClient.waitUntilReady();
  const currentLDUserContext = ldClient.getContext();
  const newLDUserContext = makeUserIdentityContext(user);

  // TODO: Do a better compare on the next line if we want reactive changes on LD user context values (other than key)
  if (currentLDUserContext.key !== newLDUserContext.key) {
    await ldClient.identify(newLDUserContext);
    logger.debug('LD client changed identity success', { from: currentLDUserContext, to: newLDUserContext });
  } else {
    logger.debug('LD client using existing user context', { ...currentLDUserContext });
  }
};
