import AdminPageLayout from '@/components/layout/AdminPageLayout';
import EWayTransaction from '@/pages/payment/eWay/eWayTransaction';

export function Invoices() {
  return (
    <AdminPageLayout title="Invoices">
      <EWayTransaction />
    </AdminPageLayout>
  );
}
