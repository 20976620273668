import getProductImageUrl from '@/utils/getProductImageUrl';
import { Link } from 'react-router-dom';
import Comingsoon from '../../assets/images/coming_soon.png';
import NewlowPrice from '../../assets/images/new_low_price.png';
import NewProduct from '../../assets/images/new_product.png';
import outOfStock from '../../assets/images/outofstock.png';
import { ConcessionProductCatalogueTag } from '../concession';
import ProductDisplayName from './ProductDisplayName';

const ProductImageTile = (props) => {
  const isConcessionProduct = props.product.is_concession;

  return (
    <Link to={`/product-detail/${props.product.id}`}>
      <div>
        <div className="position-relative">
          <img src={getProductImageUrl(props.product.image_url, props.product.ProductImages)} className="img-fluid" />
          <div className="position-product">
            {props.product.new_at_lowprice && <img src={NewlowPrice} className="img-fluid  new-product-low-price" />}
            {props.product.new_product && <img src={NewProduct} className="img-fluid new-product" />}
            {props.product.coming_soon && <img src={Comingsoon} className="img-fluid  coming-soon" />}
            {(props.product.is_out_of_stock || props.product.reasoning_toggle) && (
              <img src={outOfStock} className="img-fluid out-of-stock" />
            )}
            {isConcessionProduct && <ConcessionProductCatalogueTag className="img-fluid" />}
          </div>
        </div>
        <div className="justify-content-center">
          <p
            className="text-center font-primary"
            style={{ marginBottom: '0', color: '#47bd8f', fontWeight: 'bold', fontSize: '18px' }}
          >
            {props.product?.Suppliers[0]?.supplier_name}
          </p>
          <div className="justify-content-center d-flex">
            <h6 className="text-center align-items-center" style={{ marginBottom: '0' }}>
              <ProductDisplayName {...props.Product} />
              <span>
                <img src={props.type.image_url} className="ml-1" style={{ width: '22px', objectFit: 'contain' }} />
              </span>
            </h6>
          </div>
          <p className="text-center mb-1" style={{ fontSize: '12px' }}>
            THC {props.product.thc_strength_for_display} : CBD {props.product.cbd_strength_for_display}
          </p>
          <p className="text-center mb-1" style={{ fontSize: '12px' }}>
            ${props.product.price}
          </p>
          <p className="text-center mb-0" style={{ fontSize: '12px' }}>
            {props.type.name} - {props.product.size}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProductImageTile;
