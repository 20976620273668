import { useMutation } from '@tanstack/react-query';

import { AuthService } from '@/services/authentication.service';
import type { PharmacistLoginFormData } from '@/types/pharmacist.types';
import { Logger } from '@/utils/logger';

const logger = new Logger('PharmacistSignInForm');

/**
 * Log in as pharmacist and redirect to /pharmacist/orders
 *
 * @returns `login` function and `isPending` boolean
 */
function usePharmacistLogin() {
  const mutation = useMutation({
    mutationFn: async (data: PharmacistLoginFormData) => {
      await AuthService.login(data.email.toLowerCase().trim(), data.password, data.remember, data.loginType);
    },

    onError: (err) => {
      logger.error(err);
    },

    onSuccess: () => {
      window.location.replace('/pharmacist/orders');
    }
  });

  return {
    login: mutation.mutate,
    isPending: mutation.isPending
  };
}

export default usePharmacistLogin;
