import { createSvgIcon } from '@mui/material';

const OrdersIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="none">
    <path
      d="M12.6753 21.7005L13.1267 13.1228H19.4671L19.0156 21.7055"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1479 13.1491L18.4226 8.95415C18.4784 7.90969 17.6719 7.06201 16.622 7.06201C15.572 7.06201 14.6742 7.90969 14.6184 8.95415L14.3438 13.1491"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M17.6258 28.8178L17.0983 38.8032C16.9411 41.755 18.2751 44.1466 21.638 44.1466H34.065C37.4279 44.1466 40.2785 41.755 40.4307 38.8032L41.1307 25.4775H17.7983L17.6207 28.8178H17.6258Z"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.4688 31.0386H40.8011" stroke="#042B37" strokeWidth="2" strokeLinejoin="round" />
    <path
      d="M29.0435 44.1514H10.2659C8.84572 44.1514 7.76025 42.7941 7.85155 41.124L8.00879 38.0966L8.67326 25.4874C8.78485 23.3985 10.5754 21.7031 12.6753 21.7031H19.0156C21.1155 21.7031 22.7285 23.3985 22.622 25.4874"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44.5449 18.5496C44.4536 20.2903 42.9573 21.7031 41.2073 21.7031H25.9906C24.2406 21.7031 22.8965 20.2903 22.9878 18.5496C23.0791 16.8088 24.5754 15.396 26.3253 15.396H41.5421C43.292 15.396 44.6362 16.8088 44.5449 18.5496Z"
      fill="#FF8F1C"
    />
    <path
      d="M34.3297 7.82886C36.0796 7.82886 37.4238 9.24165 37.3325 10.9824L36.5361 26.1195C36.4448 27.8603 34.9485 29.2731 33.1986 29.2731C31.4486 29.2731 30.1045 27.8603 30.1958 26.1195L30.9921 10.9824C31.0834 9.24165 32.5798 7.82886 34.3297 7.82886Z"
      fill="#FF8F1C"
    />
    <path
      d="M12.7627 44.1466H34.0662C37.4291 44.1466 40.2797 41.755 40.4319 38.8032L41.1319 25.4775"
      stroke="#FF8F1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Orders'
);

export default OrdersIcon;
