import { Box, Button, Checkbox, FormControlLabel, Link, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Link as NavLink } from 'react-router-dom';
import type { SubmitTokenData } from './Landing';
import { TokenDialog } from './TokenDialog';

export interface TokenInputProps {
  setOpenTokenDialog: (state: boolean) => void;
  openTokenDialog: boolean;
  handleTokenSubmit: (formData: SubmitTokenData) => void;
}

export function TokenInput({ openTokenDialog, setOpenTokenDialog, handleTokenSubmit }: TokenInputProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<SubmitTokenData>({
    defaultValues: {
      token: '',
      agreement: false
    }
  });

  const isAgreementChecked = watch('agreement');

  return (
    <form onSubmit={handleSubmit(handleTokenSubmit)}>
      <Controller
        name="token"
        control={control}
        rules={{ required: 'Please enter your script token' }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <>
            <TextField
              type="text"
              variant="outlined"
              value={value}
              inputRef={ref}
              onChange={onChange}
              onBlur={onBlur}
              label="Script token or URL"
              error={!!errors.token}
              helperText={errors.token ? errors.token.message : ''}
              fullWidth
            />

            <Link
              variant="caption"
              color="textSecondary"
              sx={{ textDecoration: 'underline', marginLeft: 3.5, marginTop: errors.token ? 5 : 0 }}
              component="button"
              onClick={(event) => {
                event.preventDefault();
                setOpenTokenDialog(true);
              }}
            >
              Where do I find my token?
            </Link>

            <TokenDialog onClose={() => setOpenTokenDialog(false)} open={openTokenDialog} />
          </>
        )}
      />
      <Box marginTop={4}>
        <Controller
          name="agreement"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <FormControlLabel
              control={
                <Checkbox sx={{ paddingTop: 0 }} checked={value} onChange={onChange} inputRef={ref} color="primary" />
              }
              sx={{ alignItems: 'flex-start' }}
              label={
                <Typography variant="body2">
                  I understand that my prescription details will be treated in accordance with the uMeds
                  {/* todo: Update link */}
                  <NavLink to="#">
                    {' '}
                    <Typography variant="body2" component="span" color="primary" sx={{ textDecoration: 'underline' }}>
                      privacy policy
                    </Typography>
                  </NavLink>
                  .
                </Typography>
              }
            />
          )}
        />
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!isAgreementChecked}
        sx={{ marginTop: 4 }}
      >
        Submit
      </Button>
    </form>
  );
}
