import { BrandThemeProvider } from '@montugroup/themes';
import { Box, Divider, styled, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';

const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  margin: `0 ${theme.spacing(6)}`,
  minHeight: '100%'
}));

const HeadingDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

export type AdminPageProps = {
  title: string;
  themeVariant?: 'alternaleaf' | 'uMeds' | 'montu' | 'leafio' | undefined;
};

function AdminPageLayout({ title, themeVariant = 'montu', children }: PropsWithChildren<AdminPageProps>) {
  return (
    <BrandThemeProvider variant={themeVariant}>
      <PageContainer>
        <Typography component="h2" variant="h5">
          {title}
        </Typography>
        <HeadingDivider />
        {children}
      </PageContainer>
    </BrandThemeProvider>
  );
}

export default AdminPageLayout;
