import { getCountries } from '@/services/data.service';
import { useQuery } from '@tanstack/react-query';

/**
 * Retrieves the list of countries
 * @returns - UseQueryResult
 */
export function useGetCountries() {
  return useQuery({
    queryKey: ['countries'],
    queryFn: async () => await getCountries()
  });
}
