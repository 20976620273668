import { Close as CloseIcon } from '@mui/icons-material';
import type { DialogProps } from '@mui/material';
import { Box, Dialog, DialogContent, IconButton, useMediaQuery, useTheme } from '@mui/material';

export default function EnquiryDialog({
  children,
  open,
  onClose
}: Pick<DialogProps, 'open' | 'children'> & { onClose?: () => void }) {
  const theme = useTheme();

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullScreen={isSmDown} onClose={onClose}>
      {onClose && (
        <Box textAlign="right">
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
