import { getDiscountTypes } from '@/services/data.service';
import { useQuery } from '@tanstack/react-query';

export function usePatientDiscountType() {
  return useQuery({
    queryKey: ['patientDiscounts'],
    queryFn: async () => {
      const response = await getDiscountTypes();
      return response.data;
    }
  });
}

export default usePatientDiscountType;
