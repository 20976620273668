import { createSvgIcon } from '@mui/material';

const DocumentsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="none">
    <path
      d="M13.494 40.2432L15.126 9.27389C15.2114 7.62053 16.6325 6.27686 18.2946 6.27686H35.3679L33.7359 37.2462C33.5601 40.5529 30.7229 43.2403 27.3987 43.2403H13.3384L13.494 40.2432Z"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3555 43.2403H27.3942C30.7235 43.2403 33.5607 40.5579 33.7364 37.2462L35.3684 6.27686"
      stroke="#FF8F1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.6029 38.9941C24.7127 38.9941 25.6568 38.1 25.717 36.9961C25.7773 35.8922 24.9236 34.998 23.8138 34.998C22.7041 34.998 21.76 35.8922 21.6998 36.9961C21.6395 38.1 22.4932 38.9941 23.6029 38.9941Z"
      fill="#042B37"
    />
    <path
      d="M41.3893 28.8301C45.3362 25.0988 45.6526 19.0498 42.1024 15.3185C38.5471 11.5872 32.466 11.5872 28.5191 15.3185C24.5722 19.0498 24.2558 25.0988 27.806 28.8301C31.3613 32.5614 37.4424 32.5614 41.3893 28.8301Z"
      fill="#FF8F1C"
    />
    <path
      d="M26.1235 32.223L31.0948 30.185L27.5898 26.5037L25.2849 31.3389C25.0188 31.8933 25.551 32.4528 26.1235 32.218V32.223Z"
      fill="#FF8F1C"
    />
    <path
      d="M31.7178 22.7609L34.098 25.2585L39.4459 19.0146"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22.1006 10.2688H29.1307" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Documents'
);

export default DocumentsIcon;
