import { PaginationVariant, Table, toast } from '@montugroup/design-system';
import { Edit } from '@mui/icons-material';
import { Box, Button, Stack, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import TableSearchInput from '@/components/common/TableSearchInput';
import settings from '@/constants/constants';
import useSearchPharmacy from '@/hooks/pharmacy/useSearchPharmacy';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import useDownload from '@/hooks/useDownload';
import useDownloadTemplate from '@/hooks/useDownloadTemplate';
import useFileHandling from '@/hooks/useFileHandling';
import useFileUploadData from '@/hooks/useFileUploadData';
import { AuthService } from '@/services/authentication.service';
import { PharmacyService } from '@/services/pharmacy.service';
import type { SearchPharmacy } from '@/types/pharmacyView.types';

import PharmacyUpdateCta from './PharmacyUpdateCta';
import { UpdateOrderPharmacyModal } from './PharmacyViewModal';

const CustomiseLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const columns: ColumnDef<SearchPharmacy>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    enableSorting: true
  },
  {
    accessorKey: 'pharmacy_code',
    header: 'Pharmacy ID',
    enableSorting: true,
    cell: ({ row }) => <Link to={`/pharmacy-account/${row.original.id}`}>{row.original.pharmacy_code}</Link>
  },
  {
    accessorKey: 'name',
    header: 'Name',
    enableSorting: true,
    cell: ({ row }) => (
      <span style={{ color: row.original.active === false ? 'red' : 'inherit' }}>{row.original.name}</span>
    )
  },
  {
    accessorKey: 'email',
    header: 'Email',
    enableSorting: true
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    enableSorting: true
  },
  {
    accessorKey: 'address',
    header: 'Address',
    enableSorting: true,
    cell: ({ row }) => row.original.address.replace('$$$$', ' ')
  },
  {
    accessorKey: 'city',
    header: 'Suburb',
    enableSorting: true
  },
  {
    accessorKey: 'state',
    header: 'State',
    enableSorting: true
  },
  {
    accessorKey: 'zip_code',
    header: 'Postcode',
    enableSorting: true
  },
  {
    id: 'report',
    header: 'Report',
    enableSorting: false,
    cell: ({ row }) => (
      <CustomiseLink to={{ pathname: `/pharmacy/report/${row.original.id}` }} data-testid="report" color="info">
        Report
      </CustomiseLink>
    )
  },
  {
    id: 'edit',
    header: 'Edit',
    enableSorting: false,
    cell: ({ row }) => (
      <Link to={`/pharmacy-account/${row.original.id}`}>
        <Edit aria-label="edit" color="action" sx={{ cursor: 'pointer' }} />
      </Link>
    )
  }
];

const CustomiseBox = styled(Box)(({ theme }) => ({
  justifyContent: 'flex-end',
  display: 'flex',
  gap: theme.spacing(2)
}));

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const sortFieldOverrides = {
  pharmacy_code: 'pharmacy_code',
  name: 'name',
  email: 'email',
  phone: 'phone',
  address: 'address',
  city: 'city',
  state: 'state',
  zip_code: 'zip_code'
} as const;

function PharmacyView() {
  const [filter, setFilter] = useState<string>('');
  const [showActiveOnly, setShowActiveOnly] = useState<boolean>(true);
  const [orderData, setOrderData] = useState<null | [] | undefined>(null);
  const [pharmFileName, setPharmFileName] = useState<string>('');
  const [showOrderUpdatePharmacyModal, setShowOrderUpdatePharmacyModal] = useState<boolean>(false);

  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel();
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({ sortFieldOverrides });
  const { data, isLoading } = useSearchPharmacy({ page, pageSize, sortingOrder, filter });
  const navigate = useNavigate();
  const { downloadFile } = useDownload();
  const { mutate: downloadTemplate } = useDownloadTemplate();

  const { url } = settings;

  const API_URL = `${url}/pharmacy`;

  const handleExport = async () => {
    const itemsFormatted = data?.pharmacies.map((item: SearchPharmacy) => ({
      pharmacy_id: item.pharmacy_code,
      name: item.name.replace(/,/g, ''),
      email: item.email,
      phone: item.phone,
      address: item.address.split('$$$$'),
      suburb: item.city,
      state: item.state,
      postcode: item.zip_code
    }));

    const fileName = `${new Date().getTime()}-montu-pharmacies.csv`;
    await downloadFile(fileName, `${settings.url}/excel/pharmacy/download`, {
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob'
    });
  };

  const handleFileUpload = useFileUploadData(`${API_URL}/uploadpharmacy`, 5242880, ['csv']);
  const uploadFileHandling = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const selectedFile = e.target.files[0];
    const result = await handleFileUpload.fileUpload(selectedFile);
    if (result.error) {
      toast.error('Error Uploading CSV');
    } else {
      setOrderData(result.data?.data);
      setPharmFileName(result.data?.fileName ?? '');
      setShowOrderUpdatePharmacyModal(true);

      toast.success('CSV File Uploaded Successfully');
    }
  };

  const toggleShowActiveOnly = () => {
    setShowActiveOnly((prev) => !prev);
  };

  const filteredData = showActiveOnly ? data?.pharmacies.filter((pharmacy) => pharmacy.active) : data?.pharmacies;

  const { handleFileChange } = useFileHandling({
    onUpload: (formData) => PharmacyService.pharmacyUpload(formData),
    allowedFileTypes: ['xls', 'xlsx'],
    maxFileSizeInBytes: 5242880,
    onUploadSuccess: () => navigate(0)
  });

  const handleDownloadTemplate = () => {
    downloadTemplate({ fileName: 'Pharmacy Update Template.csv', urlLink: `${API_URL}/update-history-file-download` });
  };

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <TableSearchInput value={filter} onChange={(value) => setFilter(value)} />
        <CustomiseBox>
          {AuthService.isSuperAdmin() && (
            <PharmacyUpdateCta fileUpload={uploadFileHandling} downloadTemplate={handleDownloadTemplate} />
          )}
          <Box>
            <Button variant="outlined" size="medium" onClick={toggleShowActiveOnly} data-testid="toggle-filter">
              {showActiveOnly ? 'Show All' : 'Show Active'}
            </Button>
          </Box>
          <Box>
            <Button variant="outlined" size="medium" onClick={() => navigate('/pharmacy-report')} data-testid="report">
              Report
            </Button>
          </Box>
          {AuthService.isSuperAdmin() && (
            <Box>
              <Button variant="outlined" size="medium" onClick={handleExport} data-testid="export">
                Export All
              </Button>
            </Box>
          )}

          <Box display="inline" onChange={handleFileChange}>
            <Button
              variant="outlined"
              size="medium"
              component="label"
              htmlFor="file-input-pharmacy"
              data-testid="import"
            >
              Import
            </Button>
            <VisuallyHiddenInput id="file-input-pharmacy" name="pharmacy" type="file" />
          </Box>
        </CustomiseBox>
      </Stack>

      <Table
        isLoading={isLoading}
        data={filteredData || []}
        columns={columns}
        columnVisibility={{ id: false }}
        showPagination
        total={data?.count}
        manualPagination
        pageSize={pageSize}
        onPaginationModelChange={handlePaginationModelChange}
        hasRowBackgroundColor={false}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        onSortingChange={handleSortingOrderChange}
      />
      <Box>
        <UpdateOrderPharmacyModal
          isOpen={showOrderUpdatePharmacyModal}
          onClose={() => setShowOrderUpdatePharmacyModal(false)}
          SaveOrderUpdateData={orderData || []}
          pharmacyFileName={pharmFileName}
        />
      </Box>
    </>
  );
}

export default PharmacyView;
