import { FilterSelect } from '@/components/common/FilterSelect';
import useGetOrderStatus from '@/hooks/orders/useGetOrderStatus';
import type { OrderStatus } from '@/types';

type Props = {
  selectedStatuses: OrderStatus[];
  onSelect: (statuses: OrderStatus[]) => void;
  allowedStatuses?: string[];
};

function FilterByOrderStatusSelect({ selectedStatuses, onSelect, allowedStatuses }: Props) {
  const { data } = useGetOrderStatus(allowedStatuses);

  return (
    <FilterSelect
      options={data ?? []}
      selected={selectedStatuses}
      name="order-status-filter"
      label="Filter by Status"
      placeholder="Select status"
      onChange={onSelect}
    />
  );
}

export default FilterByOrderStatusSelect;
