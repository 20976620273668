/* eslint-disable react/no-unstable-nested-components */
import { Button, PaginationVariant, Table } from '@montugroup/design-system';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import type { ColumnDef, ColumnMeta, Row } from '@tanstack/react-table';
import moment from 'moment';
import { useMemo } from 'react';

import ListTableCell from '@/components/common/Table/ListTableCell';
import TableSearchInput from '@/components/common/TableSearchInput';
import ActionsTableCell from '@/components/orders/tableCells/ActionsTableCell';
import ConsignmentTableCell from '@/components/orders/tableCells/ConsignmentTableCell';
import DoctorTableCell from '@/components/orders/tableCells/DoctorTableCell';
import OrderNumberTableCell from '@/components/orders/tableCells/OrderNumberTableCell';
import OrderStatusTableCell from '@/components/orders/tableCells/OrderStatusTableCell';
import PatientIDTableCell from '@/components/orders/tableCells/PatientIdTableCell';
import PaymentStatusTableCell from '@/components/orders/tableCells/PaymentStatusTableCell';
import { useOrders } from '@/context/orders/Orders';
import AuthService from '@/services/authentication.service';

import type { Order } from '@/services/data.service';

const columnMeta: ColumnMeta<Order, unknown> = {
  style: {
    padding: '4px 4px 4px 15px'
  }
};

function OrdersTable() {
  const {
    orders,
    orderCount,
    includeSasStatus,
    pageSize,
    selectedOrders,
    handlePaginationModelChange,
    handleSortingOrderChange,
    filter,
    onFilterChange,
    loading,
    hasFiltersOrQuery,
    enableRecentOrders
  } = useOrders();
  const isDoc = AuthService.isDoc();
  const isAdmin = AuthService.isAdmin();
  const theme = useTheme();

  const columns: ColumnDef<Order>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        enableSorting: true
      },
      {
        accessorKey: 'order_code',
        header: 'Order #',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <OrderNumberTableCell isDoc={isDoc} orderCode={row.original.order_code} orderId={row.original.id} />
        )
      },
      {
        accessorKey: 'order_date',
        header: 'Date',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => <Box minWidth={90}>{moment(row.original.order_date).format('DD-MMM-YYYY')}</Box>
      },
      {
        accessorKey: 'patient_name',
        header: 'Patient',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => <span>{row.original.patient_name}</span>
      },
      {
        accessorKey: 'patient_id',
        header: 'Patient ID',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <PatientIDTableCell patientId={row.original.patient_id} patientUniqId={row.original.patient_uniq_id} />
        )
      },
      {
        accessorKey: 'patient_address_state_abbr',
        header: 'Patient state',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => row.original.patient_address_state_abbr
      },
      {
        accessorKey: 'Pharmacy.identifier_name',
        header: 'Pharmacy ID',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <Tooltip title={row.original.pharmacy_name}>
            <span>{row.original.identifier_name}</span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'product_name',
        header: 'Product',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <Box minWidth={200}>
            <ListTableCell
              items={row.original.product_name.map((name, i) => ({
                key: `product-name-${row.original.id}-${name}-${i}`,
                value: name
              }))}
            />
          </Box>
        )
      },
      {
        accessorKey: 'quantity',
        header: 'Qty',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <ListTableCell
            items={row.original.quantity.map((quantity, i) => ({
              key: `quantity-${row.original.id}-${i}`,
              value: quantity
            }))}
          />
        )
      },
      {
        accessorKey: 'pharmacy_name',
        header: 'Pharmacy',
        enableSorting: true,
        meta: columnMeta
      },
      {
        accessorKey: 'prescriber_details',
        header: 'Doctor',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => <DoctorTableCell order={row.original} />
      },
      {
        accessorKey: 'payment_status',
        header: 'Payment',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <PaymentStatusTableCell
            paymentGateway={row.original.payment_gateway}
            paymentStatus={row.original.payment_status}
          />
        )
      },
      {
        accessorKey: 'sasStatus',
        header: 'Approval',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <ListTableCell
            items={row.original.sasStatus.map((status, i) => ({
              key: `approval-${row.original.id}-${i}`,
              value: status
            }))}
          />
        )
      },
      {
        accessorKey: 'shipped_date',
        header: 'Shipped',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => {
          const shipped = moment(row.original.shipped_date);
          return <span>{shipped.isValid() ? shipped.format('DD-MMM-YYYY') : '-'}</span>;
        }
      },
      {
        accessorKey: 'orderStatus',
        header: 'Status',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <OrderStatusTableCell
            orderId={row.original.id}
            orderStatusId={row.original.order_status_id}
            orderStatus={row.original.orderStatus}
            modifiedDate={row.original.modified_date}
          />
        )
      },
      {
        accessorKey: 'dispensed_date',
        header: 'Dispensed',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => {
          const dispensed = moment(row.original.dispensed_date);
          return <span>{dispensed.isValid() ? dispensed.format('DD-MMM-YYYY') : '-'}</span>;
        }
      },
      {
        accessorKey: 'consignment_code',
        header: 'Consignment',
        enableSorting: true,
        meta: columnMeta,
        cell: ({ row }) => (
          <ConsignmentTableCell
            consignmentCode={row.original.consignment_code}
            shipmentTrackingId={row.original.shipment_tracking_id}
            shipmentCarrier={row.original.shipment_carrier}
          />
        )
      },
      {
        accessorKey: 'actions',
        header: '',
        meta: columnMeta,
        cell: ({ row }) => <ActionsTableCell order={row.original} isDoc={isDoc} isAdmin={isAdmin} />
      }
    ],
    [columnMeta, isAdmin, isDoc]
  );

  const columnVisibility = {
    id: false,
    pharmacy_name: !isAdmin,
    prescriber_details: !isDoc,
    consignment_code: isAdmin,
    sasStatus: includeSasStatus
  };

  return (
    <>
      <TableSearchInput value={filter} onChange={onFilterChange} />
      <Table
        compact
        data={orders}
        columns={columns}
        columnVisibility={columnVisibility}
        showPagination
        manualPagination
        pageSize={pageSize}
        total={orderCount}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        onSortingChange={handleSortingOrderChange}
        isLoading={loading}
        hasRowBackgroundColor={false}
        applyRowStyles={(row) => {
          const styles: any = { '& .MuiTableCell-root': { padding: '4px 4px 4px 15px' } };
          if (selectedOrders.includes((row as Row<Order>).original.id)) {
            styles.backgroundColor = `${theme.palette.info.light} !important`;
          } else {
            styles.backgroundColor = 'inherit';
          }
          return styles;
        }}
        noDataFoundMessage={
          !hasFiltersOrQuery ? (
            <>
              <Typography>
                To search for orders, simply enter your search term in the search bar above or select a filter.
              </Typography>
              <Typography>
                <Button variant="text" onClick={enableRecentOrders} sx={{ padding: 0 }}>
                  Click here to view the most recent orders
                </Button>
              </Typography>
            </>
          ) : (
            'There are no orders matching your search criteria.'
          )
        }
      />
    </>
  );
}

export default OrdersTable;
