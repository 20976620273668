import { WithSnowplow } from '@montugroup/data-collection';
import React, { useEffect } from 'react';

import { FF_ENABLE_SNOWPLOW_CIRCUIT } from '@/constants/featureFlags';
import { SNOWPLOW_APP_ID, SNOWPLOW_TRACKER_NAME } from '@/constants/snowplow';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useUser from '@/hooks/user/useUser';
import { setUserId } from '@snowplow/browser-tracker';

// This component acts as a wrapper around WithSnowplow from data-collection
// It ensures the userId field is properly set at all times
export const AuthenticatedSnowplow = (props: React.PropsWithChildren) => {
  const { children } = props;
  const { flags } = useFeatureFlags();
  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW_CIRCUIT];
  const { data: user } = useUser();
  const userId = user?.id.toString();

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  return WithSnowplow(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>,
    {
      appId: SNOWPLOW_APP_ID,
      trackerName: SNOWPLOW_TRACKER_NAME,
      userId
    },
    enableSnowplow
  );
};

export default AuthenticatedSnowplow;
