import { Grid, Skeleton } from '@mui/material';

const HelpdeskSettingsLoadingSkeleton = () => (
  <Grid container xs={12} spacing={2}>
    <Grid item xs={12}>
      <Grid item xs={4}>
        <Skeleton variant="text" height={50} />
      </Grid>
    </Grid>
    {Array.from({ length: 7 }).map(() => (
      <>
        <Grid item xs={4}>
          <Skeleton variant="text" height={56} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" height={56} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="text" height={56} />
        </Grid>
      </>
    ))}
  </Grid>
);

export default HelpdeskSettingsLoadingSkeleton;
