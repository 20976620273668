import type { Prescription } from '@/components/patient/prescription/common';
import { getCreatedDate, getDaysUntilExpiry, getExpiryDate } from '@/components/patient/prescription/common';
import type { IPrescriptions, ScriptMetadata } from '@/pages/patient/PatientPrescription';

export function sortPrescriptionsByCreatedDate(prescriptions: Prescription[]): Prescription[] {
  return prescriptions.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
}

function createScriptMetadata(prescription: Prescription): ScriptMetadata {
  return {
    createdDate: getCreatedDate(prescription),
    expiryDate: getExpiryDate(prescription),
    status: prescription.status,
    inActive: prescription.inActive,
    daysUntilExpiry: getDaysUntilExpiry(prescription)
  };
}

function processPrescriptions(prescriptionsMap: Record<string, IPrescriptions>) {
  return Object.values(prescriptionsMap).map((group) => ({
    ...group,
    prescriptions: sortPrescriptionsByCreatedDate(group.prescriptions)
  }));
}

export function transformPrescriptions(data: Prescription[] | undefined) {
  if (!data) {
    return { activePrescriptions: [], inactivePrescriptions: [] };
  }

  const activePrescriptionsMap: Record<string, IPrescriptions> = {};
  const inactivePrescriptionsMap: Record<string, IPrescriptions> = {};

  data.forEach((item) => {
    const isInactive = item.inActive;

    // if a script has at least 1 or more refills and is active, show as active. Otherwise, it has expired, ceased, or no refills left
    const map =
      !isInactive && item.status.toLowerCase() === 'active' ? activePrescriptionsMap : inactivePrescriptionsMap;

    if (!map[item.presciptionCode]) {
      map[item.presciptionCode] = {
        prescriptionCode: item.presciptionCode,
        prescriptionMetadata: createScriptMetadata(item),
        prescriptions: []
      };
    }
    map[item.presciptionCode].prescriptions.push(item);
  });

  const activePrescriptions = processPrescriptions(activePrescriptionsMap);
  const inactivePrescriptions = processPrescriptions(inactivePrescriptionsMap);

  return {
    activePrescriptions,
    inactivePrescriptions
  };
}
