import { Button, PaginationVariant, Table } from '@montugroup/design-system';
import { Box, Typography, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import settings from '@/constants/constants';
import useFetchClinics from '@/hooks/clinic/useFetchClinics';
import useDownload from '@/hooks/useDownload';
import type { ClinicProps } from '@/types/clinic.types';

import CircleLoader from '../common/circleLoader';

const HeaderContainer = styled(Box)`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
`;

function RenderEditButton({ original }: { original: ClinicProps }) {
  const navigate = useNavigate();

  return (
    <Button color="info" onClick={() => navigate(`/editclinic/${original.id}`)}>
      Edit
    </Button>
  );
}
const fields: ColumnDef<ClinicProps>[] = [
  { accessorKey: 'id', header: 'id', enableSorting: true },
  { accessorKey: 'clinic_code', header: 'Clinic ID', enableSorting: true },
  { accessorKey: 'name', header: 'Name', enableSorting: true },
  {
    accessorKey: 'email',
    header: 'Email',
    enableSorting: true
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    enableSorting: true
  },
  {
    accessorKey: 'city',
    header: 'Suburb',
    enableSorting: true
  },
  {
    accessorKey: 'state',
    header: 'State',
    enableSorting: true
  },
  {
    accessorKey: 'edit',
    header: 'Edit',
    cell: ({ row }) => RenderEditButton(row)
  }
];

function ClinicView() {
  const { clinicData, loading } = useFetchClinics();
  const { isDownloading, downloadFile } = useDownload();

  const handleExport = async () => {
    const itemsFormatted = clinicData.map((item) => ({
      clinic_Id: item.clinic_code,
      first_name: item.name.replace(/,/g, ''),
      email: item.email,
      phone: item.phone,
      city: item.city,
      state: item.state
    }));

    const fileName = `${new Date().getTime()}--montu-clinics.xlsx`;
    await downloadFile(fileName, `${settings.url}/excel/clinic/download`, {
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob'
    });
  };

  if (loading) {
    return <CircleLoader data-testid="circle-loader" />;
  }
  return (
    <>
      <HeaderContainer>
        <Typography variant="h5">Clinics</Typography>
        <Button size="small" color="info" onClick={handleExport}>
          {isDownloading ? 'Downloading...' : 'Export all'}
        </Button>
      </HeaderContainer>

      <Table
        isLoading={loading}
        data={clinicData}
        columns={fields}
        showPagination
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        columnVisibility={{ id: false }}
        initialSort={[{ id: 'id', desc: true }]}
      />
    </>
  );
}

export default ClinicView;
