import type { UseControllerReturn, UseFormProps, UseFormReturn } from 'react-hook-form';
import { useController, useForm } from 'react-hook-form';

import type { ValidationResult } from './useAddressValidation';
import useAddressValidation from './useAddressValidation';

export type UseAddressFormValues = {
  addressLine1: string;
  addressLine2: string;
  suburb: string;
  postcode: string;
  state: string;
};

export type AddressFormReturn = Pick<UseFormReturn<UseAddressFormValues>, 'formState' | 'reset' | 'handleSubmit'> & {
  defaultControllers: {
    controlAddressLine1: UseControllerReturn<UseAddressFormValues, 'addressLine1'>;
    controlAddressLine2: UseControllerReturn<UseAddressFormValues, 'addressLine2'>;
    controlSuburb: UseControllerReturn<UseAddressFormValues, 'suburb'>;
    controlPostcode: UseControllerReturn<UseAddressFormValues, 'postcode'>;
    controlState: UseControllerReturn<UseAddressFormValues, 'state'>;
  };
  additionalErrorStates: {
    addressLine1ValidationMessage: ValidationResult;
    addressLine2ValidationMessage: ValidationResult;
  };
};

type UseAddressFormProps = UseFormProps<UseAddressFormValues>;

/**
 * Custom hook for managing address form state management and validation.
 * @param props - Optional form configuration props
 */
export function useAddressForm(props?: UseAddressFormProps) {
  const { validatePostcode, validateParcelLocker, validateStateBelongsToPostcode } = useAddressValidation();
  const { control, ...otherForm } = useForm<UseAddressFormValues>({
    ...props,
    defaultValues: {
      addressLine1: '',
      addressLine2: '',
      postcode: '',
      state: '',
      suburb: '',
      ...(props || {}).defaultValues
    }
  });

  const addressLine1ValidationMessage = validateParcelLocker(otherForm.getValues().addressLine1);
  const addressLine2ValidationMessage = validateParcelLocker(otherForm.getValues().addressLine2);

  const controlAddressLine1 = useController({
    name: 'addressLine1',
    control,
    rules: {
      required: true,
      validate: (value) => validateParcelLocker(value)?.errorMessage ?? true
    }
  });

  const controlAddressLine2 = useController({
    name: 'addressLine2',
    control,
    rules: {
      validate: (value) => {
        if (!value) {
          return true;
        }
        return validateParcelLocker(value)?.errorMessage ?? true;
      }
    }
  });

  const controlSuburb = useController({
    name: 'suburb',
    control,
    rules: {
      required: true
    }
  });

  const controlPostcode = useController({
    name: 'postcode',
    control,
    rules: {
      required: true,
      validate: (value) => {
        // First validate the postcode format
        const formatValidation = validatePostcode(value);
        if (!formatValidation.isValid) {
          return formatValidation.errorMessage;
        }

        // If format is valid, check state match
        const stateValidation = validateStateBelongsToPostcode(value, otherForm.getValues().state);
        if (!stateValidation.isValid) {
          return stateValidation.errorMessage;
        }

        return true;
      }
    }
  });

  const controlState = useController({
    name: 'state',
    control,
    rules: {
      required: true
    }
  });

  const defaultControllers = {
    controlAddressLine1,
    controlAddressLine2,
    controlSuburb,
    controlPostcode,
    controlState
  };

  return {
    ...otherForm,
    control,
    defaultControllers,
    additionalErrorStates: {
      addressLine1ValidationMessage,
      addressLine2ValidationMessage
    }
  };
}

export default useAddressForm;
