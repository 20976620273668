import moment from 'moment';

export const dateColumnSort = (dateA: string, dateB: string) => {
  const a = moment(new Date(dateA));
  const b = moment(new Date(dateB));
  if (a.isBefore(b)) {
    return -1;
  }
  if (a.isSame(b)) {
    return 0;
  }
  return 1;
};
