import { PaginationVariant, Table } from '@montugroup/design-system';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { Column, Row } from '@tanstack/react-table';
import debounce from 'awesome-debounce-promise';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';

import TableSearchInput from '@/components/common/TableSearchInput';
import settings from '@/constants/constants';
import { INVENTORY_STATUS } from '@/constants/inventory';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import useDownload from '@/hooks/useDownload';
import type { PharmacyInventoryHistory } from '@/types/pharmacy.types';

import useInventoryHistory from '../../../../hooks/pharmacist/useGetInventoryHistory';

const DATE_DISPLAY_FORMAT = 'DD-MMM-YYYY';

function AdjustmentOverview() {
  const [search, setSearch] = useState('');
  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel({ page: 0, pageSize: 25 });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder();

  const { data, isLoading } = useInventoryHistory({ page, pageSize }, sortingOrder, search);
  const { isDownloading, downloadFile } = useDownload();

  const exportAll = async () => {
    const fileName = `${new Date().getTime()}-inventory-history`;

    await downloadFile(
      fileName,
      `${settings.url}/excel/pharmacist/downloadInventoryHistoryOfPharmacy`,
      {
        method: 'POST',
        responseType: 'blob'
      },
      '.xlsx'
    );
  };

  const dateColumnSort = useCallback((dateA: string, dateB: string) => {
    const a = moment(new Date(dateA));
    const b = moment(new Date(dateB));
    if (a.isBefore(b)) {
      return -1;
    }
    if (a.isSame(b)) {
      return 0;
    }
    return 1;
  }, []);

  const renderSimpleValueTableCell = ({
    row,
    column
  }: {
    row: Row<PharmacyInventoryHistory>;
    column: Column<PharmacyInventoryHistory>;
  }) => <Box>{row.getValue(column.id)}</Box>;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'created_date',
        header: 'Date',
        enableSorting: true,
        accessorFn: (originalRow: { createdDate: string | number | Date }) =>
          moment(new Date(originalRow.createdDate)).format(DATE_DISPLAY_FORMAT),
        sortingFn: (rowA: { original: { createdDate: string } }, rowB: { original: { createdDate: string } }) =>
          dateColumnSort(rowA.original.createdDate, rowB.original.createdDate),
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'productName',
        header: 'Product',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'adjustedBy',
        header: 'Adjusted By',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'adjustedType',
        header: 'Adjusted Type',
        accessorFn: (originalRow: { adjustmentType: string | number }) =>
          Number(originalRow.adjustmentType) === Number(INVENTORY_STATUS.INCREMENT) ? 'Increase' : 'Decrease',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity Change',
        accessorFn: (originalRow: { adjustmentType: string | number; quantity: string }) =>
          Number(originalRow.adjustmentType) === Number(INVENTORY_STATUS.INCREMENT)
            ? originalRow.quantity
            : `-${originalRow.quantity}`,
        enableSorting: false,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'reference',
        header: 'Reference',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      }
    ],
    [renderSimpleValueTableCell, dateColumnSort]
  );

  const handleSearch = debounce((value: string) => {
    setSearch(value);
  }, 250);

  return (
    <Box>
      <Typography variant="h5" component="h2" paddingTop={5} paddingBottom={6}>
        Adjustment Overview
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={(theme) => theme.spacing(4)}
        justifyContent="space-between"
        paddingBottom={6}
      >
        <TableSearchInput value={search} onChange={handleSearch} />
        <Box>
          <Button variant="contained" size="large" onClick={exportAll} disabled={isDownloading}>
            Export All
          </Button>
        </Box>
      </Stack>
      <Table
        data={data?.inventoryHistory || []}
        columns={columns}
        showPagination
        manualPagination
        pageSize={pageSize}
        total={data?.count || 0}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        onSortingChange={handleSortingOrderChange}
        isLoading={isLoading}
      />
    </Box>
  );
}

export default AdjustmentOverview;
