import { getTsAdminClient } from '@/api/admin.client';
import { getMillisUntilNextSchedule } from '@/utils/helpdesk';

const client = getTsAdminClient();

export default function useGetHelpdeskSettings() {
  const { data, isLoading, refetch } = client.getHelpdeskSettings.useQuery({
    queryKey: ['getHelpdeskSettings'],
    refetchInterval: ({ state: { data } }) => (data ? getMillisUntilNextSchedule(data.body.data) : false)
  });

  return {
    data: data?.body.data,
    isLoading,
    refetch
  };
}
