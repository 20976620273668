import { DialogContent, IconButton, styled } from '@mui/material';

export const StyledDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const StyledDialogImage = styled('img')(() => ({
  maxWidth: '180px',
  alignSelf: 'center'
}));

export const StyledIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 16,
  right: 16
}));
