import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import ErrorPage from '@/components/error/ErrorPage';

export function ErrorFallback() {
  useEffect(() => {
    datadogRum.addAction('error-boundary');
  }, []);

  return <ErrorPage errorCode="500" />;
}
