import { useEffect, useState } from 'react';

import useGetHelpdeskSettings from '@/hooks/helpdesk/useGetHelpdeskSettings';
import type { HelpdeskSchedule } from '@/utils/helpdesk';
import { getSchedule } from '@/utils/helpdesk';

export default function useHelpdeskSchedule(): HelpdeskSchedule {
  const { data: helpdeskSettings } = useGetHelpdeskSettings();
  const [schedule, setSchedule] = useState<HelpdeskSchedule>({
    active: null,
    next: null
  });

  useEffect(() => {
    if (!helpdeskSettings) {
      return;
    }

    const newSchedule = getSchedule(helpdeskSettings);
    setSchedule(newSchedule);
  }, [helpdeskSettings, setSchedule]);

  return schedule;
}
