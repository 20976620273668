import type { HelpdeskSettingsDto } from '@montugroup/circuit-api-contracts';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
  styled
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import useGetHelpdeskSettings from '@/hooks/helpdesk/useGetHelpdeskSettings';
import useHelpdeskSchedule from '@/hooks/helpdesk/useHelpdeskSchedule';
import useUpdateHelpdeskSettings from '@/hooks/helpdesk/useUpdateHelpdeskSettings';
import { LoadingButton } from '@/ui-library';
import { DAYS, TIME_FORMAT } from '@/utils/helpdesk';
import { capitalizeFirstLetter } from '@/utils/string';
import useHelpdeskSettingsStore from './useHelpdeskSettingsStore';

import HelpdeskSettingsLoadingSkeleton from './HeldeskSettingsLoadingSkeleton';
import HelpdeskScheduleStatus from './HelpdeskScheduleStatus';

const HelpdeskDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  paddingBottom: theme.spacing(6)
}));

const HelpdeskRow = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  '&:hover': {
    p: {
      fontWeight: 'bold'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.common.black,
      transition: 'border-color 0.3s ease'
    }
  }
}));

const buildDateTimeFromTime = (time: string | null) => (time ? DateTime.fromFormat(time, TIME_FORMAT) : null);

const HelpdeskSettingsPage = () => {
  const [savedHelpdeskSettings, setSavedHelpdeskSettings] = useState<HelpdeskSettingsDto[]>([]);
  const { helpdeskSettings, setHelpdeskSettings, updateHelpdeskSetting } = useHelpdeskSettingsStore();
  const { data: savedData, isLoading, refetch } = useGetHelpdeskSettings();
  const { data: updatedData, mutateAsync, isPending } = useUpdateHelpdeskSettings();
  const { active: activeSchedule, next: nextSchedule } = useHelpdeskSchedule();

  const handleSubmit = async (id: number) => {
    const settingsToSubmit = helpdeskSettings?.find((setting) => setting.id === id);
    if (settingsToSubmit) {
      await mutateAsync({ params: { id }, body: { data: settingsToSubmit } });
      await refetch();
    }
  };

  const handleTimeChange = (
    id: number,
    day: (typeof DAYS)[number],
    field: 'enable_time' | 'disable_time',
    value: DateTime | null
  ) => {
    updateHelpdeskSetting(id, {
      [`${day}_${field}`]: value ? value.toFormat(TIME_FORMAT) : null
    });
  };

  const handleActiveChange = (id: number, active: boolean) => {
    updateHelpdeskSetting(id, { active });
  };

  const isModified = (helpdesk: HelpdeskSettingsDto) => {
    const originalHelpdesk = savedHelpdeskSettings.find(({ id }) => id === helpdesk.id);
    return JSON.stringify(helpdesk) !== JSON.stringify(originalHelpdesk);
  };

  useEffect(() => {
    if (savedData) {
      setSavedHelpdeskSettings(savedData);
      setHelpdeskSettings(savedData);
    }
  }, [savedData, setHelpdeskSettings, setSavedHelpdeskSettings]);

  useEffect(() => {
    if (updatedData) {
      const updatedHelpdeskSettings = savedHelpdeskSettings.map((helpdesk) =>
        helpdesk.id === updatedData.id ? updatedData : helpdesk
      );
      setSavedHelpdeskSettings(updatedHelpdeskSettings);
    }
  }, [updatedData, savedHelpdeskSettings, setSavedHelpdeskSettings]);

  return (
    <Box
      sx={(theme) => ({
        maxWidth: theme.breakpoints.values.lg,
        m: '0 auto'
      })}
    >
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" component="h4" gutterBottom>
            Helpdesk Settings
          </Typography>

          <Typography variant="body1" gutterBottom>
            Toggle our helpdesk widgets on and off, or set an automated schedule.
            <FormHelperText>
              If a schedule clashes between helpdesk providers, the first in the list will take precidence
            </FormHelperText>
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          md={6}
          sx={(theme) => ({
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            pt: 2,
            [theme.breakpoints.up('md')]: {
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }
          })}
        >
          <Stack direction="row" spacing={1}>
            {activeSchedule && <HelpdeskScheduleStatus schedule={activeSchedule} label="active" type="success" />}
            {nextSchedule && <HelpdeskScheduleStatus schedule={nextSchedule} label="next" type="info" />}
          </Stack>
        </Grid>
      </Grid>

      {isLoading && <HelpdeskSettingsLoadingSkeleton />}

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {helpdeskSettings.map((helpdesk) => (
          <Box key={helpdesk.id}>
            <HelpdeskDivider />
            <Typography variant="h5" component="h5" gutterBottom sx={{ pb: 3 }}>
              {helpdesk.name}
              <FormControlLabel
                control={
                  <Switch
                    checked={helpdesk.active}
                    onChange={(e) => handleActiveChange(helpdesk.id, e.target.checked)}
                    size="small"
                  />
                }
                label={<FormHelperText>{helpdesk.active ? 'Enabled' : 'Disabled'}</FormHelperText>}
                sx={{ px: 3, m: 0 }}
              />
            </Typography>

            {DAYS.map((day) => (
              <HelpdeskRow key={day} container xs={12} spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="body1" sx={{ fontWeight: 'inherit' }}>
                    {capitalizeFirstLetter(day)}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TimePicker
                    label="Time to enable"
                    views={['hours', 'minutes']}
                    value={buildDateTimeFromTime(helpdesk[`${day}_enable_time`])}
                    onChange={(value) => handleTimeChange(helpdesk.id, day, 'enable_time', value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TimePicker
                    label="Time to disable"
                    views={['hours', 'minutes']}
                    value={buildDateTimeFromTime(helpdesk[`${day}_disable_time`])}
                    onChange={(value) => handleTimeChange(helpdesk.id, day, 'disable_time', value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleTimeChange(helpdesk.id, day, 'enable_time', null);
                      handleTimeChange(helpdesk.id, day, 'disable_time', null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </HelpdeskRow>
            ))}

            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={!isModified(helpdesk)}
                loading={isPending}
                onClick={() => handleSubmit(helpdesk.id)}
              >
                Save {helpdesk.name} settings
              </LoadingButton>
            </Grid>
          </Box>
        ))}
      </LocalizationProvider>
    </Box>
  );
};

export default HelpdeskSettingsPage;
