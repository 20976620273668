import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

interface LayoutContextObject {
  alertContent: ReactNode | null;
  setAlertContent: (content: ReactNode | null) => void;
}

export const LayoutContext = createContext<LayoutContextObject>({
  alertContent: null,
  setAlertContent: () => null
});

export const LayoutConnector = (props: PropsWithChildren) => {
  const { children } = props;
  const [alertContent, setAlertContent] = useState<ReactNode | null>(null);

  return <LayoutContext.Provider value={{ alertContent, setAlertContent }}>{children}</LayoutContext.Provider>;
};

export const GlobalAlertContent = (props: PropsWithChildren) => {
  const { children } = props;
  const { setAlertContent } = useContext(LayoutContext);
  useEffect(() => {
    setAlertContent(children);
    return () => setAlertContent(null); // Clear alert content on unmount, so it doesn't persist through page navigation
  }, [children, setAlertContent]);

  return null; // This component renders nothing itself, it instead renders into the AlertOutlet
};

export const AlertOutlet = () => {
  const { alertContent } = useContext<LayoutContextObject>(LayoutContext);
  //eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{alertContent}</>;
};
