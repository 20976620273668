import { Box } from '@mui/material';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/authentication.service';
import Header from '../layout/header';

function SignOut() {
  const navigate = useNavigate();

  React.useEffect(() => {
    AuthService.logout({ navigate });
  }, [navigate]);

  return (
    <>
      <Header />
      <Box>Signing out...</Box>
    </>
  );
}

export default SignOut;
