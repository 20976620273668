import debounce from 'awesome-debounce-promise';
import { useCallback, useMemo, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export function useTableFilter(onChangeAction?: () => void) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState<string>(searchParams.get('search') ?? '');

  const debouncedFilterChange = useMemo(
    () =>
      debounce((value: string) => {
        const trimmedValue = value?.trim().replace(/\s{2,}/g, ' ') ?? '';
        const params = new URLSearchParams(location.search);
        if (trimmedValue) {
          params.set('search', trimmedValue);
        } else {
          params.delete('search');
        }
        const path = {
          pathname: location.pathname,
          search: createSearchParams(params).toString()
        };
        navigate(path);
        setFilter(trimmedValue);
        onChangeAction && onChangeAction();
      }, 250),
    [location, navigate, onChangeAction]
  );

  const onFilterChange = useCallback(
    (value: string) => {
      debouncedFilterChange(value);
    },
    [debouncedFilterChange]
  );

  const clearFilter = useCallback(() => setFilter(''), []);

  return { filter, onFilterChange, clearFilter };
}
