import type { ReactNode } from 'react';
import { AlertContainer } from './Onboarding.styles';

export type OnboardingAlertProps = {
  children?: ReactNode;
};

function OnboardingAlert({ children }: OnboardingAlertProps) {
  if (!children) {
    return null;
  }

  return <AlertContainer data-testid="onboarding-layout__alert">{children}</AlertContainer>;
}

export default OnboardingAlert;
