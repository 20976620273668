import * as braze from '@braze/web-sdk';
import md5 from 'crypto-js/md5';
import { v4 as uuidv4 } from 'uuid';

import type { User } from '@/hooks/user/useUser';
import { Logger } from '@/utils/logger';

import settings from '../constants/constants';

import type { Customer } from '@/hooks/user/useCustomer';
import { stripDollarSigns } from '@/utils/StripDollarSigns';

const logger = new Logger('braze.service');
// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/setting_custom_attributes/
export function updateUserAttributes({ user, customer }: { user: User; customer: Customer }) {
  const brazeUser = braze.getUser();
  if (!brazeUser) {
    return;
  }

  brazeUser.setFirstName(user.firstName ?? '');
  brazeUser.setEmail(user.email ?? '');
  brazeUser.setCustomUserAttribute('address', stripDollarSigns(customer.shippingAddress.address));
  brazeUser.setHomeCity(customer.shippingAddress.city ?? '');
  brazeUser.setCustomUserAttribute('state_id', customer.shippingAddress.stateId ?? '');
  brazeUser.setCustomUserAttribute('zip_code', customer.shippingAddress.zipCode ?? '');
  brazeUser.setPhoneNumber(customer.shippingAddress.phone ?? '');
  brazeUser.setCustomUserAttribute('client_code', user.connections.patient?.code ?? '');
  brazeUser.setCustomUserAttribute('created_by', customer.braze.createdBy ?? '');
  brazeUser.setCustomUserAttribute('date_of_birth', customer.braze.dateOfBirth ?? ''); // TODO: use `setDateOfBirth`
  brazeUser.setCustomUserAttribute('patient_id', customer.id ?? '');
  brazeUser.setCustomUserAttribute('is_discharged', customer.isDischarged ?? '');
  brazeUser.setCustomUserAttribute('is_new_patient', customer.braze.isNewPatient ?? '');
  brazeUser.setCustomUserAttribute('patient_code', customer.code ?? '');
  brazeUser.setCustomUserAttribute('patient_pharmacy_name', customer.braze.patientPharmacyName ?? '');
  brazeUser.setCustomUserAttribute('patient_pharmacy_address', customer.braze.patientPharmacyAddress ?? '');
  brazeUser.setCustomUserAttribute('pharmacy_id', customer.braze.pharmacyId ?? '');
  brazeUser.setCustomUserAttribute('priority', customer.braze.priority ?? '');
  brazeUser.setCustomUserAttribute('user_id', user.id ?? '');
}

type BrazeInitOptions = {
  options?: Omit<braze.InitializationOptions, 'baseUrl'>;
  contentCardsSubscriber?: (events: braze.ContentCards) => void;
};

export function changeUser(email: User['email']) {
  const identifier = md5(email.toLowerCase().trim()).toString();
  braze.changeUser(identifier);
}

export interface BrazeOrder {
  productId: string;
  price: number;
  currencyCode: string;
  quantity: number;
  purchaseProperties: {
    products: {
      product_id: number;
      product_name: string;
      price: number;
      quantity: number;
      remaining_units: number;
      is_out_of_stock: boolean;
      not_addable: boolean;
    }[];
  };
}

export function brazeTrackOrderPurchase(products: [] = []) {
  try {
    if (products.length < 1) {
      return;
    }

    const orderId = uuidv4();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    products.forEach((p: any) => {
      braze.logPurchase(p['Product Name'] || 'Unknown', p.Price || 0, 'AUD', p.Quantity || 0, {
        order_id: orderId,
        product_id: p['Product ID'] || 0,
        remaining_units: p['Remaining Units'] || 0,
        is_out_of_stock: p['Is Out of Stock'] || false,
        not_addable: p['Is Addable'] || true
      });
    });
  } catch (e) {
    logger.error('Braze checkout event failed to send', e);
  }
}

// https://www.braze.com/docs/developer_guide/platform_integration_guides/web/initial_sdk_setup/#step-2-initialize-braze
export function init({ contentCardsSubscriber }: BrazeInitOptions = {}) {
  braze.initialize(settings.brazeApiKey, {
    baseUrl: settings.brazeBaseUrl
  });
  braze.requestPushPermission();
  braze.automaticallyShowInAppMessages();

  if (contentCardsSubscriber) {
    braze.subscribeToContentCardsUpdates(contentCardsSubscriber);
    braze.requestContentCardsRefresh();
  }

  braze.openSession();
}
