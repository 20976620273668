import { Box, styled } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import type { ProductCatalog } from '@/components/products/detail/types';
import { alternaleafTheme } from '@/theme';
import getProductImageUrl from '@/utils/getProductImageUrl';

import ProductStatusLabel from './detail/ProductStatusLabel';
import ProductDisplayName from './ProductDisplayName';

const RelativeBox = styled(Box)`
  position: relative;
`;

const ProductStatusContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '0',
  right: '0',
  objectFit: 'contain',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const SupplierName = styled('span')(
  ({ theme }) => `
  color: ${theme.palette.primary.main};
  font-size: 1rem;
  font-weight: 700;
`
);

const ProductDetail = styled('span')`
  font-size: 0.75rem;
  font-weight: 400;
`;

const ProductPrice = styled('span')`
  font-size: 0.875rem;
  font-weight: 800;
`;

const ImageContainerSquare = styled(Box)`
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: flex-end;
`;

interface ProductImageTileProps {
  product: ProductCatalog;
  type: {
    image_url: string;
    name: string;
  };
}

function ProductImageTile(props: ProductImageTileProps) {
  const { product, type } = props;

  return (
    <CssVarsProvider theme={alternaleafTheme}>
      <Link to={`/product-detail/${product.id}`}>
        <div>
          <RelativeBox>
            <ImageContainerSquare>
              <img
                src={getProductImageUrl(product.image_url, product.ProductImages)}
                style={{ objectFit: 'contain', height: 'auto', maxWidth: '100%' }}
                alt={`${product.name} preview`}
              />
            </ImageContainerSquare>
            <ProductStatusContainer>
              <ProductStatusLabel
                newAtLowPrice={product.new_at_lowprice}
                comingSoon={product.coming_soon}
                isConcessionProduct={product.is_concession}
                isOutOfStock={product.is_out_of_stock || product.reasoning_toggle}
                newProduct={product.new_product}
                campaignDiscountPrice={product.campaign_discount_price}
                isLimitedAvailability={product.is_excluded_from_new_prescriptions}
              />
            </ProductStatusContainer>
          </RelativeBox>

          <Box display="flex" flexDirection="column" marginTop="0.75rem">
            <SupplierName>{product?.Suppliers[0]?.supplier_name}</SupplierName>

            <ProductDetail>
              <ProductDisplayName {...product} />
            </ProductDetail>

            <ProductDetail sx={{ fontSize: '12px', marginBottom: '0.25rem' }}>
              THC {product.thc_strength_for_display} : CBD {product.cbd_strength_for_display}
            </ProductDetail>

            <ProductPrice sx={{ fontSize: '12px', marginBottom: '0.25rem' }}>${product.price}</ProductPrice>

            <ProductDetail sx={{ fontSize: '12px' }}>
              {type.name} - {product.size}
            </ProductDetail>
          </Box>
        </div>
      </Link>
    </CssVarsProvider>
  );
}

export default ProductImageTile;
