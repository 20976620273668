import { usePostPatientNote } from '@/hooks/admin/usePostPatientNote';
import type { PatientNoteData } from '@/types/patient.types';
import { BrandThemeProvider } from '@montugroup/themes';
import { Box, Button, Card, CardContent, styled, TablePagination, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import type React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const Container = styled(Box)(({ theme }) => ({ margin: theme.spacing(8, 0) }));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',

  marginBottom: theme.spacing(2)
}));

const BoxContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const TextFiledStyle = styled(TextField)`
  width: '100%';
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
`;

const BottomButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
  margin: theme.spacing(4, 0)
}));

const VerticalMargins = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(2)
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const VerticalScroll = styled(Typography)(({ theme }) => ({
  overflowY: 'auto',
  maxHeight: theme.spacing(40),
  marginTop: theme.spacing(3)
}));

type PatientNoteProps = {
  patientNote: PatientNoteData[];
  setPatientNote: React.Dispatch<React.SetStateAction<PatientNoteData[]>>;
  patientID: number;
};
const ROWS_PER_PAGE_OPTIONS = [5, 10, 15];
const DEFAULT_ROWS_PER_PAGE = 5;

const PatientNote = ({ patientNote, setPatientNote, patientID }: PatientNoteProps) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);

  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<{ note: string }>();
  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hasNote = patientNote.length > 0;

  const paginatedNotes = hasNote ? patientNote.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];

  const onNoteCancel = () => {
    reset();

    setIsAddingNote(false);
  };

  const mutation = usePostPatientNote();

  const onNoteSubmit = (_data: { note: string }) => {
    mutation.mutate(
      { patient_id: patientID, note: _data.note },
      {
        onSuccess: (newNote) => {
          setPatientNote((prevNote) => [newNote.data, ...prevNote]);

          setIsAddingNote(false);
          reset();
        }
      }
    );
  };

  return (
    <BrandThemeProvider variant="montu">
      <Container>
        <BoxContainer>
          <Heading variant="body1">Patient Notes</Heading>
          <ButtonContainer>
            <Button variant="contained" color="primary" onClick={() => setIsAddingNote(true)} disabled={isAddingNote}>
              Add new note
            </Button>
          </ButtonContainer>
        </BoxContainer>

        <>
          {isAddingNote && (
            <VerticalMargins>
              <form onSubmit={handleSubmit(onNoteSubmit)}>
                <Controller
                  name="note"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Patient note is required' }}
                  render={({ field }) => (
                    <TextFiledStyle
                      {...field}
                      placeholder="New Note"
                      label="Creating a new patient note"
                      variant="standard"
                      multiline
                      rows={4}
                      error={!!errors.note}
                      helperText={errors.note?.message}
                    />
                  )}
                />
                <BottomButtonContainer>
                  <Button onClick={onNoteCancel} variant="outlined" size="medium">
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" size="medium">
                    Add
                  </Button>
                </BottomButtonContainer>
              </form>
            </VerticalMargins>
          )}

          {hasNote ? (
            <Box>
              {paginatedNotes.map((row: PatientNoteData) => (
                <VerticalMargins key={row.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="caption" color={(theme) => theme.palette.grey[500]}>
                        {row.User.first_name} {row.User.last_name}{' '}
                        {DateTime.fromISO(row.created_date).setZone('local').toFormat('dd-MMM-yyyy, hh:mm a')}
                      </Typography>
                      <VerticalScroll variant="body1">{row.note}</VerticalScroll>
                    </CardContent>
                  </Card>
                </VerticalMargins>
              ))}
            </Box>
          ) : (
            <Typography variant="body2">No Patient notes yet. Click &apos;Add new note&apos; to add one.</Typography>
          )}
        </>

        {hasNote && (
          <TablePagination
            component="div"
            count={patientNote.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          />
        )}
      </Container>
    </BrandThemeProvider>
  );
};

export { PatientNote };
