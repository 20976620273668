import { FF_USE_FULL_PRODUCT_NAMES } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

/**
 * Allows us to experiment with how we display a product's name behind a feature flag
 */
function ProductName<T extends { name: string; short_name: string | null }>(props: T) {
  const { flags } = useFeatureFlags();

  return <span>{flags[FF_USE_FULL_PRODUCT_NAMES] ? props.name : props.short_name || props.name}</span>;
}

export default ProductName;
