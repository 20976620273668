import { Box, Typography, styled } from '@mui/material';
import React from 'react';

export interface InfoItemProps {
  icon: React.ReactNode;
  title?: string;
  description: React.ReactNode;
  iconSize?: number;
}

const StyledSvgContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: theme.spacing(4),
  '& svg': {
    width: 51,
    height: 51
  }
}));

export function InfoItem({ icon, title, description, iconSize }: InfoItemProps) {
  return (
    <Box display="flex" alignItems="center">
      <StyledSvgContainer
        sx={{
          '& svg': {
            width: iconSize,
            height: iconSize
          }
        }}
      >
        {icon}
      </StyledSvgContainer>

      <Box>
        {title && (
          <Typography variant="body2" fontWeight={600}>
            {title}
          </Typography>
        )}
        <Typography variant={title ? 'caption' : 'body1'} color="textPrimary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
