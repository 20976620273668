import type { SplitOrderBreakdownProps } from './OrderBreakdown';

import settings from '@/constants/constants';
import type { OrderDetails, OrderDetailsProducts } from '@/hooks/order/useGetOrderDetails';

export function getSplitOrderProps(
  orderBreakdownDetails: OrderDetails[],
  dispensingFee?: number,
  isRefundedOrCancelled = false
): SplitOrderBreakdownProps {
  const lineItems: OrderDetailsProducts[] = [];
  const cancelledLineItems: OrderDetailsProducts[] = [];

  const allSplitOrdersCancelled = orderBreakdownDetails.every(
    (order) =>
      order.order_status_id === settings.orderStatus.CANCELLED ||
      order.order_status_id === settings.orderStatus.REFUNDED
  );

  orderBreakdownDetails.forEach((order) => {
    if (!order.OrderProducts) {
      return;
    }

    // here we split the cancelled/refunded and current line items into separate arrays, as in split orders
    // one order could still be active while another is cancelled/refunded.
    if (
      (order.order_status_id === settings.orderStatus.CANCELLED ||
        order.order_status_id === settings.orderStatus.REFUNDED) &&
      !allSplitOrdersCancelled
    ) {
      cancelledLineItems.push(...order.OrderProducts);
    } else {
      lineItems.push(...order.OrderProducts);
    }
  });

  const baseOrderTotalPrice = Number(orderBreakdownDetails[0].total_price ?? 0);

  // if all split orders are cancelled, the refund amount will be the total base price
  const refundedAmount = allSplitOrdersCancelled
    ? baseOrderTotalPrice
    : cancelledLineItems.reduce((total, product) => total + Number(product.price ?? 0), 0);

  // calculate the total price of the current active line items
  const lineItemsTotalPrice = lineItems.reduce((total, product) => total + Number(product.price ?? 0), 0);

  return {
    totalPrice: baseOrderTotalPrice,
    lineItems,
    cancelledLineItems,
    lineItemsTotalPrice,
    refundedAmount,
    isRefundedOrCancelled,
    dispensingFee,
    splitOrder: true
  };
}

export function orderPriceText(price: string | number) {
  return Number(price).toFixed(2);
}

export function orderTotal(dispensingFee: number | undefined, lineItemsTotalPrice: number) {
  return `$${
    dispensingFee && lineItemsTotalPrice > 0
      ? orderPriceText(lineItemsTotalPrice + dispensingFee)
      : orderPriceText(lineItemsTotalPrice)
  }`;
}
