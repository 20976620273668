import { InfoOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Link, Typography } from '@mui/material';

interface SasApprovalAlert {
  enabled: boolean;
}

export const SasApprovalAlert = (props: SasApprovalAlert) => {
  const { enabled } = props;

  if (!enabled) {
    return null;
  }

  return (
    <Alert
      severity="info"
      icon={<InfoOutlined />}
      sx={{
        width: '100%'
      }}
      data-testid="sasapprovalalert"
    >
      <AlertTitle>Special Access Scheme (SAS) approval in progress</AlertTitle>
      <Typography variant="inherit">
        Your doctor is awaiting SAS approval from the TGA to prescribe your medication. This may delay your order by 5-7
        business days.
      </Typography>
      <Typography variant="inherit">
        <Link href="https://support.umeds.com.au/hc/en-au/articles/10869503095695-Regulations-and-approvals#h_01J92JF9BB7S1FN9G6SRGEPW2P">
          More details on SAS approval
        </Link>
      </Typography>
    </Alert>
  );
};
