import React, { Suspense } from 'react';

import CircleLoader from '@/components/common/circleLoader';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import { BrandThemeProvider } from '@montugroup/themes';
import { Box } from '@mui/material';
import OnboardingLayout from './OnboardingLayout';
import { AlertOutlet } from './contexts/onboarding-layout-context';

function OnboardingUnauth({ children }: React.PropsWithChildren) {
  const { key: partnerKey } = usePortalPartnerBrand();

  return (
    <BrandThemeProvider variant={partnerKey}>
      <OnboardingLayout.Background>
        <Box sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
          <OnboardingLayout.OnboardingHeader />
        </Box>

        <OnboardingLayout.Alert>
          <AlertOutlet />
        </OnboardingLayout.Alert>

        <OnboardingLayout.Content>
          <Suspense fallback={<CircleLoader />}>{children}</Suspense>
        </OnboardingLayout.Content>

        <OnboardingLayout.OnboardingFooter />
      </OnboardingLayout.Background>
    </BrandThemeProvider>
  );
}
export default OnboardingUnauth;
