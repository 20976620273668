import { outOfBandThemeColors } from '@/theme/colors';
import { Box, Chip, styled } from '@mui/material';

import newProductImg from '../../../assets/images/new-product.svg';

const { skyBlue, pastelYellow, faintPurple, redOrange, statusGray, lightGreen } = outOfBandThemeColors.alternaleaf;

type ProductStatusLabelProps = {
  newAtLowPrice?: boolean;
  newProduct?: boolean;
  comingSoon?: boolean;
  isOutOfStock?: boolean;
  isConcessionProduct?: boolean;
  campaignDiscountPrice?: boolean;
  isLimitedAvailability?: boolean;
};

const ProductBadgeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const ProductStatusChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginBottom: '1rem',
  zIndex: '100',
  fontSize: theme.typography.body2.fontSize
}));

const NewProductImageContainer = styled(Box)`
  width: 60px;
  height: 60px;
`;

function ProductStatusLabel({
  newAtLowPrice,
  newProduct,
  comingSoon,
  isOutOfStock,
  isConcessionProduct,
  campaignDiscountPrice,
  isLimitedAvailability
}: ProductStatusLabelProps) {
  return (
    <ProductBadgeContainer>
      {isLimitedAvailability && <ProductStatusChip label="Limited availability" sx={{ background: pastelYellow }} />}
      {newAtLowPrice && <ProductStatusChip label="New low price" sx={{ background: lightGreen }} />}
      {comingSoon && <ProductStatusChip label="Coming soon" sx={{ background: redOrange }} />}
      {campaignDiscountPrice && <ProductStatusChip label="Special Offer" sx={{ background: skyBlue }} />}
      {isOutOfStock && <ProductStatusChip label="Out of stock" sx={{ background: statusGray }} />}
      {isConcessionProduct && <ProductStatusChip label="Concession" sx={{ background: faintPurple }} />}
      {newProduct && (
        <NewProductImageContainer>
          <img src={newProductImg} alt="new-product" width="100%" />
        </NewProductImageContainer>
      )}
    </ProductBadgeContainer>
  );
}

export default ProductStatusLabel;
