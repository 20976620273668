import { useFlags } from 'launchdarkly-react-client-sdk';

import { viteAppEnv, viteFFOverrides } from '@/constants/env';
import * as FeatureFlagNames from '@/constants/featureFlags';
import type ffDefaults from '@/constants/ffDefaults';
import { Logger } from '@/utils/logger';

const logger = new Logger('useFeatureFlags');
const FF_OVERRIDE_LOCAL_STORAGE_KEY = 'FEATURE_FLAG_OVERRIDES';

const ENV_WITH_FF_OVERRIDES = ['local', 'test', 'development', 'qa', 'staging'];
const shouldApplyFFOverrides = ENV_WITH_FF_OVERRIDES.includes(viteAppEnv());

const getFFOverrides = (): { [key: string]: boolean } => {
  let overrides = {};
  const localStorageFFOverrides = localStorage.getItem(FF_OVERRIDE_LOCAL_STORAGE_KEY);
  const envFFOverrides = viteFFOverrides();

  if (localStorageFFOverrides || envFFOverrides) {
    try {
      const parsedLocalStorageFFOverrides = localStorageFFOverrides ? JSON.parse(localStorageFFOverrides) : {};

      overrides = {
        ...(envFFOverrides ?? {}),
        ...parsedLocalStorageFFOverrides
      };

      const overridenKeys = Object.entries(overrides).join(' | ');
      logger.info(`Overriding Feature Flags: ${overridenKeys}`);
    } catch {
      overrides = {};
    }
  }

  return overrides;
};

sessionStorage.setItem('FEATURE_FLAG_KEYS', JSON.stringify(Object.values(FeatureFlagNames), null, 2));
const featureFlagOverrides = getFFOverrides();

const useFeatureFlags = () => {
  const flags = {
    ...useFlags<typeof ffDefaults>()
  };

  if (shouldApplyFFOverrides) {
    if (featureFlagOverrides) {
      Object.keys(featureFlagOverrides).forEach((key) => {
        flags[key] = featureFlagOverrides[key];
      });
    }
  }

  return {
    flags
  };
};

export default useFeatureFlags;
