import type { DialogProps, SxProps } from '@mui/material';
import { useTheme } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import type { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { MdChevronLeft, MdClose } from 'react-icons/md';

import Button from '../button/Button';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="left" ref={ref} {...props} />
);

Transition.displayName = 'Transition';

interface Props extends DialogProps {
  title?: string;
  description?: string;
  bodySx?: SxProps;
  backButtonText?: string;
}

export default function Dialog({ title, description, bodySx, backButtonText, onClose, children, ...rest }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.down('md'));

  const mobileOptions = isMobile ? { fullScreen: true, TransitionComponent: Transition } : null;

  return (
    <MuiDialog
      PaperProps={{ sx: { width: !isMobile ? '32rem' : 'auto', p: 4, ...bodySx } }}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...rest}
      {...mobileOptions}
    >
      {isMobile && (
        <Button
          size="small"
          variant="text"
          sx={{ justifyContent: 'flex-start' }}
          startIcon={<MdChevronLeft />}
          onClick={(e) => onClose && onClose(e, 'escapeKeyDown')}
        >
          {backButtonText}
        </Button>
      )}
      <Typography id="dialog-title" variant="h6" component="h2">
        {title}
        {!isMobile && (
          <IconButton
            aria-label="close"
            onClick={(e) => onClose && onClose(e, 'escapeKeyDown')}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme?.palette?.grey?.[600]
            }}
          >
            <MdClose />
          </IconButton>
        )}
      </Typography>
      {description && (
        <Typography id="dialog-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
      )}
      {children}
    </MuiDialog>
  );
}
