import ListTableCell from '@/components/common/Table/ListTableCell';
import { getPrescriptionUrl } from '@/services/data.service';
import type { PatientPrescription } from '@/types';
import { formatISODateHelper } from '@/utils/helpers';
import { dateColumnSort } from '@/utils/table';
import { PaginationVariant, Table, toast } from '@montugroup/design-system';
import { Box, Link, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';

type PatientPrescriptionsProps = {
  prescriptions: PatientPrescription[];
};

const openPrescription = async (key: string) => {
  const response = await getPrescriptionUrl(key);
  if (!response || !response.data) {
    toast.warn('Prescription not found');
    return;
  }
  window.open(response.data);
};

const columns: ColumnDef<PatientPrescription>[] = [
  {
    accessorKey: 'url',
    header: 'ID',
    enableSorting: true,
    cell: ({ row }) => (
      <Link component="button" variant="body2" onClick={() => openPrescription(row.original.url)}>
        {row.original.orderCode}
      </Link>
    )
  },
  {
    accessorKey: 'orderDate',
    header: 'Created',
    enableSorting: true,
    sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.orderDate, rowB.original.orderDate),
    cell: ({ row }) => (
      <Box minWidth={80}>{row.original.orderDate ? formatISODateHelper(row.original.orderDate) : '-'}</Box>
    )
  },
  {
    accessorKey: 'expiryDate',
    header: 'Expiry',
    enableSorting: true,
    sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.expiryDate, rowB.original.expiryDate),
    cell: ({ row }) => (
      <Box minWidth={80}>{row.original.expiryDate ? formatISODateHelper(row.original.expiryDate) : '-'}</Box>
    )
  },
  {
    accessorKey: 'productName',
    header: 'Medication',
    enableSorting: true,
    cell: ({ row }) => (
      <ListTableCell
        items={row.original.productName.map((name, index) => ({ key: `medication-${name}-${index}`, value: name }))}
      />
    )
  },
  {
    accessorKey: 'doctorName',
    header: 'Doctor',
    enableSorting: true
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    enableSorting: true,
    cell: ({ row }) => (
      <ListTableCell
        items={row.original.quantity.map((qty, index) => ({ key: `quantity-${qty}-${index}`, value: qty }))}
      />
    )
  },
  {
    accessorKey: 'repeats',
    header: 'Repeats',
    enableSorting: true,
    cell: ({ row }) => (
      <ListTableCell
        items={row.original.repeats.map((repeat, index) => ({ key: `repeat-${repeat}-${index}`, value: repeat }))}
      />
    )
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableSorting: true
  }
];

export function PatientPrescriptions({ prescriptions }: PatientPrescriptionsProps) {
  if (!prescriptions.length) {
    return null;
  }

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <Typography variant="h6">Prescriptions</Typography>
      <Table
        data={prescriptions}
        columns={columns}
        hasRowBackgroundColor={false}
        showPagination={prescriptions.length > 5}
        pageSize={5}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        initialSort={[{ id: 'orderDate', desc: true }]}
      />
    </Box>
  );
}
