import type { OrderDetails, OrderDetailsProducts } from '@/hooks/order/useGetOrderDetails';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  FeesContainer,
  OrderBreakdownContainer,
  ProductItemContainer,
  ProductsContainer,
  StyledTypography,
  TotalContainer
} from './OrderBreakdown.styles';
import { getSplitOrderProps, orderPriceText, orderTotal } from './util';

type BaseOrderBreakdownProps = {
  dispensingFee?: number;
  isRefundedOrCancelled?: boolean;
  lineItems: OrderDetailsProducts[];
  cancelledLineItems: OrderDetailsProducts[];
  refundedAmount: number;
  totalPrice: number;
};

type StandardOrderBreakdownProps = BaseOrderBreakdownProps & {
  splitOrder?: false;
};

export type SplitOrderBreakdownProps = BaseOrderBreakdownProps & {
  splitOrder: true;
  lineItemsTotalPrice: number;
};

export type OrderBreakdownProps = StandardOrderBreakdownProps | SplitOrderBreakdownProps;

type LineItemProps = {
  title: string;
  amount: string | number;
  subtitle?: string;
  isNegative?: boolean;
};

type OrderBreakdownLayoutProps = OrderBreakdownProps & {
  headerText: string;
  renderRefund: () => React.ReactNode;
  renderTotal: () => React.ReactNode;
};

function ProductItem({ id, Product, quantity, price, isCancelled }: OrderDetailsProducts & { isCancelled?: boolean }) {
  return (
    <ProductItemContainer key={id}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledTypography
          variant="body2"
          sx={{
            maxWidth: {
              xs: '200px',
              sm: 'none'
            }
          }}
        >
          {Product.name}
        </StyledTypography>
        <StyledTypography variant="body2" fontWeight="bold">
          {isCancelled ? '-' : ''}${orderPriceText(price ?? '0')}
        </StyledTypography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledTypography variant="body2">Qty: {quantity}</StyledTypography>
      </Box>
    </ProductItemContainer>
  );
}

function LineItem({ title, amount, subtitle, isNegative }: LineItemProps) {
  return (
    <FeesContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledTypography variant="body2">{title}</StyledTypography>
        <StyledTypography variant="body2" fontWeight="bold">
          {isNegative ? '-' : ''}${orderPriceText(amount)}
        </StyledTypography>
      </Box>
      {subtitle && (
        <Box>
          <Typography variant="body2">{subtitle}</Typography>
        </Box>
      )}
    </FeesContainer>
  );
}

function OrderBreakdownLayout({
  headerText,
  lineItems,
  cancelledLineItems,
  dispensingFee,
  isRefundedOrCancelled,
  renderRefund,
  renderTotal
}: OrderBreakdownLayoutProps) {
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <OrderBreakdownContainer>
      <Typography
        variant="subtitle2"
        fontWeight="bold"
        lineHeight={isLargeView ? 1.3 : 1.6}
        sx={{ marginBottom: isLargeView ? 3 : 2 }}
      >
        {headerText}
      </Typography>

      <ProductsContainer>
        {lineItems.map((item) => (
          <ProductItem key={item.id} {...item} />
        ))}
        {cancelledLineItems.map((item) => (
          <ProductItem key={item.id} {...item} isCancelled />
        ))}
      </ProductsContainer>

      <Divider />
      {dispensingFee ? (
        <>
          <LineItem
            title="Shipping fee"
            amount={dispensingFee}
            subtitle="(inclusive of GST)"
            isNegative={isRefundedOrCancelled}
          />
          <Divider />
        </>
      ) : null}
      {renderRefund()}
      {renderTotal()}
    </OrderBreakdownContainer>
  );
}

function SplitOrderBreakdown(props: SplitOrderBreakdownProps) {
  const { dispensingFee, isRefundedOrCancelled, lineItemsTotalPrice, refundedAmount } = props;

  return (
    <OrderBreakdownLayout
      {...props}
      headerText="Order Items"
      renderRefund={() =>
        isRefundedOrCancelled || refundedAmount > 0 ? (
          <>
            <LineItem title="Refund" amount={refundedAmount} isNegative={true} />
            <Divider />
          </>
        ) : null
      }
      renderTotal={() => (
        <TotalContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Total</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {isRefundedOrCancelled ? '$0.00' : orderTotal(dispensingFee ?? 0, lineItemsTotalPrice ?? 0)}
            </Typography>
          </Box>
        </TotalContainer>
      )}
    />
  );
}

function StandardOrderBreakdown(props: StandardOrderBreakdownProps) {
  const { isRefundedOrCancelled, totalPrice } = props;

  return (
    <OrderBreakdownLayout
      {...props}
      headerText="Order Items"
      renderRefund={() =>
        isRefundedOrCancelled ? (
          <>
            <LineItem title="Refund" amount={totalPrice} isNegative={true} />
            <Divider />
          </>
        ) : null
      }
      renderTotal={() => (
        <TotalContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="subtitle1">Total</Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              {isRefundedOrCancelled ? '$0.00' : `$${orderPriceText(totalPrice)}`}
            </Typography>
          </Box>
        </TotalContainer>
      )}
    />
  );
}

export const getOrderBreakdownProps = ({
  dispensingFee,
  orderDetails,
  isRefundedOrCancelled,
  splitOrder = false
}: {
  dispensingFee?: number;
  orderDetails: OrderDetails[];
  isRefundedOrCancelled?: boolean;
  splitOrder?: boolean;
}): OrderBreakdownProps => {
  if (splitOrder && orderDetails.length > 1) {
    return getSplitOrderProps(orderDetails, dispensingFee, isRefundedOrCancelled);
  }

  const order = orderDetails[0];

  const {
    OrderProducts = [],
    total_price = 0 // eslint-disable-line camelcase
  } = order;

  const totalPrice = Number(total_price) || 0;

  return {
    lineItems: isRefundedOrCancelled ? [] : OrderProducts,
    cancelledLineItems: isRefundedOrCancelled ? OrderProducts : [],
    totalPrice,
    refundedAmount: isRefundedOrCancelled ? totalPrice : 0,
    isRefundedOrCancelled,
    dispensingFee
  };
};

export function OrderBreakdown(props: OrderBreakdownProps) {
  if (props.splitOrder) {
    return <SplitOrderBreakdown {...props} />;
  }
  return <StandardOrderBreakdown {...props} />;
}

export default OrderBreakdown;
