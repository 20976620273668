import { useQuery } from '@tanstack/react-query';

import { searchPatients } from '@/services/data.service';
import type { TableSortOrder } from '@/types';

export type UseSearchPatientsParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
};

/**
 *
 * Search for patients
 *
 * @param params - Parameters to pass to the request
 * @param params.page - The page to retrieved
 * @param params.pageSize - The size of the page to retrieve
 * @param params.sortingOrder - The current TableSortOrder
 * @param params.filter - The input filter from the user
 *
 * @returns UseQueryResult the query result
 */
export function useSearchPatients({ page, pageSize, sortingOrder, filter }: UseSearchPatientsParams) {
  const { isLoading, data, isError, isSuccess, error } = useQuery({
    queryKey: ['searchPatients', page, pageSize, sortingOrder, filter],
    queryFn: async ({ signal }) => (await searchPatients({ page, pageSize, sortingOrder, filter, signal })).data
  });

  return { isLoading, data, isError, isSuccess, error };
}
