import { UserService } from '@/services/user.service';
import type { PharmacistLoginFormData } from '@/types/pharmacist.types';
import { Logger } from '@/utils/logger';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import { toast } from '@montugroup/design-system';
import type { ChangeEvent, FormEvent } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const logger = new Logger('usePharmacistSetPassword');

function usePharmacistSetPassword(initialState: PharmacistLoginFormData, onBoardKey: string) {
  const validator = new SimpleReactValidator();
  const navigate = useNavigate();

  const [data, setData] = useState<PharmacistLoginFormData & { rePassword?: string }>({
    ...initialState,
    rePassword: ''
  });
  const [errors, setErrors] = useState({
    email: validator.message('email', data.email.trim(), 'required|email'),
    password: validator.message('password', data.password, 'required'),
    rePassword: validator.message('rePassword', data.rePassword, `required|in:${data.password}`, {
      messages: { in: 'Passwords do not match' }
    })
  });
  const [load, setLoad] = useState(false);

  useEffect(() => {
    (async () => {
      if (onBoardKey) {
        try {
          const { data } = await UserService.getUserOnboardEmail(onBoardKey);
          if (!data) {
            toast.warn('Invalid key. Please try again!');
            navigate('/login/pharmacist');
            return;
          }
          if (data.expired) {
            toast.warn('The link has expired. Please reset your password.');
            navigate('/forgot-password/pharmacist');
            return;
          }
          setData((prev) => ({ ...prev, email: data.email }));
        } catch (e) {
          logger.error('Unexpected error with pharmacist onboard key', e);
          toast.error('Unexpected error. Please retry later.');
          navigate('/login/pharmacist');
        }
      }
    })();
  }, [onBoardKey, navigate]);

  const handleUserInput = (prop: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: prop === 'checkbox' ? e.target.checked : e.target.value });
  };

  const validateFields = (): boolean => {
    const validationErrors = {
      email: validator.message('email', data.email.trim(), 'required|email'),
      password: validator.message('password', data.password, 'required'),
      rePassword: validator.message('rePassword', data.rePassword, `required|in:${data.password}`, {
        messages: { in: 'Passwords do not match' }
      })
    };
    setErrors(validationErrors);
    return Object.values(validationErrors).every((err) => !err);
  };

  const onRegister = async (formData: PharmacistLoginFormData, e: FormEvent): Promise<void> => {
    e.preventDefault();
    if (!validateFields()) {
      validator.showMessages();
      return;
    }
    setLoad(true);
    try {
      const user = await UserService.registerUserPassword({
        token: onBoardKey,
        password: formData.password
      });
      if (user) {
        setData({ email: '', password: '', rePassword: '', loginType: 'pharmacist' });
        setLoad(false);
        toast.success('Registered Successfully. Please Login now.', {});
        navigate('/login/pharmacist', { replace: true });
      }
    } catch (err) {
      toast.error('Error Occurred!. Try again later!', {});
      logger.error('handleRegister', err);
      setLoad(false);
    }
  };

  return {
    data,
    errors,
    setErrors,
    handleUserInput,
    onRegister,
    load,
    validator
  };
}

export default usePharmacistSetPassword;
