import { useTableFilter } from '@/hooks/table/useTableFilter';
import { noOp } from '@/utils/noOp';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';

type ViewPatientsData = {
  filter: string;
  onFilterChange: (filter: string) => void;
};

export const ViewPatientsContext = createContext<ViewPatientsData>({
  filter: '',
  onFilterChange: noOp
});

export function ViewPatientsProvider(props: PropsWithChildren) {
  const { children } = props;
  const { filter, onFilterChange } = useTableFilter();

  const value = useMemo(
    () => ({
      filter,
      onFilterChange
    }),
    [filter]
  );

  return <ViewPatientsContext.Provider value={value}>{children}</ViewPatientsContext.Provider>;
}

export const useViewPatients = () => {
  const { filter, onFilterChange } = useContext(ViewPatientsContext);

  return { filter, onFilterChange };
};
