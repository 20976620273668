import type { HelpdeskScheduleWindow } from '@/utils/helpdesk';
import { Alert, AlertTitle, Chip, Typography } from '@mui/material';

const DATE_TIME_FORMAT = 'd/M/yyyy h:mma';

interface Props {
  schedule: HelpdeskScheduleWindow;
  label: string;
  type: 'info' | 'success';
}

const HelpdeskScheduleStatus = ({ schedule, label, type }: Props) => {
  return (
    <Alert severity={type} variant="outlined" icon={false}>
      <AlertTitle alignItems="center">
        <Chip label={label} color={type} sx={{ mr: 2 }} /> {schedule.name}
      </AlertTitle>
      <Typography variant="body2">
        <strong>start</strong>: {schedule.start.toFormat(DATE_TIME_FORMAT).toLowerCase()}
      </Typography>
      <Typography variant="body2">
        <strong>end</strong>: {schedule.end.toFormat(DATE_TIME_FORMAT).toLowerCase()}
      </Typography>
    </Alert>
  );
};

export default HelpdeskScheduleStatus;
