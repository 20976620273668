import { FF_ENABLE_BRAINFISH, FF_ENABLE_HELPDESK_SCHEDULING, FF_ENABLE_ZENDESK_WIDGET } from '@/constants/featureFlags';
import useHelpdeskSchedule from '@/hooks/helpdesk/useHelpdeskSchedule';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import BrainfishHelpdesk from './BrainfishHelpdesk';
import ZendeskChatWidget from './ZendeskChatWidget';

function HelpdeskSelector() {
  const { flags } = useFeatureFlags();
  const { active } = useHelpdeskSchedule();

  if (flags[FF_ENABLE_HELPDESK_SCHEDULING]) {
    if (active?.externalId === 'zendesk') {
      return <ZendeskChatWidget />;
    }

    if (active?.externalId === 'brainfish') {
      return <BrainfishHelpdesk />;
    }

    return null;
  }

  // Temporary fallbacks to enable Zendesk and Brainfish directly;
  // To be removed once scheduling is has been confirmed in production
  if (flags[FF_ENABLE_ZENDESK_WIDGET]) {
    return <ZendeskChatWidget />;
  }

  if (flags[FF_ENABLE_BRAINFISH]) {
    return <BrainfishHelpdesk />;
  }

  return null;
}

export default HelpdeskSelector;
