import type { AddressFormReturn, UseAddressFormValues } from '@/hooks/user/useAddressForm';
import type { IState } from '@/types/user.types';
import { LoadingButton } from '@/ui-library';
import type { ButtonProps, TextFieldVariants } from '@mui/material';
import { Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import type { FieldValues, SubmitHandler } from 'react-hook-form';

type SubmitValues = {
  addressLine1: string;
  addressLine2?: string;
  suburb: string;
  postcode: string;
  state: string;
};

export type ManualAddressFormProps = {
  onSubmit: (data: SubmitValues) => Promise<UseAddressFormValues | void> | UseAddressFormValues | void;
  states: IState[];
  formMethods: AddressFormReturn;
  onError?: (errors: FieldValues) => void;
  variant?: TextFieldVariants;
  buttonProps?: ButtonProps;
  isLoading?: boolean;
};

function ManualAddressForm({
  onSubmit,
  states,
  formMethods,
  onError,
  variant = 'outlined',
  buttonProps,
  isLoading = false
}: ManualAddressFormProps) {
  const {
    handleSubmit,
    defaultControllers: { controlAddressLine1, controlAddressLine2, controlPostcode, controlState, controlSuburb },
    formState: { isSubmitting, isDirty }
  } = formMethods;

  const {
    field: { onChange: addressLine1Change, value: addressLine1Value },
    fieldState: { error: addressLine1Error }
  } = controlAddressLine1;
  const {
    field: { onChange: addressLine2Change, value: addressLine2Value },
    fieldState: { error: addressLine2Error }
  } = controlAddressLine2;
  const {
    field: { onChange: suburbChange, value: suburbValue },
    fieldState: { error: suburbError }
  } = controlSuburb;
  const {
    field: { onChange: postcodeChange, value: postcodeValue },
    fieldState: { error: postcodeError }
  } = controlPostcode;
  const {
    field: { onChange: stateChange, value: stateValue },
    fieldState: { error: stateError }
  } = controlState;

  const transformSubmit: SubmitHandler<UseAddressFormValues> = async ({
    addressLine1,
    addressLine2,
    postcode,
    state,
    suburb
  }) => {
    await onSubmit({
      addressLine1,
      addressLine2,
      postcode,
      state,
      suburb
    });
  };

  return (
    <Box component="form" role="form" onSubmit={handleSubmit(transformSubmit, onError)} sx={{ mt: 6 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
          <TextField
            variant={variant}
            fullWidth
            required
            id="addressLine1"
            label="Street address 1"
            InputLabelProps={{ shrink: true }}
            onChange={addressLine1Change}
            value={addressLine1Value}
            disabled={isSubmitting}
            error={Boolean(addressLine1Error)}
            helperText={addressLine1Error?.message}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            variant={variant}
            fullWidth
            id="addressLine2"
            label="Street address 2"
            InputLabelProps={{ shrink: true }}
            onChange={addressLine2Change}
            value={addressLine2Value}
            disabled={isSubmitting}
            error={Boolean(addressLine2Error)}
            helperText={addressLine2Error?.message}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            variant={variant}
            required
            fullWidth
            id="suburb"
            label="Suburb"
            InputLabelProps={{ shrink: true }}
            onChange={suburbChange}
            value={suburbValue}
            error={Boolean(suburbError)}
            helperText={suburbError?.message}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            variant={variant}
            select
            fullWidth
            required
            label="State/Territory"
            id="state"
            InputLabelProps={{ shrink: true }}
            value={stateValue}
            error={Boolean(stateError)}
            helperText={stateError?.message}
            onChange={stateChange}
          >
            <MenuItem value="select" disabled>
              Select
            </MenuItem>
            {states.map((state) => (
              <MenuItem key={state.id} value={state.name}>
                {state.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            variant={variant}
            fullWidth
            required
            type="number"
            id="postcode"
            label="Postcode"
            InputLabelProps={{ shrink: true }}
            onChange={postcodeChange}
            value={postcodeValue}
            error={Boolean(postcodeError)}
            helperText={postcodeError?.message}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="caption">* Required fields</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoadingButton
            type="submit"
            disabled={isSubmitting || !isDirty || isLoading}
            size="large"
            variant="contained"
            sx={{
              mt: 2
            }}
            {...buttonProps}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ManualAddressForm;
