import { useUnableToDispenseModal } from '@/context/orders/OrdersModals';
import useGetUnableToDispenseInfo from '@/hooks/orders/useGetUnableToDispenseInfo';
import type { UnableToDispenseInfo, UnableToDispenseInfoProductDetails } from '@/types';
import { Button, Table } from '@montugroup/design-system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FlagIcon from '@mui/icons-material/Flag';
import type { BoxProps } from '@mui/material';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tooltip, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

interface TruncatedTextProps extends BoxProps {
  maximumWidth: string;
}

const TruncatedText = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maximumWidth'
})<TruncatedTextProps>(({ maximumWidth }: { maximumWidth: string }) => ({
  maxWidth: maximumWidth,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const orderTableColumns: ColumnDef<UnableToDispenseInfo>[] = [
  {
    accessorKey: 'paidDate',
    header: 'Paid date',
    enableSorting: false
  },
  {
    accessorKey: 'patientCode',
    header: 'Patient ID',
    enableSorting: false
  },
  {
    accessorKey: 'prescriptions',
    header: 'Prescription',
    enableSorting: false,
    cell: ({ row }) => (row.original.prescriptions.length ? row.original.prescriptions.join(', ') : '')
  }
];

const orderProductTableColumns: ColumnDef<UnableToDispenseInfoProductDetails>[] = [
  {
    accessorKey: 'name',
    header: 'Product',
    enableSorting: false,
    cell: ({ row }) => (
      <Tooltip title={row.original.name}>
        <TruncatedText maximumWidth="10rem">{row.original.name}</TruncatedText>
      </Tooltip>
    )
  },
  {
    accessorKey: 'qty',
    header: 'Quantity',
    enableSorting: false,
    cell: ({ row }) => <Box sx={{ textAlign: 'center' }}>{row.original.qty}</Box>
  },
  {
    accessorKey: 'otherDispenseReasonTxt',
    header: 'Reason',
    enableSorting: false,
    cell: ({ row }) => (
      <Tooltip title={row.original.otherDispenseReasonTxt}>
        <TruncatedText maximumWidth="12rem">{row.original.otherDispenseReasonTxt}</TruncatedText>
      </Tooltip>
    )
  },
  {
    accessorKey: 'canDispense',
    header: '',
    enableSorting: false,
    cell: ({ row }) => {
      if (row.original.canDispense) {
        return <CheckCircleIcon color="success" />;
      }
      return <FlagIcon color="error" />;
    }
  }
];

const additionalCommentsColumns: ColumnDef<UnableToDispenseInfoProductDetails>[] = [
  {
    accessorKey: 'additionalComment',
    header: 'Additional comment',
    enableSorting: false,
    cell: ({ row }) => (row.original.additionalComment ? row.original.additionalComment : 'No comment')
  }
];

function UnableToDispenseModal() {
  const { visible, orderId, hideUnableToDispenseModal } = useUnableToDispenseModal();

  const { data, isPending } = useGetUnableToDispenseInfo({ orderId });

  const hasAdditionalComments = useMemo(
    () => data?.products.some((product) => product.additionalComment?.trim()),
    [data]
  );

  return (
    <Dialog open={visible} onClose={hideUnableToDispenseModal}>
      <DialogTitle>Unable to dispense {data?.orderCode}</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Table
            isLoading={isPending}
            data={data ? [data] : []}
            columns={orderTableColumns}
            hasRowBackgroundColor={false}
          />
          <Table
            isLoading={isPending}
            data={data?.products ?? []}
            columns={orderProductTableColumns}
            hasRowBackgroundColor={false}
          />
          {hasAdditionalComments && (
            <Table data={data?.products ?? []} columns={additionalCommentsColumns} hasRowBackgroundColor={false} />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={hideUnableToDispenseModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UnableToDispenseModal;
