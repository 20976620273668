import type { ReactNode } from 'react';

import { Background } from './Onboarding.styles';

export type OnboardingBackgroundProps = {
  children: ReactNode;
};

function OnboardingBackground({ children }: OnboardingBackgroundProps) {
  return <Background>{children}</Background>;
}

export default OnboardingBackground;
