import { Check, LockClock } from '@mui/icons-material';
import { Chip } from '@mui/material';

import constants from '../../../constants/constants';

type OrderStatus =
  | 'delivered'
  | 'shippedByPharmacy'
  | 'orderPlaced'
  | 'cancelled'
  | 'splitOrder'
  | 'refunded'
  | 'intervalTime';
type OrderStatusChipProps = {
  status: OrderStatus;
  text?: string;
};
const defaultTexts: Record<OrderStatus, string> = {
  orderPlaced: 'Order placed',
  shippedByPharmacy: 'Shipped by pharmacy',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
  splitOrder: 'Split order',
  refunded: 'Refunded',
  intervalTime: 'Interval time'
};

const statusStyles: Record<
  OrderStatus,
  { variant: 'filled' | 'outlined'; colour: 'default' | 'primary' | 'secondary' | 'success' | 'warning' }
> = {
  orderPlaced: { variant: 'filled', colour: 'default' },
  shippedByPharmacy: { variant: 'filled', colour: 'default' },
  splitOrder: { variant: 'filled', colour: 'default' },
  delivered: { variant: 'filled', colour: 'success' },
  cancelled: { variant: 'filled', colour: 'warning' },
  refunded: { variant: 'filled', colour: 'default' },
  intervalTime: { variant: 'filled', colour: 'default' }
};

export const getOrderStatusChipProps = ({
  status,
  delivered,
  splitOrder = false
}: {
  status: number;
  delivered: boolean;
  splitOrder?: boolean;
}): OrderStatusChipProps => {
  if (status === constants.orderStatus.REFUNDED) {
    return { status: 'refunded' };
  }

  if (splitOrder && status !== constants.orderStatus.CANCELLED && !delivered) {
    return { status: 'splitOrder' };
  }

  if (
    status === constants.orderStatus.AWAITING_PAYMENT ||
    status === constants.orderStatus.PAID_NOT_APPROVED ||
    status === constants.orderStatus.APPROVED_NOT_PAID ||
    status === constants.orderStatus.AWAITING_SHIPMENT ||
    status === constants.orderStatus.AWAITING_DISPENSE
  ) {
    return { status: 'orderPlaced' };
  }

  if (status === constants.orderStatus.PHARMACY_DISPENSED && !delivered) {
    return { status: 'shippedByPharmacy' };
  }

  if (delivered && status !== constants.orderStatus.CANCELLED) {
    return { status: 'delivered' };
  }

  if (status === constants.orderStatus.CANCELLED) {
    return { status: 'cancelled' };
  }

  if (status === constants.orderStatus.INTERVAL_TIME) {
    return { status: 'intervalTime' };
  }

  return { status: 'orderPlaced' };
};

export function OrderStatusChip(props: OrderStatusChipProps) {
  const { status, text } = props;
  const { variant, colour } = statusStyles[status];

  return (
    <Chip
      label={text || defaultTexts[status]}
      {...(status === 'delivered' && { icon: <Check /> })}
      {...(status === 'intervalTime' && { icon: <LockClock /> })}
      variant={variant} // using MUI built-in prop to match with theme
      color={colour}
    />
  );
}

export default OrderStatusChip;
