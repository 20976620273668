import ViewPatientsActions from '@/components/admin/viewPatients/ViewPatientsActions';
import ViewPatientsTable from '@/components/admin/viewPatients/ViewPatientsTable';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { ViewPatientsProvider } from '@/context/view-patients/ViewPatients';

function ViewPatients() {
  return (
    <ViewPatientsProvider>
      <AdminPageLayout title="Patients">
        <ManagedErrorToasts />
        <ViewPatientsActions />
        <ViewPatientsTable />
      </AdminPageLayout>
    </ViewPatientsProvider>
  );
}

export default ViewPatients;
