import { PatientService } from '@/services/patient.service';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

type PatientNotePayload = {
  patient_id: number;
  note: string;
};

export const usePostPatientNote = () => {
  const mutation = useMutation({
    mutationFn: (payload: PatientNotePayload) => PatientService.postPatientNote(payload),
    onSuccess: () => {},
    onError: () => {
      toast.error('Error while updating Patient note');
    }
  });
  return mutation;
};
