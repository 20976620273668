/* eslint-disable no-console */
import { toast } from '@montugroup/design-system';
import { GoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import LoginFailure from '@/components/auth/login/components/LoginFailure';
import FormInput from '@/components/common/FormInputTyped';
import { EMAIL_PATTERN } from '@/constants/constants';
import USER_ROLES from '@/constants/userRoles';
import { AuthService } from '@/services/authentication.service';
import { UserService } from '@/services/user.service';
import { LoadingButton } from '@/ui-library';
import { Logger } from '@/utils/logger';

import logo from '../../layout/montu.png';
import { FormContainer } from '../authPage';

import '../signIn.scss';

import type { LoginType, SignInFormData } from './types';
import { CONTACT_EMAILS, OTHER_LOGINS } from './types';

const logger = new Logger('DefaultLogInForm');

interface DefaultLogInPageProps {
  loginType: LoginType;
  onBoardKey: string | null;
}

export function DefaultLogInPage(props: DefaultLogInPageProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginType, onBoardKey } = props;

  const { register, handleSubmit, formState, getValues, setValue } = useForm<SignInFormData>({
    defaultValues: {
      email: '',
      password: '',
      rePassword: '',
      remember: true
    }
  });
  const { errors } = formState;

  const [isOnboarding, setIsOnboarding] = React.useState<boolean>(onBoardKey !== null);
  const [loading, setLoad] = React.useState(false);
  const [submissionError, setSubmissionError] = useState('');

  const loggedIn = AuthService.getUser();
  if (loggedIn && loggedIn.user) {
    if (loggedIn.user.role_id === USER_ROLES.patient) {
      navigate('/home', { replace: true });
    } else if (loggedIn.user.role_id === USER_ROLES.pharmacist) {
      navigate('/pharmacist/orders', { replace: true });
    } else {
      navigate('/orders', { replace: true });
    }
  }

  React.useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (params.get('expired')) {
      toast.error('Your session has expired, please login again.', {});
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      if (isOnboarding && onBoardKey) {
        const user = await UserService.getUserOnboardEmail(onBoardKey);
        if (!user.data && onBoardKey) {
          toast.warn('Invalid Key. Please Try again!');
          navigate('/logout');
          return;
        }
        setValue('email', user.data.email);
      }
    })();
  }, [onBoardKey, isOnboarding]);

  const responseSuccessGoogle = async (res: { credential?: string }) => {
    const formData = getValues();
    const isUserExist = await AuthService.login(
      formData.email.toLowerCase(),
      formData.password,
      formData.remember,
      loginType,
      res.credential
    );
    if (isUserExist) {
      navigate('/orders', { replace: true });
    } else {
      setLoad(false);
      AuthService.clearCurrentUserFromStorage();
    }
  };

  const onRegister: SubmitHandler<SignInFormData> = async (formData, e) => {
    e?.preventDefault();
    setLoad(true);
    try {
      const user = await UserService.registerUserPassword({
        token: onBoardKey,
        password: formData.password
      });
      if (user) {
        setIsOnboarding(false);
        setValue('password', '');
        setValue('rePassword', '');
        setLoad(false);
        toast.success('Registered Successfully. Please Login now.', {});
      }
    } catch (err) {
      toast.error('Error Occurred!. Try again later!', {});
      logger.error('handleRegister', err);
      setLoad(false);
    }
  };

  const onSubmit: SubmitHandler<SignInFormData> = async (formData, e) => {
    e?.preventDefault();

    setLoad(true);
    setSubmissionError('');

    const userFrom = location?.state?.from?.pathname || '';
    let user;

    try {
      user = await AuthService.login(
        formData.email.toLowerCase().trim(),
        formData.password,
        formData.remember,
        loginType,
        ''
      );
    } catch (err) {
      logger.info('AuthService.login failed: ', err);
      setSubmissionError(err as string);
      setLoad(false);
    }

    if (user) {
      if (user.user?.role_id === USER_ROLES.patient) {
        if (userFrom && !userFrom.includes('logout')) {
          logger.info('navigating to patient: ', userFrom);
          navigate(userFrom, { replace: true });
        } else {
          logger.info('navigating to patient /home');
          navigate('/home', { replace: true });
        }
        return;
      }
      if (user.user?.phone === '') {
        logger.info('navigating to profile detail');
        navigate('/profile-detail', { replace: true });
        toast.success('Complete your profile details');
        return;
      }
      if (userFrom && !userFrom.includes('logout')) {
        logger.info('navigating to: ', userFrom);
        navigate(userFrom, { replace: true });
      } else {
        logger.info('navigating to /orders');
        navigate('/orders', { replace: true });
      }
    }
  };

  const contactEmail = CONTACT_EMAILS.get(loginType);
  const otherLogins = OTHER_LOGINS.get(loginType) || [];

  return (
    <div className="auth-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 mx-auto mob-signin">
            <div className="container p-3">
              <div>
                <a href="https://www.montu.com.au" target="blank">
                  <img src={logo} alt="montu" className="p-2 montu-logo" />
                </a>
              </div>
              <div className="card w-100  shadow-lg auth-border">
                <div className="row auth-border">
                  <div className="col-md-6">
                    <div className="auth-opacity auth-image-border">
                      <img
                        src="/assets/images/back.png"
                        className="w-100 auth-image auth-image-border img-fluid"
                        alt="MONTU"
                      />
                      <div className="centered text-left">
                        A platform for healthcare professionals to manage their medicinal cannabis patients and
                        prescriptions.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 login-response mt-5">
                    <div className="sign-in mt-5">
                      <div className="auth-wrapper mx-auto d-flex justify-content-center">
                        <FormContainer>
                          <LoginFailure error={submissionError} />
                          <form data-private onSubmit={handleSubmit(onSubmit)} method="post">
                            <h2 className="mb-3">
                              {loginType.charAt(0).toUpperCase() + loginType.slice(1)}{' '}
                              {isOnboarding ? 'Register' : 'Login'}
                            </h2>
                            {loginType !== 'admin' && (
                              <div>
                                <FormInput
                                  {...register('email', {
                                    required: 'You must enter an email address',
                                    pattern: {
                                      value: EMAIL_PATTERN,
                                      message: 'You must enter a valid email address'
                                    }
                                  })}
                                  type="text"
                                  id="useremailthree"
                                  label="Your Email"
                                  error={errors.email?.message}
                                  disabled={isOnboarding}
                                />
                                <FormInput
                                  {...register('password', {
                                    required: 'You must enter a password',
                                    minLength: {
                                      value: 5,
                                      message: 'Password must be at least 5 characters'
                                    }
                                  })}
                                  type="password"
                                  id="userpasswordthree"
                                  label="Password"
                                  error={errors.password?.message}
                                />
                              </div>
                            )}
                            {isOnboarding && (
                              <FormInput
                                {...register('rePassword', {
                                  required: true,
                                  minLength: {
                                    value: 5,
                                    message: 'Password must be at least 5 characters'
                                  },
                                  validate: (val, formData) => val === formData.password || 'Passwords must match'
                                })}
                                type="password"
                                name="rePassword"
                                id="re-password"
                                label="Re-Enter Password"
                                error={errors.rePassword?.message}
                              />
                            )}
                            {loginType !== 'admin' && (
                              <LoadingButton
                                type="submit"
                                id="btnSubmitthree"
                                variant="contained"
                                color="primary"
                                className="w-100"
                                loading={loading}
                                onClick={isOnboarding ? handleSubmit(onRegister) : handleSubmit(onSubmit)}
                                data-testid="login-button"
                              >
                                {isOnboarding ? 'Register' : 'Login'}
                              </LoadingButton>
                            )}
                            {loginType !== 'admin' && (
                              <div className="d-flex flex-wrap justify-content-center justify-content-sm-between">
                                <div />
                                <div className="d-flex mt-1 mx-auto text-center">
                                  {otherLogins.map((x, idx) => (
                                    <div key={x}>
                                      <Link className="mx-1" to={`/login/${x}`}>
                                        Login as <span style={{ textTransform: 'capitalize' }}>{x}</span>
                                      </Link>
                                      {otherLogins.length !== idx + 1 && '/'}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {loginType === 'admin' && (
                              <div className="admin-login-button" style={{ marginTop: '30px' }}>
                                <div style={{ width: '220px', margin: '0 auto' }}>
                                  <GoogleLogin
                                    theme="filled_blue"
                                    onSuccess={responseSuccessGoogle}
                                    onError={console.error}
                                  />
                                </div>
                              </div>
                            )}
                            {loginType !== 'admin' && (
                              <div className="d-flex flex-wrap justify-content-center justify-content-sm-between mt-3">
                                <div className="form-check mt-2">
                                  <label className="sub-text" htmlFor="remember">
                                    <input
                                      {...register('remember')}
                                      id="remember"
                                      type="checkbox"
                                      className="form-check-input"
                                    />
                                    Remember me
                                  </label>
                                </div>
                                <div className="mt-2 ml-3 sub-text">
                                  <Link to={`/forgot-password/${loginType}`}>Forgot Password?</Link>
                                </div>
                              </div>
                            )}
                            <span className="mt-2 sub-text">Contact: {contactEmail}</span>
                          </form>
                        </FormContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultLogInPage;
