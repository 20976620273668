import { toast } from '@montugroup/design-system';
import { Badge, Box, Button, styled } from '@mui/material';

import LegacyFilterByOrderStatusSelect from '@/components/common/LegacyFilterByOrderStatusSelect';
import { OrderStatus } from '@/components/order/order.types';
import settings from '@/constants/constants';
import { FF_ENABLE_FILTER_SELECTS_REDESIGN, FF_PHARMACY_FILTER_ORDERS_BY_STATUS } from '@/constants/featureFlags';
import { PharmacistOrderTableView, usePharmacistOrders } from '@/context/pharmacist/orders/PharmacistOrders';
import { usePharmacistConfirmDispenseModal } from '@/context/pharmacist/orders/PharmacistOrdersModals';
import useDownload from '@/hooks/useDownload';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Logger } from '@/utils/logger';

const logger = new Logger('pharmacistOrder');

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end'
  }
}));

function PharmacistOrdersActions() {
  const {
    activeView,
    selectedOrders,
    toggleSelectAllOrders,
    dispenseOrders,
    loading,
    statusFilter,
    setStatusFilter,
    areAnyOrdersSelected
  } = usePharmacistOrders();
  const { showConfirmDispenseModal } = usePharmacistConfirmDispenseModal();
  const { isDownloading, downloadFile } = useDownload();
  const { flags } = useFeatureFlags();

  const generateExport = async () => {
    const fileName = `${new Date().getTime()}-pharmacy-orders`;

    await downloadFile(
      fileName,
      `${settings.url}/excel/pharmacist/exportOrders`,
      {
        method: 'POST',
        responseType: 'blob'
      },
      '.xlsx'
    );
  };

  const handleDispenseOrders = async () => {
    const { status, message, pdfUrl } = await dispenseOrders();
    if (status) {
      if (!pdfUrl || window.open(pdfUrl) !== null) {
        // When no PDF URL, or opened successfully, just show the standard message.
        toast.success(message);
      } else {
        // On failure to open a window, offer a download link
        toast.success(
          {
            title: message,
            message: <a href={pdfUrl}>Download dispensing document</a>
          },
          { autoClose: false }
        );
      }
    } else {
      toast.error(message);
      logger.error('dispenseOrders', message);
    }
  };

  const isStatusSelected = (orderStatus: OrderStatus) => {
    if (statusFilter.length !== 1) {
      return false;
    }
    return statusFilter[0].name === orderStatus;
  };

  return (
    <ActionsContainer>
      {isStatusSelected(OrderStatus.AwaitingDispensing) && (
        <div>
          <Button
            variant="contained"
            size="large"
            color={areAnyOrdersSelected() ? 'secondary' : 'primary'}
            onClick={toggleSelectAllOrders}
            disabled={loading}
          >
            {areAnyOrdersSelected() ? 'Deselect page' : 'Select page'}
          </Button>
        </div>
      )}
      {activeView !== PharmacistOrderTableView.PROCESSING_DISPENSE && (
        <div>
          <Button variant="contained" size="large" onClick={generateExport} disabled={isDownloading || loading}>
            Export all
          </Button>
        </div>
      )}
      {activeView === PharmacistOrderTableView.OPEN_ORDERS && (
        <div>
          <Badge badgeContent={selectedOrders.length} color="primary">
            <Button variant="contained" size="large" onClick={handleDispenseOrders} disabled={loading}>
              Process dispense
            </Button>
          </Badge>
        </div>
      )}
      {activeView === PharmacistOrderTableView.PROCESSING_DISPENSE && (
        <Button variant="contained" size="large" onClick={showConfirmDispenseModal} disabled={loading}>
          Confirm dispense
        </Button>
      )}
      {flags[FF_PHARMACY_FILTER_ORDERS_BY_STATUS] && !flags[FF_ENABLE_FILTER_SELECTS_REDESIGN] && (
        <LegacyFilterByOrderStatusSelect
          allowedStatuses={[
            OrderStatus.AwaitingDispensing,
            OrderStatus.Pending,
            OrderStatus.Test,
            OrderStatus.IntervalTime,
            OrderStatus.PharmacyPartiallyOutOfStock,
            OrderStatus.UnableToDispense
          ]}
          selectedStatuses={statusFilter}
          onSelect={(value) => setStatusFilter(value)}
        />
      )}
    </ActionsContainer>
  );
}

export default PharmacistOrdersActions;
