import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import withFlags from '@/utils/withFlags';
import withRouter from '@/utils/withRouter';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import FilterableTable from 'rc-data-table';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/images/edit.png';
import settings from '../../constants/constants';
import CircleLoader from '../common/circleLoader';

const CancelToken = axios.CancelToken;
let cancel;

class PatientView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageSize: 100,
      count: 0,
      page: 0,
      filter: null,
      sort: { name: 'id', reverse: true },
      loading: true
    };
  }

  /**
   * Fetches the patients from the backend for display.
   *
   * @param {number} page the page to fetch
   * @param {number} pageSize how many items to fetch
   * @param {object} sort contains keys 'reverse' and 'name' for sorting.
   * @param {string} filter the search term to query data with.
   *
   * @returns {Promise<Response>} running search handle.
   */
  searchPatients = (page, pageSize, sort, filter) => {
    return axios.get(`${settings.url}/data/patient`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      params: {
        limit: pageSize,
        offset: page * pageSize,
        sort: sort.name,
        sortOrder: sort.reverse ? 'DESC' : 'ASC',
        filter,
        ts: new Date().getTime()
      }
    });
  };

  debouncedPatientSearch = AwesomeDebouncePromise(this.searchPatients, 300);

  async componentDidMount() {
    document.title = 'Patients - Montu Group | Making Medical Cannabis Accessible';
    await this.dataBind();
  }

  /**
   * Stops all promises/state changes as component is unmounting.
   * @returns {Promise<void>}
   */
  async componentWillUnmount() {
    this.setState = () => {};
  }

  dataBind = async () => {
    const { page, pageSize, sort, filter } = this.state;
    if (cancel) {
      cancel();
    }
    this.debouncedPatientSearch(page, pageSize, sort, filter).then((data) => {
      this.setState({ data: data.data.patients, count: data.data.count, loading: false });
    });
  };

  pageChange = async (page, size) => {
    this.setState({ page: page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
  };

  onFilter = (filter) => {
    this.setState({ filter, page: 0 }, function () {
      this.dataBind();
    });
  };

  render() {
    let { loading, data, sort, count, page, pageSize, filter } = this.state;
    const fields = [
      { name: 'id', displayName: 'id', inputFilterable: true, sortable: true, visible: false },
      {
        name: 'patient_code',
        displayName: 'Patient ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text'
      },
      {
        name: 'patient_name',
        displayName: 'Name',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.first_name'
      },
      {
        name: 'email',
        displayName: 'Email',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.email'
      },
      {
        name: 'phone',
        displayName: 'Phone',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.phone'
      },
      {
        name: 'patient_pharmacy_name',
        displayName: 'Preferred Pharmacy Name',
        inputFilterable: true,
        tdClassName: 'sub-text'
      },
      { name: 'approval', displayName: 'Approval', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'id',
        displayName: 'Edit',
        tdClassName: 'text-center',
        thClassName: 'text-center',
        render: (e) => {
          return (
            <Link to={`/${this.props.flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${e.record.id}`}>
              <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
            </Link>
          );
        }
      }
    ];
    return (
      <div>
        <div className="container-fluid">
          <h3 className="float-left">Patients</h3>
          {loading ? (
            <CircleLoader />
          ) : (
            <FilterableTable
              className="table-responsive w-100 p-2"
              data={data}
              fields={fields}
              topPagerVisible={false}
              paginationOnServer={true}
              initialSort={sort.name}
              initialSortDir={!sort.reverse}
              onPageChanged={this.pageChange}
              filterData={this.onFilter}
              serverSort={this.getSortColumn}
              totalRecords={count}
              currentPage={page}
              pageSize={pageSize}
              filterPlaceholder={filter ? filter : 'Search'}
              pageSizes={false}
              maintainPageOnSetData={true}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withFlags(withRouter(PatientView));
