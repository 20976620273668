import useGetDoctors from '@/hooks/doctors/useGetDoctors';
import useTableCountWithLoading from '@/hooks/table/useTableCountWithLoading';
import { useTableFilter } from '@/hooks/table/useTableFilter';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import type { GPData } from '@/services/data.service';
import { noOp } from '@/utils/noOp';
import type { PaginationModel } from '@montugroup/design-system';
import type { SortingState } from '@tanstack/react-table';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 50;

export enum GP_FILTERS {
  All = '1',
  'Authorised prescriber' = '2',
  'General prescriber' = '3'
}

type DoctorsData = {
  page: number;
  pageSize: number;
  handlePaginationModelChange: (model: PaginationModel) => void;
  handleSortingOrderChange: (sortOrder: SortingState) => void;
  filter: string;
  onFilterChange: (filter: string) => void;
  gpFilter: GP_FILTERS;
  setGpFilter: (filter: GP_FILTERS) => void;
  isLoading: boolean;
  doctors: GPData[];
  doctorCount: number;
};

const DoctorsContext = createContext<DoctorsData>({
  page: INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  handlePaginationModelChange: noOp,
  handleSortingOrderChange: noOp,
  filter: '',
  onFilterChange: noOp,
  gpFilter: GP_FILTERS.All,
  setGpFilter: noOp,
  isLoading: false,
  doctors: [],
  doctorCount: 0
});

const sortFieldOverrides = {
  gp_name: 'Doctor.first_name',
  email: 'Doctor.email',
  joined_date: 'created_date',
  phone: 'Doctor.phone',
  ahpraNo: 'aphra_no'
};

export function DoctorsProvider(props: PropsWithChildren) {
  const { children } = props;
  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel({
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({
    sortFieldOverrides
  });
  const { filter, onFilterChange } = useTableFilter();
  const [gpFilter, setGpFilter] = useState<GP_FILTERS>(GP_FILTERS.All);

  const { data, isFetching } = useGetDoctors({ page, pageSize, sortingOrder, filter, gpFilter });

  const count = useTableCountWithLoading(isFetching, data?.count);

  const value = useMemo(
    () => ({
      page,
      pageSize,
      isLoading: isFetching,
      doctors: data?.doctors ?? [],
      doctorCount: count,
      filter,
      onFilterChange,
      gpFilter,
      setGpFilter,
      handlePaginationModelChange,
      handleSortingOrderChange
    }),
    [
      count,
      data?.doctors,
      filter,
      onFilterChange,
      gpFilter,
      handlePaginationModelChange,
      handleSortingOrderChange,
      isFetching,
      page,
      pageSize
    ]
  );

  return <DoctorsContext.Provider value={value}>{children}</DoctorsContext.Provider>;
}

export const useDoctors = () => {
  const {
    page,
    pageSize,
    isLoading,
    doctors,
    doctorCount,
    filter,
    onFilterChange,
    gpFilter,
    setGpFilter,
    handlePaginationModelChange,
    handleSortingOrderChange
  } = useContext(DoctorsContext);

  return {
    page,
    pageSize,
    isLoading,
    doctors,
    doctorCount,
    filter,
    onFilterChange,
    gpFilter,
    setGpFilter,
    handlePaginationModelChange,
    handleSortingOrderChange
  };
};
