import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import ApprovalDocumentsModal from '@/components/pharmacist/orders/modals/ApprovalDocumentsModal';
import ConfirmDispenseModal from '@/components/pharmacist/orders/modals/ConfirmDispenseModal';
import SplitOrderModal from '@/components/pharmacist/orders/modals/SplitOrderModal';
import UnableToDispenseModal from '@/components/pharmacist/orders/modals/UnableToDispenseModal';
import PharmacistOrdersTable from '@/components/pharmacist/orders/PharmacistOrdersTable';
import PharmacistOrderTableSelector from '@/components/pharmacist/orders/PharmacistOrderTableSelector';
import { PharmacistOrdersProvider } from '@/context/pharmacist/orders/PharmacistOrders';
import { PharmacistOrdersModalsProvider } from '@/context/pharmacist/orders/PharmacistOrdersModals';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

function PharmacistOrders() {
  const { key } = usePortalPartnerBrand();

  return (
    <PharmacistOrdersProvider>
      <PharmacistOrdersModalsProvider>
        <AdminPageLayout title="Orders" themeVariant={key}>
          <ManagedErrorToasts />
          <PharmacistOrderTableSelector />
          <PharmacistOrdersTable />
          <ApprovalDocumentsModal />
          <UnableToDispenseModal />
          <ConfirmDispenseModal />
          <SplitOrderModal />
        </AdminPageLayout>
      </PharmacistOrdersModalsProvider>
    </PharmacistOrdersProvider>
  );
}

export default PharmacistOrders;
