import type { PaginationModel } from '@montugroup/design-system';
import type { SortingState } from '@tanstack/react-table';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import useGetOrders from '@/hooks/orders/useGetOrders';
import useTableCountWithLoading from '@/hooks/table/useTableCountWithLoading';
import { useTableFilter } from '@/hooks/table/useTableFilter';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import type { Order } from '@/services/data.service';
import type { OrderStatus } from '@/types';
import { noOp } from '@/utils/noOp';
import type { QueryObserverResult } from '@tanstack/react-query';

const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 50;

type OrdersData = {
  page: number;
  pageSize: number;
  handlePaginationModelChange: (model: PaginationModel) => void;
  handleSortingOrderChange: (sortOrder: SortingState) => void;
  filter: string;
  onFilterChange: (filter: string) => void;
  statusFilters: OrderStatus[];
  setStatusFilters: (statusFilter: OrderStatus[]) => void;
  pharmacyIds: string[];
  setPharmacyIds: (pharmacyIds: string[]) => void;
  orders: Order[];
  orderCount: number;
  includeSasStatus: boolean;
  loading: boolean;
  selectedOrders: number[];
  setSelectedOrders: (selectedOrders: number[]) => void;
  setShowRecentOrders: (showRecentOrders: boolean) => void;
  refetch: () => Promise<QueryObserverResult | undefined>;
};

export const OrdersContext = createContext<OrdersData>({
  page: INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  handlePaginationModelChange: noOp,
  handleSortingOrderChange: noOp,
  filter: '',
  onFilterChange: noOp,
  statusFilters: [],
  setStatusFilters: noOp,
  pharmacyIds: [],
  setPharmacyIds: noOp,
  orders: [],
  orderCount: 0,
  includeSasStatus: false,
  loading: false,
  selectedOrders: [],
  setSelectedOrders: noOp,
  setShowRecentOrders: noOp,
  refetch: () => Promise.resolve(undefined)
});

const sortFieldOverrides = {
  patient_name: 'Patient.PatientUser.first_name',
  pharmacy_name: 'Pharmacy.name'
} as const;

export function OrdersProvider(props: PropsWithChildren) {
  const { children } = props;
  const {
    page,
    pageSize,
    handlePaginationModelChange: doHandlePaginationModelChange
  } = useTablePaginationModel({
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({
    sortFieldOverrides,
    initialSortingOrder: { name: 'order_date', reverse: true }
  });
  const { filter, onFilterChange } = useTableFilter();
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const [statusFilters, setStatusFilters] = useState<OrderStatus[]>([]);
  const [pharmacyIds, setPharmacyIds] = useState<string[]>([]);
  const [showRecentOrders, setShowRecentOrders] = useState<boolean>(false);

  const { data, isFetching, refetch } = useGetOrders({
    page,
    pageSize,
    sortingOrder,
    filter,
    useFastQuery: true,
    statusFilters: statusFilters.map(({ id }) => id),
    pharmacyIds,
    showRecentOrders
  });

  const count = useTableCountWithLoading(isFetching, data?.count);

  const handlePaginationModelChange = useCallback(
    (model: PaginationModel) => {
      if (model.pageSize !== pageSize) {
        setSelectedOrders([]);
      }
      doHandlePaginationModelChange(model);
    },
    [doHandlePaginationModelChange, pageSize]
  );

  const value = useMemo(
    () => ({
      page,
      pageSize,
      orders: data?.orders ?? [],
      orderCount: count,
      includeSasStatus: data?.includeSasStatus !== false,
      loading: isFetching,
      filter,
      selectedOrders,
      statusFilters,
      pharmacyIds,
      onFilterChange,
      setStatusFilters,
      setPharmacyIds,
      setSelectedOrders,
      handlePaginationModelChange,
      handleSortingOrderChange,
      setShowRecentOrders,
      refetch
    }),
    [
      data?.orders,
      data?.includeSasStatus,
      count,
      selectedOrders,
      filter,
      onFilterChange,
      handlePaginationModelChange,
      handleSortingOrderChange,
      isFetching,
      page,
      pageSize,
      pharmacyIds,
      statusFilters,
      refetch
    ]
  );

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
}

export const useOrders = () => {
  const {
    page,
    pageSize,
    orders,
    orderCount,
    includeSasStatus,
    loading,
    filter,
    selectedOrders,
    statusFilters,
    pharmacyIds,
    onFilterChange,
    setStatusFilters,
    setPharmacyIds,
    setSelectedOrders,
    handlePaginationModelChange,
    handleSortingOrderChange,
    setShowRecentOrders,
    refetch
  } = useContext(OrdersContext);

  const toggleOrderSelection = (orderId: number) => {
    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const clearSelectedOrders = () => {
    setSelectedOrders([]);
  };

  const hasFiltersOrQuery = Boolean(filter) || statusFilters?.length > 0 || pharmacyIds?.length > 0;

  const enableRecentOrders = () => {
    setShowRecentOrders(true);
  };

  const refreshOrders = () => {
    refetch();
  };

  const areAnyOrdersSelected = () => {
    const ordersInPage = orders.map(({ id }) => id);
    const overlappingOrders = selectedOrders.filter((selectedId) => ordersInPage.includes(selectedId));
    return overlappingOrders.length;
  };

  // toggle behaviour:
  // if any orders are checked in the current page, deselects all orders in page
  // else selects all orders in page
  const toggleSelectAllOrders = () => {
    const ordersInPage = orders.map(({ id }) => id);
    if (areAnyOrdersSelected()) {
      setSelectedOrders(selectedOrders.filter((selectedId) => !ordersInPage.includes(selectedId)));
    } else {
      setSelectedOrders([...selectedOrders, ...ordersInPage]);
    }
  };

  return {
    page,
    pageSize,
    orders,
    orderCount,
    includeSasStatus,
    loading,
    filter,
    selectedOrders,
    statusFilters,
    pharmacyIds,
    hasFiltersOrQuery,
    onFilterChange,
    setStatusFilters,
    setPharmacyIds,
    toggleOrderSelection,
    handlePaginationModelChange,
    handleSortingOrderChange,
    enableRecentOrders,
    refreshOrders,
    clearSelectedOrders,
    toggleSelectAllOrders
  };
};
