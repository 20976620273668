import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import { Logger } from '@/utils/logger';
import useUser from './user/useUser';

const logger = new Logger('useSetDatadogUser');

export const useSetDatadogUser = () => {
  const { data: user } = useUser();

  useEffect(() => {
    if (user?.id) {
      if (datadogRum.getUser()?.id === `${user.id}`) {
        return;
      }

      try {
        datadogRum.setUser({
          id: user.id.toString()
        });
        logger.debug('DD RUM :: setUser was successful.', user.id);
      } catch (error) {
        logger.error('DD RUM :: Error setting user key', error);
      }
    }
  }, [user?.id]);
};
