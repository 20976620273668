import { outOfBandThemeColors } from '@/theme/colors';
import { Box, Container, Stack, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

/* shared/layout */
export const OnboardingHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: outOfBandThemeColors.uMeds.merino,
  //background: theme.palette.merino.main -> exists but type error, added color to outOfBandThemeColors for later cleanup.
  padding: `${theme.spacing(4)} 0`,
  [`${theme.breakpoints.up('lg')},${theme.breakpoints.up('xl')}`]: {
    padding: `${theme.spacing(6)} 0`
  }
}));

export const OnboardingContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  [`${theme.breakpoints.up('xs')},${theme.breakpoints.up('sm')},${theme.breakpoints.up('md')}`]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  [`${theme.breakpoints.up('lg')},${theme.breakpoints.up('xl')}`]: {
    paddingLeft: theme.spacing(22),
    paddingRight: theme.spacing(22)
  }
}));

export const ContentContainer = styled(OnboardingContainer)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(6),
  flex: 1
}));

export const Background = styled(Box)(() => ({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: outOfBandThemeColors.uMeds.merino
}));

export const OnboardingFooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.primary.main,
  height: '300px'
}));

/* Footer */
export const StyledStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(16),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    textAlign: 'center',
    gap: theme.spacing(4)
  }
}));

export const StyledNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(16),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4),
    flexDirection: 'column-reverse',
    textAlign: 'center',
    gap: theme.spacing(4)
  }
}));

export const StyledLink = styled(NavLink)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  color: theme.palette.common.white,
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.common.white
  }
}));

export const SocialIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(8),
  '& svg': {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  }
}));

export const StyledFaq = styled(Box)(({ theme }) => ({
  color: outOfBandThemeColors.uMeds.merino,
  marginTop: 0,
  '& .MuiButton-root': {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}));

/* Alerts */
export const AlertContainer = styled(OnboardingContainer)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(8),
    gap: theme.spacing(4)
  },
  [theme.breakpoints.down('md')]: {
    padding: 0
  },
  '&:empty': {
    display: 'none'
  }
}));

export default null;
