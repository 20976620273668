import type { HelpdeskSettingsDto } from '@montugroup/circuit-api-contracts';
import { create } from 'zustand';

interface HelpdeskSettingsState {
  helpdeskSettings: HelpdeskSettingsDto[];
  setHelpdeskSettings: (settings: HelpdeskSettingsDto[]) => void;
  updateHelpdeskSetting: (id: number, update: Partial<HelpdeskSettingsDto>) => void;
}

const useHelpdeskSettingsStore = create<HelpdeskSettingsState>((set) => ({
  helpdeskSettings: [],
  setHelpdeskSettings: (settings) => set({ helpdeskSettings: settings }),
  updateHelpdeskSetting: (id, update) =>
    set((state) => ({
      helpdeskSettings:
        state.helpdeskSettings?.map((helpdesk) => (helpdesk.id === id ? { ...helpdesk, ...update } : helpdesk)) ?? null
    }))
}));

export default useHelpdeskSettingsStore;
