import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Avatar, Box, Button, Divider, Modal, Snackbar, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { FF_ENABLE_WOM_DISCOUNT_INCREASED_COPY, FF_WOM_PRICE, FF_WOM_TNC_COPY } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import { Logger } from '@/utils/logger';

import { outOfBandThemeColors } from '@/theme/colors';
import { PatientReferralActionType, usePatientReferral } from '../../context/PatientReferral';
import { useGenerateReferralCode } from '../../hooks/useGenerateReferralCode';
import { GoogleAnalyticsEventName } from '../../types';

const logger = new Logger('patientReferral');

const modalData = [
  {
    id: 1,
    value: 'Reveal your unique promo code below.'
  },
  {
    id: 2,
    // eslint-disable-next-line quotes
    value: "Share the code and T&C's link with friends and family."
  },
  {
    id: 3,
    value: ''
  }
];

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  padding: '2rem 1.5rem',
  boxShadow: '0px 11px 15px -7px #00000033',
  overflow: 'scroll',
  height: '100%',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    borderRadius: '4px',
    maxWidth: '500px',
    padding: '2rem 1.9rem',
    width: 'calc(100% - 2rem)',
    height: 'unset',
    overflow: 'hidden'
  }
}));

const ModalHeader = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '1.5rem',
  padding: '1rem 2rem',
  fontWeight: 600,
  color: '#181B25DE'
}));

const ImgContainer = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const StepDivider = styled(Divider)(({ theme }) => ({
  height: '10px',
  width: '2px',
  justifyContent: 'center',
  marginLeft: '10px',
  [theme.breakpoints.up('md')]: {
    height: '20px'
  }
}));

const DialogContent = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '0 8',
  gridTemplateColumns: '15% 85%',
  alignItems: 'center',
  gridAutoRows: 'auto 20px',
  [theme.breakpoints.up('md')]: {
    gridAutoRows: 'auto 35px',
    gridTemplateColumns: '8% 92%'
  }
}));

const PromoCodeLabel = styled(Typography)(() => ({
  color: '#181B25DE',
  width: '100%',
  marginTop: '1.5rem',
  marginBottom: '0.5rem',
  letterSpacing: '1px',
  fontWeight: '400',
  border: '1px solid #3004313B',
  padding: '0.5rem',
  borderRadius: '4px',
  textAlign: 'center'
}));

function ModalContent() {
  const { flags } = useFeatureFlags();
  const womPrice = flags[FF_WOM_PRICE];
  const priceAfterDiscount = 59 - womPrice;
  const isWomDiscountIncreased = flags[FF_ENABLE_WOM_DISCOUNT_INCREASED_COPY];

  modalData[2].value = `They can enter the code at the end of the pre-screening form to save $${womPrice} on their initial consultation - only $${priceAfterDiscount} instead of $59.`;

  return (
    <>
      <ImgContainer>
        <img src="/assets/images/word_of_mouth.png" alt="Circuit" />
      </ImgContainer>
      <ModalHeader id="refer-modal-title" variant="h4">
        Share the Care with Alternaleaf
      </ModalHeader>
      <Typography textAlign="center" marginBottom="1.5rem">
        {isWomDiscountIncreased
          ? `We understand that healthcare can sometimes be pricey, but we’re committed to making it more affordable. So we've increased the Share the Care discount from $20 to $${womPrice}. Make quality healthcare more accessible for your friends and family this holiday season in three simple steps.`
          : `Make quality healthcare more accessible for your friends and family this holiday season with a $${womPrice}{' '}
        discount on their initial consultation in three simple steps.`}
      </Typography>
      {modalData.map((referralStep) => (
        <DialogContent key={referralStep.id}>
          <Avatar
            sx={{
              bgcolor: (theme) => theme.palette.secondary.main,
              height: '24px',
              width: '24px',
              fontSize: '16px'
            }}
          >
            {referralStep.id}
          </Avatar>
          <Typography id="modal-modal-description" fontSize="14px">
            {referralStep.value}
          </Typography>
          {referralStep.id !== modalData.length && <StepDivider orientation="vertical" />}
        </DialogContent>
      ))}
    </>
  );
}

function PromoCodeSection({ promoCode }: { promoCode: string }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();

  const onCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(promoCode);
      setSnackbarOpen(true);
      sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.STC_COPY_TO_CLIPBOARD, {
        event_category: 'stc_promo_code_copy',
        event_label: 'stc_code_copied'
      });
    } catch (err) {
      logger.error('Failed to copy to clipboard', err);
    }
  };

  return (
    <>
      <PromoCodeLabel data-testid="promo-code">{promoCode}</PromoCodeLabel>
      <Button
        fullWidth
        sx={{
          marginBottom: '1rem',
          boxShadow: '0px 3px 1px -2px #00000033',
          letterSpacing: '1px',
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.common.white,
          '&:hover': {
            backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
            color: (theme) => `${theme.palette.common.white} !important`
          }
        }}
        onClick={onCopyToClipboard}
      >
        Copy code
      </Button>
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            minWidth: '160px'
          }
        }}
        message="Copied to clipboard!"
      />
    </>
  );
}

function GenerateButton({ isLoading, onClick }: { isLoading: boolean; onClick: () => void }) {
  return (
    <LoadingButton
      fullWidth
      sx={{
        margin: '1rem 0',
        boxShadow: '0px 3px 1px -2px #00000033',
        letterSpacing: '1px'
      }}
      onClick={onClick}
      loading={isLoading}
      variant="contained"
      data-testid="generate-code"
    >
      Generate code
    </LoadingButton>
  );
}

function PatientReferralModal({
  open,
  isReferralCodeCheckLoading
}: {
  open: boolean;
  isReferralCodeCheckLoading: boolean;
}) {
  const { generateReferralCode, isPending, isError, isSuccess, data } = useGenerateReferralCode();
  const { promoCode, dispatch } = usePatientReferral();
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();
  const { flags } = useFeatureFlags();
  const womTncCopy = flags[FF_WOM_TNC_COPY];

  const onGenerateCodeClick = async () => {
    await generateReferralCode();
  };

  useEffect(() => {
    if (isSuccess && data) {
      dispatch({ type: PatientReferralActionType.SET_PROMO_CODE, payload: data.promoCode });
    }
  }, [isSuccess, data]);

  const onClose = () => {
    dispatch({ type: PatientReferralActionType.TOGGLE_OPEN });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="patient-referral-modal"
      aria-describedby="modal used to generate promo codes"
      data-testid="modal-open"
    >
      <ModalContainer>
        <Button
          aria-label="close"
          onClick={onClose}
          variant="text"
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            color: (theme) => theme.palette.grey[500]
          }}
          data-testid="close-modal-button"
        >
          <CloseIcon />
        </Button>
        <ModalContent />
        {isSuccess || promoCode ? (
          <PromoCodeSection promoCode={promoCode} />
        ) : (
          <>
            <GenerateButton isLoading={isPending || isReferralCodeCheckLoading} onClick={onGenerateCodeClick} />
            {isError && <Alert severity="error">There was an error generating your referral code</Alert>}
          </>
        )}
        <>
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'center',
              width: '100%',
              color: outOfBandThemeColors.alternaleaf.deepNavy
            }}
          >
            {womTncCopy} Further terms & conditions apply:
            <a
              href="https://www.alternaleaf.com.au/share-terms"
              className="btn"
              style={{
                textDecoration: 'underline',
                fontSize: '12px',
                color: 'inherit',
                marginLeft: '-0.5rem',
                lineHeight: '0.7'
              }}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.STC_TERMS_CONDITIONS, {
                  event_category: 'stc_terms_conditions',
                  event_label: 'terms_conditions_link'
                })
              }
            >
              View here
            </a>
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'center',
              width: '100%',
              color: outOfBandThemeColors.alternaleaf.deepNavy
            }}
          >
            Our healthcare services may not be suitable for everyone. Speak to one of our health professionals to
            determine whether our services are appropriate. Treatment can affect every person differently, due to their
            personal circumstances.
          </Typography>
        </>
      </ModalContainer>
    </Modal>
  );
}

export default PatientReferralModal;
