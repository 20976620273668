import { PatientService } from '@/services/patient.service';
import { useQuery } from '@tanstack/react-query';

/**
 * Retrieves the list of countries
 * @param patientId - the ID of the patient to retrieve
 * @returns  UseQueryResult
 */
export function useGetPatient(patientId: string) {
  return useQuery({
    queryKey: ['patient', patientId],
    queryFn: async () => await PatientService.getPatient(patientId)
  });
}
