import settings from '@/constants/constants';
import type { PatientDiscount } from '@/types';
import { Table } from '@montugroup/design-system';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Chip, IconButton, Typography, styled } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';

const redirectToShopify = (shopifyDiscountId: string) => {
  const { shopifyDiscountsURL, shopifyShippingDiscountAppURL } = settings;
  window.open(
    `${shopifyDiscountsURL}/${shopifyShippingDiscountAppURL}/${shopifyDiscountId}`,
    '_blank',
    'noopener noreferrer'
  );
};

const Actions = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center'
}));

const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

const Subheader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(4, 0)
}));

const columns: ColumnDef<PatientDiscount>[] = [
  {
    accessorKey: 'id',
    header: 'id',
    enableSorting: true
  },

  {
    accessorKey: 'DiscountType.name',
    header: 'Discount Type',
    enableSorting: false
  },
  {
    accessorKey: 'DiscountReason.reason',
    header: 'Reason',
    enableSorting: false
  },
  {
    accessorKey: 'discount_percentage',
    header: 'Discount Amount',
    enableSorting: false,
    cell: ({ row }) =>
      row.original.discount_percentage ? `${row.original.discount_percentage}%` : `$${row.original.discount_amount}`
  },
  {
    accessorKey: 'Product',
    header: 'Product Name',
    enableSorting: false,
    cell: ({ row }) => (row.original.Product ? row.original.Product : '-')
  },
  {
    accessorKey: 'start_date',
    header: 'Start Date',
    enableSorting: false,
    cell: ({ row }) => DateTime.fromISO(row.original.start_date).toFormat('dd-MMM-yyyy')
  },
  {
    accessorKey: 'end_date',
    header: 'End Date',
    enableSorting: false,
    cell: ({ row }) => DateTime.fromISO(row.original.end_date).toFormat('dd-MMM-yyyy')
  },
  {
    accessorKey: 'DiscountType.status',
    header: 'Status',
    enableSorting: false,
    cell: ({ row }) => (row.original.active ? <Chip label="Active" color="success" /> : <Chip label="Inactive" />)
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) =>
      row.original.active && (
        <Actions
          onClick={() => redirectToShopify(`${row.original.PatientDiscountsShopify[0].shopify_discount_id}`) as any}
        >
          <Edit aria-label="edit" color="action" sx={{ cursor: 'pointer' }} data-testid="edit" />
          <Delete aria-label="delete" color="action" sx={{ cursor: 'pointer' }} data-testid="delete" />
        </Actions>
      )
  }
];

type PatientDiscountProps = {
  patientDiscounts: PatientDiscount[];
};
const PatientDiscounts = ({ patientDiscounts }: PatientDiscountProps) => {
  if (!patientDiscounts.length) {
    return null;
  }

  return (
    <Container>
      <Typography>Patient Discounts</Typography>
      <Subheader variant="body2">
        The discounts listed here are either active or inactive, this does not reflect their usage status.
      </Subheader>
      <Table data={patientDiscounts} columns={columns} columnVisibility={{ id: false }} hasRowBackgroundColor={false} />
    </Container>
  );
};

export default PatientDiscounts;
