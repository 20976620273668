import { getStates } from '@/services/data.service';
import { useQuery } from '@tanstack/react-query';

/**
 * Retrieves the list of states for the given country
 * @param countryId - the id of the country to retrieve states for
 * @returns UseQueryResult the query result
 */
export function useGetStates(countryId: number) {
  return useQuery({
    queryKey: ['states', countryId],
    enabled: countryId !== null && countryId !== undefined,
    queryFn: async () => await getStates(countryId)
  });
}
