import type { AxiosResponse } from 'axios';
import axios from 'axios';

import { apiConfig } from '@/api/api.config';
import type {
  Clinic,
  Country,
  DropdownData,
  GeneralPractictioner,
  GenericStatusResponse,
  GPPatient,
  GPProduct,
  LoginUser,
  Patient,
  PatientDropdown,
  State,
  TableSortOrder
} from '@/types';

import settings from '../constants/constants';

const API_URL = `${settings.url}/data`;
const CUSTOMER_PORTAL_XAPI_URL = `${apiConfig().baseApiUrl}/api/customer-portal/v1`;

// get entire country list around the world
const getCountries = async () => (await axios.get<Country[]>(`${API_URL}/countries`)).data;

// state based on country
const getStates = async (id: string | number) => (await axios.get<State[]>(`${API_URL}/states/${id}`)).data;

const getPatientsForDropdown = async () => axios.get<PatientDropdown[]>(`${API_URL}/patient?dropdown=true`);

export type PatientsForGPResponse = {
  count: number;
  patients: GPPatient[];
};

type GetPatientsForGPParams = {
  pageSize: number;
  page: number;
  sortingOrder: TableSortOrder;
  filter: string;
  signal?: AbortSignal;
};

const getPatientsForGP = async ({ pageSize, page, sortingOrder, filter, signal }: GetPatientsForGPParams) =>
  axios.get<PatientsForGPResponse>(`${API_URL}/patient`, {
    signal,
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sort: sortingOrder.name,
      sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
      filter,
      ts: new Date().getTime()
    }
  });

export type MedicalLiaisonOption = {
  label: string;
  value: number;
};

export type GPUpdateRequestPayload = {
  user: LoginUser;
  medical_liason: MedicalLiaisonOption;
  gp_data: GeneralPractictioner;
  clinics: Clinic[];
  isAP: boolean;
  products: GPProduct[];
  existingProducts: GPProduct[];
};

const GPUpdate = (data: GPUpdateRequestPayload) => axios.post<GenericStatusResponse>(`${settings.url}/gp/edit`, data);

export type GPData = Pick<
  LoginUser,
  'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'address' | 'created_date'
> &
  Pick<GeneralPractictioner, 'medical_Liasion' | 'gp_code'> & {
    medicalLiasion: string | null;
    gp_name: string;
    ahpraNo: string;
    patients_count: number;
    total_prescriptions: number;
    order_date: string | null;
    joined_date: string;
    last_prescription: string;
    clinic?: string;
    clinic_email?: string;
    clinic_code?: string;
    clinic_phone?: string;
    clinic_address?: string;
    clinic_city?: string;
    clinic_stateId?: number;
    clinic_state?: string;
    clinic_country?: string;
    clinic_countryId?: number;
    clinic_postCode?: string;
    clinic1?: string;
    clinic1_email?: string;
    clinic1_code?: string;
    clinic1_phone?: string;
    clinic1_address?: string;
    clinic1_city?: string;
    clinic1_stateId?: number;
    clinic1_state?: string;
    clinic1_country?: string;
    clinic1_countryId?: number;
    clinic1_postCode?: string;
  };

const GetGPs = (gpFilter: string) => {
  const url = new URL(`${API_URL}/doctors`);
  if (gpFilter) {
    url.searchParams.append('gpFilter', gpFilter);
  }
  return axios.get<GPData>(url.toString());
};

export type ApApprovalResponse = {
  user: LoginUser;
  ingredients: DropdownData[];
  formulation: DropdownData[];
};

const getApApprovalFormData = (id: string | number) => axios.get<ApApprovalResponse>(`${API_URL}/apApproval/${id}`);

const getCreditData = () => axios.get(`${API_URL}/patient/credit/`);

export type PrescriptionData = {
  createdDate: string;
  presciptionCode: string;
  productName: string;
  productId: number;
  thcStrength: string | null;
  cbdStrength: string | null;
  dosage: string | null;
  quantity: number;
  repeats: string;
  interval: number | null;
  expiryDate: string | null;
  status: string;
  inActive: boolean;
};

const getPatientPrescriptions = () => axios.get<PrescriptionData[]>(`${API_URL}/patient/prescription`);

export type IPatientOrderSummary = {
  hasOrders: boolean;
  openOrdersCount: number;
};

const getPatientOrderSummary = async () =>
  axios.get<AxiosResponse<IPatientOrderSummary>>(`${CUSTOMER_PORTAL_XAPI_URL}/patient-order-summary`);

export type OrderCreationReason = {
  id: number;
  reason: string;
  active: boolean;
};

const getOrderCreationReasons = async () => axios.get<OrderCreationReason[]>(`${API_URL}/order-creation-reasons`);

type DiscountReason = {
  reason: string;
  id: string;
};
type DiscountType = {
  name: string;
  id: number;
};

const getDiscountReasons = async () => axios.get<DiscountReason[]>(`${API_URL}/discount-reasons`);
const getDiscountTypes = async () => axios.get<DiscountType[]>(`${API_URL}/discount-types`);

type SearchPatientsParams = {
  pageSize: number;
  page: number;
  sortingOrder: any; // todo TableSortOrder
  filter: string;
  signal?: AbortSignal;
};

export type SearchPatient = Pick<
  Patient,
  'id' | 'client_code' | 'patient_code' | 'user_id' | 'patient_pharmacy_name' | 'patient_pharmacy_address'
> &
  Pick<LoginUser, 'first_name' | 'last_name' | 'email' | 'phone' | 'city' | 'zip_code' | 'address'> & {
    state: string;
    circuit_access: string;
    gpname: string;
    approval: string;
  };

export type SearchPatientsResponse = {
  count: number;
  patients: SearchPatient[];
};

const searchPatients = async ({ page, pageSize, sortingOrder, filter, signal }: SearchPatientsParams) =>
  axios.get<SearchPatientsResponse>(`${API_URL}/patient`, {
    signal,
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sort: sortingOrder.name,
      sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
      filter,
      ts: new Date().getTime()
    }
  });

export type GetOrdersParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
  useFastQuery: boolean;
  statusFilter: number[] | string[];
  pharmacyIds: number[] | string[];
  signal?: AbortSignal;
};

export type PrescriberDetail = {
  name: string;
  docUserId: string;
};

export type Order = {
  shipped_date: string;
  sasStatus: any[];
  id: number;
  patient_name: string;
  patient_id: string;
  patient_uniq_id: number;
  patient_address_state_id: number;
  patient_address_state_abbr: string;
  client_id: any;
  is_discharge: any;
  gp_name: string;
  gp_uniq_id: number;
  product_name: string[];
  orderStatus: string;
  order_status_id: number;
  payment_status: string;
  payment_gateway: string;
  quantity: number[];
  order_code: string;
  order_date: string;
  pharmacy_id?: number;
  pharmacy_name: string;
  identifier_name: string;
  prescriber_details: PrescriberDetail[];
  prescription_code: string[];
  prescriber_names: string[];
  prescriber_code: string[];
  prescriber_ids: string[];
  shipment_carrier: any;
  shipment_tracking_id: string;
  consignment_code: string;
  dispensed_date: string;
  transaction_type_id: number;
  modified_date: string;
  shipped?: string;
  paid_date?: string;
};

export type GetOrdersResponse = {
  count: number;
  includeSasStatus: boolean;
  orders: Order[];
};

const getOrders = async ({
  page,
  pageSize,
  sortingOrder,
  filter,
  useFastQuery,
  statusFilter,
  pharmacyIds,
  signal
}: GetOrdersParams) =>
  axios.get<GetOrdersResponse>(`${API_URL}/order`, {
    signal,
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sort: sortingOrder.name,
      sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
      filter,
      useFastQuery: useFastQuery ? 'Y' : 'N',
      statusFilter,
      pharmacyIds,
      ts: new Date().getTime()
    }
  });

export type GetDoctorsParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
  gpFilter: string;
  signal?: AbortSignal;
};

export type GetDoctorResponse = {
  count: number;
  doctors: GPData[];
};

const searchDoctors = async ({ page, pageSize, sortingOrder, filter, gpFilter, signal }: GetDoctorsParams) =>
  axios.get<GetDoctorResponse>(`${API_URL}/doctors`, {
    signal,
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sort: sortingOrder.name,
      sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
      filter,
      gpFilter,
      ts: new Date().getTime()
    }
  });

const getPrescriptionUrl = async (key: string) =>
  axios.get<string>(`${API_URL}/prescriptionUrl`, {
    params: { key }
  });

export {
  getApApprovalFormData,
  getCountries,
  getCreditData,
  getDiscountReasons,
  getDiscountTypes,
  GetGPs,
  getOrderCreationReasons,
  getOrders,
  getPatientOrderSummary,
  getPatientPrescriptions,
  getPatientsForDropdown,
  getPatientsForGP,
  getPrescriptionUrl,
  getStates,
  GPUpdate,
  searchDoctors,
  searchPatients
};
