import { useState } from 'react';

import AdminPageLayout from '@/components/layout/AdminPageLayout';
import AdjustmentOverview from '@/components/pharmacist/inventory/adjustment-overview/AdjustmentOverview';
import PharmacistInventoryTabs from '@/components/pharmacist/inventory/PharmacistInventoryTabs';
import ProductOverview from '@/components/pharmacist/inventory/product-overview/ProductOverview';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

export const tabs = {
  PRODUCT_OVERVIEW: 'productOverview',
  ADJUSTMENT_OVERVIEW: 'adjustmentOverview'
};

function PharmacistInventory() {
  const { key } = usePortalPartnerBrand();
  const [activeTab, setActiveTab] = useState(tabs.PRODUCT_OVERVIEW);

  return (
    <AdminPageLayout title="Inventory" themeVariant={key}>
      <PharmacistInventoryTabs activeTab={activeTab} toggleTab={(tab: string) => setActiveTab(tab)} />
      {activeTab === tabs.PRODUCT_OVERVIEW && <ProductOverview />}
      {activeTab === tabs.ADJUSTMENT_OVERVIEW && <AdjustmentOverview />}
    </AdminPageLayout>
  );
}

export default PharmacistInventory;
