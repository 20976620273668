import { createSvgIcon } from '@mui/material';

const ApprovalIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="none">
    <path
      d="M34.2391 37.7187H20.8204C19.9817 37.7187 19.3401 37.0453 19.3856 36.211L19.7039 30.1801C19.7493 29.3458 20.4617 28.6724 21.3004 28.6724H34.719C35.5577 28.6724 36.1993 29.3458 36.1539 30.1801L35.8356 36.211C35.7901 37.0453 35.0778 37.7187 34.2391 37.7187Z"
      fill="#FF8F1C"
    />
    <path
      d="M7.84619 44.2521C11.1958 44.2521 15.0658 41.4377 15.2426 38.1056L15.7479 28.5316L16.7179 10.077C16.8038 8.41345 18.2335 7.06152 19.9058 7.06152H41.1251L39.4832 38.2212C39.3063 41.5533 36.4518 44.2521 33.1022 44.2521H7.84619Z"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1724 44.2524H33.1067C36.4563 44.2524 39.3108 41.5535 39.4877 38.2215L41.1296 7.06177"
      stroke="#FF8F1C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21.6128 13.0981H35.759" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.4014 17.1101H35.5476" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21.1948 21.0254H35.341" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>,
  'Approvals'
);

export default ApprovalIcon;
