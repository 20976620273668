/* eslint-disable jsx-a11y/label-has-associated-control */
import settings from '@/constants/constants';
import usePatientDiscountReasonData from '@/hooks/patient/usePatientDiscountReason';
import usePatientDiscountType from '@/hooks/patient/usePatientDiscountType';
import type { DiscountData, PatientDiscountModalProps, SelectOption } from '@/types';
import { datadogRum } from '@datadog/browser-rum';
import { Button, ConfirmDialog } from '@montugroup/design-system';
import { BrandThemeProvider } from '@montugroup/themes';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  styled
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

const orderLimitCount: SelectOption[] = [];
for (let i = 1; i <= 10; i += 1) {
  orderLimitCount.push({
    label: i.toString(),
    value: i.toString()
  });
}

const MarginTopContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

const DiscountAmountContainer = styled(Grid)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(3),
  xs: theme.spacing(4),
  md: theme.spacing(2)
}));

const FormRowContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const FormGroupContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1)
}));

function PatientDiscountModal({ existingDiscount, shopifyCustomerId, close, show }: PatientDiscountModalProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DiscountData>({
    defaultValues: {
      discountType: '',
      discountReasonId: '',
      discountNote: '',
      discountRepeatCount: '1',
      discountAmount: 0,
      startDate: moment().toDate(),
      endDate: moment().add(6, 'months').toDate()
    }
  });

  const [orderLimitation, setOrderLimitation] = useState<boolean>(true);

  const { discountType } = settings;

  const minDate = moment().add(1, 'day').toDate();
  const maxDate = moment().toDate();

  const getDateFromString = (dateString: string | null, isIndefinite: boolean, defaultDate: Date) => {
    if (dateString) {
      return new Date(dateString);
    }
    if (isIndefinite) {
      return defaultDate;
    }
    return null;
  };

  const { data: discountTypesData } = usePatientDiscountType();
  const { data: discountReasonsData } = usePatientDiscountReasonData();

  const discountReasons =
    discountReasonsData && discountReasonsData.map(({ reason, id }) => ({ label: reason, value: id }));

  const discountTypes =
    discountTypesData &&
    discountTypesData
      .filter((type) => type.name === 'Shipping Fee')
      .map((discount) => ({
        label: discount.name,
        value: discount.id.toString()
      }));

  const closeModal = () => {
    reset();
    setOrderLimitation(true);
    close();
  };

  const openNewShopifyDiscount = async (discount: DiscountData) => {
    const {
      shopifyDiscountsURL,
      shopifyOrderDiscountAppURL,
      shopifyProductDiscountAppURL,
      shopifyShippingDiscountAppURL
    } = settings;

    const discountData = {
      customerid: shopifyCustomerId,
      reason: discount.discountReasonId?.toString() || '',
      note: discount.discountNote || '',
      percentage: discount.discountAmount?.toString() || '',
      startdate: discount.startDate ? discount.startDate.valueOf().toString() : '',
      enddate: discount.endDate ? discount.endDate.valueOf().toString() : '',
      usagelimit: discount.discountRepeatCount?.toString() || ''
    };
    const queryParams = new URLSearchParams(discountData).toString();

    const discountTypeAppUrlMap = {
      [discountType.FULL_ORDER]: shopifyOrderDiscountAppURL,
      [discountType.DISPENSING_FEE]: shopifyShippingDiscountAppURL,
      [discountType.MEDICATION_SPECIFIC]: shopifyProductDiscountAppURL
    };
    const appUrl = discountTypeAppUrlMap[Number(discount.discountType)];

    datadogRum.addAction('open-new-shopify-discount', {
      ...discountData,
      appUrl
    });

    window.open(`${shopifyDiscountsURL}/${appUrl}/new?${queryParams}`, '_blank', 'noopener noreferrer');
    closeModal();
  };

  const handleDiscountSubmit = (discount: DiscountData) => openNewShopifyDiscount(discount);

  const onSubmit: SubmitHandler<DiscountData> = (data: DiscountData) => {
    handleDiscountSubmit(data);
  };

  useEffect(() => {
    if (existingDiscount) {
      reset({
        discountId: existingDiscount.id,
        discountType: existingDiscount.discount_type_id.toString(),
        discountReasonId: existingDiscount.discount_reason_id.toString(),
        discountNote: existingDiscount.discount_note,
        discountAmount: existingDiscount.discount_amount || undefined,
        discountRepeatCount: existingDiscount.order_limit?.toString() ?? '',
        endDate: getDateFromString(
          existingDiscount.end_date,
          existingDiscount.is_indefinite,
          moment().add(6, 'months').toDate()
        ),
        startDate: getDateFromString(existingDiscount.start_date, existingDiscount.is_indefinite, moment().toDate())
      });

      if (existingDiscount.order_limit) {
        setOrderLimitation(true);
      }
    }
  }, [existingDiscount, reset]);

  return (
    <BrandThemeProvider variant="uMeds">
      <ConfirmDialog
        open={show}
        onClose={close}
        title="Add Discount"
        data-testid="add-discount"
        actions={
          <>
            <Button variant="outlined" color="inherit" type="submit" onClick={closeModal}>
              Close
            </Button>
            <Button color="inherit" type="submit" onClick={handleSubmit(onSubmit)}>
              Add Discount
            </Button>
          </>
        }
        PaperProps={{ sx: { width: '35%' } }}
        contentProps={{ sx: { pt: 2 } }}
      >
        <Controller
          name="discountType"
          control={control}
          rules={{ required: 'The discount type field is required.' }}
          render={({ field }) => (
            <MarginTopContainer>
              <FormControl fullWidth error={!!errors.discountType} size="small">
                <InputLabel id="items-label">Items</InputLabel>
                <Select
                  {...field}
                  label="Items"
                  labelId="items-label"
                  id="items-select"
                  aria-labelledby="items-label"
                  SelectDisplayProps={{
                    // @ts-expect-error
                    'data-testid': 'items-select'
                  }}
                >
                  {(discountTypes || []).map(({ value, label: labelText }) => (
                    <MenuItem key={value} value={value}>
                      {labelText}
                    </MenuItem>
                  ))}
                </Select>
                {errors.discountType && <FormHelperText>{errors.discountType.message}</FormHelperText>}
              </FormControl>
            </MarginTopContainer>
          )}
        />
        <Controller
          name="discountReasonId"
          control={control}
          rules={{ required: 'The discount reason field is required.' }}
          render={({ field }) => (
            <MarginTopContainer>
              <FormControl fullWidth error={!!errors.discountReasonId} size="small">
                <InputLabel id="reason-label">Reason</InputLabel>
                <Select
                  {...field}
                  label="Reason"
                  labelId="Reason"
                  id="reason-select"
                  aria-labelledby="reason-label"
                  SelectDisplayProps={{
                    // @ts-expect-error
                    'data-testid': 'reason-select'
                  }}
                >
                  {(discountReasons ?? []).map(({ value, label: labelText }) => (
                    <MenuItem key={value} value={value}>
                      {labelText}
                    </MenuItem>
                  ))}
                </Select>
                {errors.discountReasonId && <FormHelperText>{errors.discountReasonId.message}</FormHelperText>}
              </FormControl>
            </MarginTopContainer>
          )}
        />
        <Controller
          name="discountNote"
          control={control}
          rules={{ required: 'The discount notes field is required.' }}
          render={({ field }) => (
            <MarginTopContainer>
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                label="Discount Notes"
                inputProps={{ maxLength: 100 }}
                error={!!errors.discountNote}
                helperText={errors.discountNote?.message}
                size="small"
              />
            </MarginTopContainer>
          )}
        />
        <Grid container>
          <DiscountAmountContainer item>
            <label htmlFor="discountAmount">Discount</label>
            <Controller
              name="discountAmount"
              control={control}
              rules={{
                required: 'The discount percentage field is required.',
                pattern: {
                  value: /^\d*$/,
                  message: 'Please enter a valid number'
                },
                validate: (value) => {
                  if (value === undefined) {
                    return true;
                  }
                  if (value.toString() === '0') {
                    return 'Discount cannot be 0%.';
                  }
                  return value <= 100 || 'Discount Percentage is higher than maximum limit.';
                }
              }}
              render={({ field }) => (
                <MarginTopContainer>
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    placeholder="100%"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    error={!!errors.discountAmount}
                    helperText={errors.discountAmount?.message}
                    size="small"
                  />
                </MarginTopContainer>
              )}
            />
          </DiscountAmountContainer>
        </Grid>
        <FormRowContainer container>
          <FormGroupContainer item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch checked={orderLimitation} size="small" onChange={(e) => setOrderLimitation(e.target.checked)} />
              }
              label="Order Limitation"
            />
          </FormGroupContainer>
          <FormGroupContainer item xs={12} sm={6}>
            {orderLimitation && (
              <Controller
                name="discountRepeatCount"
                control={control}
                rules={{ required: 'The order limitation field is required.' }}
                render={({ field }) => (
                  <MarginTopContainer>
                    <FormControl fullWidth size="small">
                      <InputLabel>Order limitation</InputLabel>
                      <Select {...field} label="Order limitation">
                        {orderLimitCount.map(({ value, label: labelText }) => (
                          <MenuItem key={value} value={value}>
                            {labelText}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.discountRepeatCount?.message}
                    </FormControl>
                  </MarginTopContainer>
                )}
              />
            )}
          </FormGroupContainer>
        </FormRowContainer>
        <MarginTopContainer>
          <label>Duration</label>
          <FormRowContainer container>
            <FormGroupContainer item xs={12} sm={6}>
              <Controller
                name="startDate"
                control={control}
                rules={{ required: 'The start date field is required.' }}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Discount Start Date"
                    disablePast
                    minDate={maxDate}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message
                      }
                    }}
                  />
                )}
              />
            </FormGroupContainer>
            <FormGroupContainer item xs={12} sm={6}>
              <Controller
                name="endDate"
                control={control}
                rules={{ required: 'The end date field is required.' }}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label="Discount End Date"
                    disablePast
                    minDate={minDate}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message
                      }
                    }}
                  />
                )}
              />
            </FormGroupContainer>
          </FormRowContainer>
        </MarginTopContainer>
      </ConfirmDialog>
    </BrandThemeProvider>
  );
}

export default PatientDiscountModal;
