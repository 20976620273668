import type { HelpdeskSettingsDto } from '@montugroup/circuit-api-contracts';
import { DateTime } from 'luxon';

export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
export const TIME_FORMAT = 'HH:mm:ss';
const TZ = 'Australia/Melbourne';

export interface HelpdeskSchedule {
  active: HelpdeskScheduleWindow | null;
  next: HelpdeskScheduleWindow | null;
}

export interface HelpdeskScheduleWindow {
  start: DateTime;
  end: DateTime;
  externalId: string;
  name: string;
}

type Day = (typeof DAYS)[number];

export function getSchedule(helpdesks: HelpdeskSettingsDto[]): HelpdeskSchedule {
  const activeHelpdesks = helpdesks.filter(({ active }) => active);
  return {
    active: getActiveSchedule(activeHelpdesks),
    next: getNextSchedule(activeHelpdesks)
  };
}

export function getMillisUntilNextSchedule(helpdesks: HelpdeskSettingsDto[]): number {
  const now = DateTime.now().setZone(TZ);
  const { active, next } = getSchedule(helpdesks);

  if (active) {
    return active.end.diff(now).toMillis();
  }

  if (next) {
    return next.start.diff(now).toMillis();
  }

  // Never...
  return Infinity;
}

function getActiveSchedule(helpdesks: HelpdeskSettingsDto[]): HelpdeskScheduleWindow | null {
  const now = DateTime.now().setZone(TZ);
  const dayOfWeek = getCurrentWeekday(now);

  for (const helpdesk of helpdesks) {
    let enableDateTime: DateTime | null = null;
    let disableDateTime: DateTime | null = null;

    const enableTime = helpdesk[`${dayOfWeek}_enable_time`];
    const disableTime = helpdesk[`${dayOfWeek}_disable_time`];

    if (enableTime) {
      enableDateTime = buildDateTimeFromTime(enableTime, now);
    }

    if (disableTime) {
      disableDateTime = buildDateTimeFromTime(disableTime, now);
    }

    // Look forward if it's enabled today, but not disabled
    if (enableDateTime && !disableDateTime) {
      disableDateTime = findNextDisableTime(helpdesk, dayOfWeek, now);
    }

    // Look backward if it's disabled today, but not enabled
    if (!enableDateTime && disableDateTime) {
      enableDateTime = findPreviousEnableTime(helpdesk, dayOfWeek, now);
    }

    // If we disable before we enable within the same day, check if we're in the "outer" boundaries of the day
    if (enableDateTime && disableDateTime && disableDateTime <= enableDateTime) {
      if (isNowWithinSchedule(now, buildDateTimeFromTime('00:00:00', now), disableDateTime)) {
        enableDateTime = findPreviousEnableTime(helpdesk, dayOfWeek, now);
      } else if (isNowWithinSchedule(now, enableDateTime, buildDateTimeFromTime('23:59:59', now))) {
        disableDateTime = findNextDisableTime(helpdesk, getNextDay(dayOfWeek, 1), now);
      }
    }

    // Did we find the enable/disable boundaries, and is it within the schedule window?
    if (enableDateTime && disableDateTime && isNowWithinSchedule(now, enableDateTime, disableDateTime)) {
      return {
        start: enableDateTime,
        end: disableDateTime,
        externalId: helpdesk.external_id,
        name: helpdesk.name
      };
    }
  }

  return null;
}

function getNextSchedule(helpdesks: HelpdeskSettingsDto[]): HelpdeskScheduleWindow | null {
  const now = DateTime.now().setZone(TZ);
  let nextSchedule: HelpdeskScheduleWindow | null = null;

  for (const helpdesk of helpdesks) {
    for (const day of DAYS) {
      let enableDateTime: DateTime | null = null;
      let disableDateTime: DateTime | null = null;

      const enableTime = helpdesk[`${day}_enable_time`];
      const disableTime = helpdesk[`${day}_disable_time`];

      if (enableTime) {
        const dateTimeForIteration = buildDateTimeForDayIteration(day, now);
        enableDateTime = buildDateTimeFromTime(enableTime, dateTimeForIteration);

        if (disableTime) {
          disableDateTime = buildDateTimeFromTime(disableTime, dateTimeForIteration);

          // If disable time is earlier than the set enable time...
          // (to end a prior days schedule), find the next disable time instead
          if (disableDateTime <= enableDateTime) {
            disableDateTime = findNextDisableTime(helpdesk, getNextDay(day, 1), dateTimeForIteration);
          }
        } else {
          // If there's no disable time in the same day, find it! :)
          disableDateTime = findNextDisableTime(helpdesk, day, dateTimeForIteration);
        }
      }

      // If we have the start and end of a schedule, and it gets enabled after the time of checking...
      if (enableDateTime && disableDateTime && enableDateTime > now) {
        // Set the next schedule if none already exists, or if it's earlier than the previous one
        if (!nextSchedule || enableDateTime < nextSchedule.start) {
          nextSchedule = {
            start: enableDateTime,
            end: disableDateTime,
            externalId: helpdesk.external_id,
            name: helpdesk.name
          };
        }
      }
    }
  }

  return nextSchedule;
}

function buildDateTimeFromTime(time: string, current: DateTime): DateTime {
  return DateTime.fromFormat(time, TIME_FORMAT, { zone: TZ }).set({
    year: current.year,
    month: current.month,
    day: current.day
  });
}

function buildDateTimeForDayIteration(day: Day, now: DateTime): DateTime {
  const targetDayIndex = DAYS.indexOf(day);
  const currentDayIndex = DAYS.indexOf(getCurrentWeekday(now));
  const daysUntilNextDay = (targetDayIndex - currentDayIndex + DAYS.length) % DAYS.length;

  return now.plus({ days: daysUntilNextDay });
}

function findNextDisableTime(helpdesk: HelpdeskSettingsDto, dayOfWeek: Day, now: DateTime): DateTime | null {
  for (let i = 0; i < DAYS.length; i++) {
    const nextDay = getNextDay(dayOfWeek, i);
    const nextDisableTime = helpdesk[`${nextDay}_disable_time`];

    if (nextDisableTime) {
      const nextDate = now.plus({ days: i }).set({ hour: 0, minute: 0, second: 0 });
      return buildDateTimeFromTime(nextDisableTime, nextDate);
    }
  }

  return null;
}

function findPreviousEnableTime(helpdesk: HelpdeskSettingsDto, dayOfWeek: Day, now: DateTime): DateTime | null {
  for (let i = 1; i <= DAYS.length; i++) {
    const previousDayIndex = (DAYS.indexOf(dayOfWeek) - i + DAYS.length) % DAYS.length;
    const previousDay = DAYS[previousDayIndex];
    const previousEnableTime = helpdesk[`${previousDay}_enable_time`];

    if (previousEnableTime) {
      const previousDate = now.minus({ days: i }).set({ hour: 0, minute: 0, second: 0 });
      return buildDateTimeFromTime(previousEnableTime, previousDate);
    }
  }

  return null;
}

function getCurrentWeekday(current: DateTime) {
  return current.toFormat('EEEE').toLowerCase() as Day;
}

function getNextDay(day: Day, daysToAdvance: number) {
  return DAYS[(DAYS.indexOf(day) + daysToAdvance) % DAYS.length];
}

function isNowWithinSchedule(now: DateTime, enableDateTime: DateTime, disableDateTime: DateTime): boolean {
  return now >= enableDateTime && now <= disableDateTime;
}
