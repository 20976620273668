import { Box, CircularProgress, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';

import { ErrorAlert, InfoAlert, LoadingIconContainer } from '@/components/common/RebrandStyledComponents';
import type { Prescription } from '@/components/patient/prescription/common';
import PrescriptionContainer from '@/components/patient/prescription/PrescriptionContainer';
import useGetPatientPrescriptions from '@/hooks/rest/useGetPatientPrescriptions';
import BackButton from '@/ui-library/backButton/BackButton';
import Tab from '@/ui-library/tab/Tab';
import TabPanel from '@/ui-library/tabPanel/TabPanel';
import { transformPrescriptions } from '@/utils/patient/transformPrescriptions';

export interface ScriptMetadata {
  createdDate: string;
  expiryDate: string;
  status: string;
  inActive: boolean;
  daysUntilExpiry: number;
}
export interface IPrescriptions {
  prescriptionCode: string;
  prescriptionMetadata: ScriptMetadata;
  prescriptions: Prescription[];
}

export default function PatientPrescription() {
  const [value, setValue] = useState(0);

  const { data: prescriptionsData, isLoading, isError, isSuccess } = useGetPatientPrescriptions();
  const { activePrescriptions, inactivePrescriptions } = transformPrescriptions(prescriptionsData);

  const renderContent = (prescriptions: IPrescriptions[], infoText: string) => {
    if (isError) {
      return (
        <ErrorAlert variant="standard" severity="error">
          An unexpected error occurred. Please try again.
        </ErrorAlert>
      );
    }

    if (isSuccess && prescriptions.length) {
      return prescriptions.map((prescription) => (
        <PrescriptionContainer
          key={prescription.prescriptionCode}
          prescriptionCode={prescription.prescriptionCode}
          prescriptionMetadata={prescription.prescriptionMetadata}
          prescription={prescription.prescriptions}
        />
      ));
    }

    return <InfoAlert severity="info">{infoText}</InfoAlert>;
  };

  const prescriptionTabs = [
    {
      label: 'Active',
      children: renderContent(activePrescriptions, 'No active prescriptions')
    },
    {
      label: 'Inactive',
      children: renderContent(inactivePrescriptions, 'No inactive prescriptions')
    }
  ];

  return (
    <>
      <Box display="flex">
        <BackButton />
        <Typography variant="h5" component="h1" color="primary.main" lineHeight={(theme) => theme.spacing(10)}>
          Prescriptions
        </Typography>
      </Box>

      <Tabs
        value={value}
        onChange={(_event: React.SyntheticEvent, newValue: number) => setValue(newValue)}
        aria-label="Patient prescription tabs"
        sx={{
          marginTop: (theme) => theme.spacing(5)
        }}
      >
        {prescriptionTabs.map((tab, index) => (
          <Tab
            key={tab.label}
            id={`tab-${tab.label}`}
            label={tab.label}
            aria-controls={`tabpanel-${index}`}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                width: '50%'
              }
            })}
          />
        ))}
      </Tabs>

      {isLoading ? (
        <LoadingIconContainer>
          <CircularProgress />
        </LoadingIconContainer>
      ) : (
        prescriptionTabs.map((tab, index) => (
          <TabPanel key={tab.label} id={`tabpanel-${index}`} index={index} value={value} label={tab.label}>
            {tab.children}
          </TabPanel>
        ))
      )}
    </>
  );
}
