import { createSvgIcon } from '@mui/material';

const ScriptsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 52" fill="none">
    <path
      d="M32.3051 34.9124H22.7885C22.1969 34.9124 21.7634 34.433 21.8246 33.8363L22.2734 29.537C22.3346 28.9454 22.865 28.4609 23.4617 28.4609H32.9783C33.5699 28.4609 34.0034 28.9403 33.9422 29.537L33.4934 33.8363C33.4322 34.4279 32.9018 34.9124 32.3051 34.9124Z"
      fill="#FF9845"
    />
    <path
      d="M13.3384 39.5739C15.715 39.5739 18.5659 37.5645 18.8158 35.193L19.5298 28.3641L20.9068 15.2062C21.0292 14.0179 22.0951 13.054 23.2834 13.054H38.3335L36.0028 35.2695C35.7529 37.6461 33.6262 39.5688 31.2496 39.5688H13.3384V39.5739Z"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6616 39.5739H31.2543C33.6309 39.5739 35.7576 37.6461 36.0075 35.2746L38.3382 13.054"
      stroke="#FF9845"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24.2676 17.3538H34.2993" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.9668 20.218H34.0036" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M23.6758 23.0132H33.7075" stroke="#042B37" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.8071 4.72832C27.7019 2.21913 36.4688 4.04492 42.3134 10.2108C50.6162 18.9726 49.8716 33.1812 40.6508 41.9481"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.5987 47.6714C22.9232 49.8083 14.5541 47.9009 8.9135 41.9441C0.605609 33.1823 1.35021 18.9737 10.5761 10.2119"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7409 42.9916L39.4212 43.5169L39.1152 39.2227"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0415 8.61353L12.1929 9.62842L10.9536 13.8359"
      stroke="#042B37"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Scripts'
);

export default ScriptsIcon;
