import AdminPageLayout from '@/components/layout/AdminPageLayout';
import ProductListing from '../../components/products/productListing';

const Products = (props) => {
  return (
    <AdminPageLayout title="Products">
      <ProductListing {...props} />
    </AdminPageLayout>
  );
};

export default Products;
