import type { Consultation } from '@/hooks/rest/useGetPatientConsultations';
import { outOfBandThemeColors } from '@/theme/colors';
import type { ConsultationResponseDto } from '@montugroup/pms-api-contracts';
import {
  AccessTime as AccessTimeIcon,
  DoDisturbAlt as DoDisturbAltIcon,
  EventBusy as EventBusyIcon,
  FiberManualRecord as FiberManualRecordIcon,
  HowToReg as HowToRegIcon,
  PendingActions as PendingActionsIcon,
  PendingOutlined as PendingOutlinedIcon,
  PersonOff as PersonOffIcon,
  Restore as RestoreIcon
} from '@mui/icons-material';
import { Chip, Stack, styled } from '@mui/material';
import { DateTime } from 'luxon';

const { skyBlue, pastelYellow, deepPink, grassGreen, faintPurple, deepRed, lightBlue, lightPurple, darkTeal } =
  outOfBandThemeColors.alternaleaf;

const PAYMENT_STATUS = {
  Paid: 'PAID',
  Invoiced: 'INVOICED',
  Voided: 'VOIDED',
  AwaitingPayment: 'AWAITING PAYMENT'
};
export const CONSULTATION_STATUS = {
  Ineligible: 'Ineligible',
  Pending: 'Pending',
  AwaitingDocuments: 'Awaiting Documents',
  Scheduled: 'Scheduled',
  Completed: 'Completed',
  Rescheduled: 'Re-Scheduled',
  NoShow: 'No-Show',
  Cancelled: 'Cancelled'
};

const CONSULTATIONS_TYPE = {
  FollowUp: 'Follow-up',
  Initial: 'Initial'
};

const PractionerChip = styled(Chip)(({ theme }) => ({
  fontFamily: theme.typography.subtitle1.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.fontSize
}));

export function getDate(startTime: string, endTime: string, useRebrandFormat = false) {
  const dateFormat = useRebrandFormat ? 'dd - MM - yyyy' : 'dd/MM/yyyy';
  const startTimeFormat = useRebrandFormat ? 'h:mm' : 'h:mm a';
  const endTimeFormat = 'h:mm a';

  const date = DateTime.fromISO(startTime).toFormat(dateFormat);
  const formattedStartTime = DateTime.fromISO(startTime).toFormat(startTimeFormat);
  const formattedEndTime = DateTime.fromISO(endTime).toFormat(endTimeFormat);

  return {
    date,
    time: `${formattedStartTime} - ${formattedEndTime}`
  };
}

const oldColors = {
  Paid: { backgroundColor: skyBlue, color: 'inherit' },
  Invoiced: { backgroundColor: pastelYellow, color: 'inherit' },
  Voided: { backgroundColor: deepPink, color: 'inherit' },
  AwaitingPayment: { backgroundColor: pastelYellow, color: 'inherit' }
};

// TODO: Change these colors to use the theme and new design system colors once the design system is updated -
const rebrandColors = {
  Paid: { backgroundColor: grassGreen, color: 'white' },
  Invoiced: { backgroundColor: faintPurple, color: 'inherit' },
  Voided: { backgroundColor: deepPink, color: 'inherit' },
  AwaitingPayment: { backgroundColor: pastelYellow, color: 'inherit' }
};

export function getPaymentStatus(status: string | null, useRebrandColors = false) {
  const colors = useRebrandColors ? rebrandColors : oldColors;
  let colorScheme;

  switch (status?.toUpperCase()) {
    case PAYMENT_STATUS.Paid:
      colorScheme = colors.Paid;
      break;
    case PAYMENT_STATUS.Invoiced:
      colorScheme = colors.Invoiced;
      break;
    case PAYMENT_STATUS.Voided:
      colorScheme = colors.Voided;
      break;
    case PAYMENT_STATUS.AwaitingPayment:
      colorScheme = colors.AwaitingPayment;
      break;
    case null:
    case undefined:
      colorScheme = { backgroundColor: 'grey.300', color: 'inherit' };
      break;
    default:
      return status;
  }

  return (
    <Chip
      label={status?.toLowerCase() || 'Unknown'}
      sx={{
        backgroundColor: colorScheme.backgroundColor,
        color: colorScheme.color,
        fontFamily: (theme) => theme.typography.body1.fontFamily,
        fontSize: (theme) => theme.typography.fontSize,
        fontWeight: (theme) => theme.typography.fontWeightMedium,
        textTransform: 'capitalize'
      }}
    />
  );
}

export function getName(consultation: Consultation | ConsultationResponseDto) {
  if ('practitioner' in consultation) {
    const { practitioner } = consultation as ConsultationResponseDto;
    if (!practitioner) {
      return '';
    }

    return practitioner.name;
  }
  if (consultation.Doctor) {
    return `Dr ${consultation.Doctor.DoctorUser.first_name} ${consultation.Doctor.DoctorUser.last_name}`;
  }
  if (consultation.Nurse) {
    return `${consultation.Nurse.NurseUser.first_name} ${consultation.Nurse.NurseUser.last_name}`;
  }
  return '';
}

export function getConsultationStatus(status: string) {
  switch (status) {
    case CONSULTATION_STATUS.Ineligible:
      return <PersonOffIcon style={{ color: deepRed }} />;
    case CONSULTATION_STATUS.Pending:
      return <PendingOutlinedIcon style={{ color: deepRed }} />;
    case CONSULTATION_STATUS.AwaitingDocuments:
      return <PendingActionsIcon style={{ color: deepRed }} />;
    case CONSULTATION_STATUS.Scheduled:
      return <AccessTimeIcon style={{ color: lightBlue }} />;
    case CONSULTATION_STATUS.Completed:
      return <HowToRegIcon style={{ color: grassGreen }} />;
    case CONSULTATION_STATUS.NoShow:
      return <EventBusyIcon style={{ color: deepRed }} />;
    case CONSULTATION_STATUS.Rescheduled:
      return <RestoreIcon style={{ color: lightBlue }} />;
    case CONSULTATION_STATUS.Cancelled:
      return <DoDisturbAltIcon style={{ color: deepRed }} />;
    default:
      return <>-</>;
  }
}

export function getConsultationType(type: string) {
  switch (type) {
    case CONSULTATIONS_TYPE.FollowUp:
      return (
        <Chip
          label={
            <Stack direction="row" spacing={2} alignItems="center">
              <FiberManualRecordIcon sx={{ fontSize: '15px' }} />
              <div>Follow up</div>
            </Stack>
          }
          sx={{ color: lightPurple, borderColor: lightPurple }}
          variant="outlined"
        />
      );
    case CONSULTATIONS_TYPE.Initial:
      return (
        <Chip
          label={
            <Stack direction="row" spacing={2} alignItems="center">
              <FiberManualRecordIcon sx={{ fontSize: '15px' }} />
              <div>Initial</div>
            </Stack>
          }
          color="primary"
          variant="outlined"
        />
      );
    default:
      return <Chip label={type} color="primary" variant="outlined" />;
  }
}

export const getPractionerType = (consultation: ConsultationResponseDto) => {
  if (!consultation.practitioner) {
    return null;
  }

  return consultation.practitioner.type === 'Doctor' ? (
    <PractionerChip
      label="Doctor consultation"
      sx={{
        backgroundColor: skyBlue
      }}
    />
  ) : (
    <PractionerChip
      label="Nurse consultation"
      sx={{
        backgroundColor: darkTeal,
        color: (theme) => theme.palette.common.white
      }}
    />
  );
};
