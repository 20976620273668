import settings from '@/constants/constants';
import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import { AuthService } from '@/services/authentication.service';
import { UserService } from '@/services/user.service';
import withFlags from '@/utils/withFlags';
import withRouter from '@/utils/withRouter';
import { toast } from '@montugroup/design-system';
import { Button as MUIButton } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import FilterableTable from 'rc-data-table';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import editIcon from '../../assets/images/edit.png';
import Button from '../common/button';
import CircleLoader from '../common/circleLoader';
import DateRangeComp from '../daterange/DateRangeComp';
import MergePatientModal from './mergePatientModal/mergePatientModal';

const CancelToken = axios.CancelToken;
let cancel;

// Wrapper to implement feature flag hook in legacy class component
const MergePatientButton = ({ handleOnMergePatientsClick }) => {
  return (
    <MUIButton
      size="small"
      onClick={handleOnMergePatientsClick}
      sx={{
        marginRight: '0.5rem'
      }}
    >
      Merge Patients
    </MUIButton>
  );
};

class ViewPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      user_role_id: AuthService.getUser().user.role_id,
      pageSize: 100,
      count: 0,
      page: 0,
      filter: null,
      sort: { name: 'id', reverse: true },
      loading: true,
      open: false,
      openMergePatientModal: false
    };
  }

  /**
   * Fetches the patients from the backend for display.
   *
   * @param {number} page the page to fetch
   * @param {number} pageSize how many items to fetch
   * @param {object} sort contains keys 'reverse' and 'name' for sorting.
   * @param {string} filter the search term to query data with.
   *
   * @returns Promise running search handle.
   */
  searchPatients = (page, pageSize, sort, filter) => {
    return axios.get(`${settings.url}/data/patient`, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
      params: {
        limit: pageSize,
        offset: page * pageSize,
        sort: sort.name,
        sortOrder: sort.reverse ? 'DESC' : 'ASC',
        filter,
        ts: new Date().getTime()
      }
    });
  };

  debouncedPatientSearch = AwesomeDebouncePromise(this.searchPatients, 300);

  async componentDidMount() {
    document.title = 'Patients - Montu Group | Making Medical Cannabis Accessible';
    await this.dataBind();
  }

  /**
   * Stops all promises/state changes as component is unmounting.
   * @returns {Promise<void>}
   */
  async componentWillUnmount() {
    this.setState = () => {};
  }

  dataBind = async () => {
    const { page, pageSize, sort, filter } = this.state;
    if (cancel) {
      cancel();
    }
    this.debouncedPatientSearch(page, pageSize, sort, filter).then((data) => {
      this.setState({ data: data.data.patients, count: data.data.count, loading: false });
    });
  };

  pageChange = async (page, size) => {
    this.setState({ page: page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
  };

  onFilter = (rawFilter) => {
    const filter = rawFilter?.trim().replace(/\s{2,}/g, ' ') ?? '';
    if (this.state.filter !== filter) {
      this.setState({ filter, page: 0 }, function () {
        this.dataBind();
      });
    }
  };

  closeCalendar = () => {
    this.setState({
      open: false
    });
  };

  downloadFile = async (from_date, to_date) => {
    const fileTitle = new Date().getTime() + '-montu-patients';
    await axios({
      url: 'excel/patient/download',
      data: {
        from_date: from_date,
        to_date: to_date
      },
      method: 'POST',
      responseType: 'blob'
    }).then((response) => {
      if (response.headers['content-length'] == 0) {
        toast.error('No data is found for this specified date range');
        return;
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  handleClick = () => {
    this.setState({
      open: true
    });
  };

  resendInvite = async (email) => {
    const response = await UserService.resendLoginInvite({ email });
    if (response.data.status === 200) {
      toast.success('Invite sent.');
    } else {
      toast.error('Failed to send invite.');
    }
  };

  handleOnMergePatientsClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      openMergePatientModal: !prevState.openMergePatientModal
    }));
  };

  render() {
    const { data, page, pageSize, sort, count, loading, filter } = this.state;
    const fields = [
      { name: 'id', displayName: 'id', inputFilterable: true, sortable: true, visible: false },
      {
        name: 'patient_code',
        displayName: 'Patient ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        render: (e) => {
          return (
            <Link to={`/${this.props.flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${e.record.id}`}>
              {e.record.patient_code}
            </Link>
          );
        }
      },
      {
        name: 'patient_name',
        displayName: 'Name',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.first_name'
      },
      {
        name: 'email',
        displayName: 'Email',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.email'
      },
      {
        name: 'phone',
        displayName: 'Phone',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.phone'
      },
      {
        name: 'state',
        displayName: 'State',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.State.name'
      },
      { name: 'gpname', displayName: 'Doctor Name', inputFilterable: true, tdClassName: 'sub-text' },
      { name: 'approval', displayName: 'Approval', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'total_prescriptions',
        displayName: 'Total Pres.',
        inputFilterable: true,
        tdClassName: 'sub-text',
        thClassName: 'text-wrap'
      },
      { name: 'circuit_access', displayName: 'Patient Access', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'id',
        displayName: '',
        tdClassName: 'text-center',
        render: (e) => {
          return e.record.circuit_access !== 'Non-PMS' && e.record.circuit_access !== 'Not active' ? (
            <Button
              text="Resend invite"
              type="button"
              style={{ whiteSpace: 'nowrap' }}
              className="btn btn-md btn-info align-center"
              disabled={e.record.circuit_access === 'Active'}
              onClick={() => this.resendInvite(e.record.email)}
            />
          ) : (
            '-'
          );
        }
      },
      {
        name: 'id',
        displayName: 'Edit',
        thClassName: AuthService.getUser().user.role_id !== USER_ROLES.ml ? 'text-center ' : 'd-none',
        tdClassName: AuthService.getUser().user.role_id !== USER_ROLES.ml ? 'sub-text ' : 'd-none',
        render: (e) => {
          return (
            <Link to={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${e.record.id}`}>
              <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
            </Link>
          );
        }
      }
    ];

    return (
      <div className="container-fluid">
        <h3>Patients</h3>
        <div className="d-flex justify-content-end">
          <div>
            {AuthService.isSuperAdmin() && (
              <>
                <Button text="Export All" className="btn btn-md btn-primary float-right" onClick={this.handleClick} />

                <MergePatientButton handleOnMergePatientsClick={this.handleOnMergePatientsClick} />

                {this.state.openMergePatientModal && (
                  <MergePatientModal
                    onClick={this.handleOnMergePatientsClick}
                    open={this.state.openMergePatientModal}
                  />
                )}
              </>
            )}
            {this.state.open && (
              <div className="Patient-Export">
                <DateRangeComp open={true} closeCalendar={this.closeCalendar} onChange={this.downloadFile} />
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <CircleLoader />
        ) : (
          <FilterableTable
            className="table-responsive"
            topPagerVisible={false}
            data={data}
            fields={fields}
            paginationOnServer={true}
            initialSort={sort.name}
            initialSortDir={!sort.reverse}
            onPageChanged={this.pageChange}
            filterData={this.onFilter}
            serverSort={this.getSortColumn}
            totalRecords={count}
            currentPage={page}
            pageSize={pageSize}
            filterPlaceholder={filter ? filter : 'Search'}
            pageSizes={false}
            maintainPageOnSetData={true}
            noRecordsMessage="There are no record to display"
            noFilteredRecordsMessage="No record match your filters!"
          />
        )}
      </div>
    );
  }
}

export default withFlags(withRouter(ViewPatient));
