import {
  OnboardingContainer,
  OnboardingFooterContainer,
  SocialIcons,
  StyledFaq,
  StyledLink,
  StyledNav,
  StyledStack
} from './Onboarding.styles';

import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import useOnboardingBrand from '../../hooks/onboarding-brand/useOnboardingBrand';

function OnboardingFooter() {
  const { Logo } = useOnboardingBrand();

  return (
    <OnboardingFooterContainer component="footer">
      <OnboardingContainer>
        <Box width="100%">
          <StyledStack direction="row">
            <Logo
              fillColor={(theme) => theme.palette.secondary.main}
              sx={{
                width: {
                  xs: '146px',
                  md: '246px'
                }
              }}
            />

            <StyledFaq>
              <Button color="inherit" variant="contained">
                FAQ
              </Button>
            </StyledFaq>
          </StyledStack>

          <StyledNav component="nav" aria-label="Footer Navigation">
            <Typography variant="body2" color="white">
              &copy; {new Date().getFullYear()} uMeds, Inc. All rights reserved.
            </Typography>

            <nav aria-label="Footer Navigation">
              <Stack direction="row">
                <StyledLink to="#">
                  <Typography variant="body2" color="white">
                    Privacy Policy
                  </Typography>
                </StyledLink>
                <StyledLink to="#">
                  <Typography variant="body2" color="white">
                    Contact us
                  </Typography>
                </StyledLink>
              </Stack>
            </nav>

            {/* todo: temporary icons, once confirmed should be lifted to icon package */}
            <SocialIcons>
              <Facebook />
              <LinkedIn />
              <Instagram />
            </SocialIcons>
          </StyledNav>
        </Box>
      </OnboardingContainer>
    </OnboardingFooterContainer>
  );
}

export default OnboardingFooter;
