import { Button, styled } from '@mui/material';

import useOnboardingBrand from '../../hooks/onboarding-brand/useOnboardingBrand';
import { OnboardingContainer, OnboardingHeaderContainer } from './Onboarding.styles';

/* !important required as the theme specificity is too high to work around, and the button by default does not change size on breakpoint. Confirming this with design  */
export const StyledButton = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(5.5)} !important`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)} !important`
  }
}));

function OnboardingHeader() {
  const { Logo } = useOnboardingBrand();

  return (
    <OnboardingHeaderContainer>
      <OnboardingContainer>
        <Logo fillColor={(theme) => theme.palette.primary.main} sx={{ width: { xs: 'auto', md: '148px' } }} />
        <StyledButton variant="contained" color="secondary">
          {/* todo: once login portal page is confirmed, add router link */}
          Login
        </StyledButton>
      </OnboardingContainer>
    </OnboardingHeaderContainer>
  );
}

export default OnboardingHeader;
