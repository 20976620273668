import { Box, CircularProgress, styled, Typography } from '@mui/material';

export interface TokenInputProps {
  setOpenTokenDialog: (state: boolean) => void;
  openTokenDialog: boolean;
}

export const StyledLoadingContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export function TokenLoading() {
  return (
    <StyledLoadingContainer>
      <Typography variant="subtitle1" fontWeight={600} marginBottom={5}>
        Processing your script...
      </Typography>
      <CircularProgress color="secondary" thickness={4.5} size={42} />
    </StyledLoadingContainer>
  );
}
