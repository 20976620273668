import type { CreditLineItem } from '@/hooks/patient/refill/types';

export function formatPrice(price: number) {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
}

export type CheckoutProduct = {
  id: number;
  data: {
    product_id: number;
    product_name: string;
    price: number;
    quantity: number;
    supplier: string;
    is_concession: boolean;
    discount?: {
      id: string;
      display: string;
      amount: number | string;
      formattedAmount: string;
    };
  };
};

export function getCheckoutData(selectedProducts: CheckoutProduct[], creditsDiscounts: CreditLineItem[]) {
  const discounts = creditsDiscounts
    .filter((creditOrDiscount) => creditOrDiscount.isDiscount)
    .map((discount) => {
      const discountAmount = Math.abs(parseFloat(discount.amount) || 0);

      return {
        id: discount.id,
        amount: discountAmount,
        display: discount.displayName, //this change is only temporary until UMED-1226 is ready
        formattedAmount: discountAmount < 0 ? formatPrice(discountAmount) : `-${formatPrice(discountAmount)}`,
        productId: discount.productId
      };
    });

  const products = selectedProducts
    .filter((p) => p.data.quantity >= 1)
    .map((selectedProduct) => {
      const productDiscount = discounts.find((discount) => discount.productId === selectedProduct.data.product_id);

      return {
        id: selectedProduct.data.product_id,
        name: selectedProduct.data.product_name,
        price: selectedProduct.data.price,
        quantity: selectedProduct.data.quantity,
        supplier: selectedProduct.data.supplier,
        totalPrice: Number(selectedProduct.data.price) * selectedProduct.data.quantity,
        formattedTotalPrice: formatPrice(Number(selectedProduct.data.price) * selectedProduct.data.quantity),
        isConcession: selectedProduct.data.is_concession,
        discount: productDiscount
          ? {
              id: productDiscount.id,
              display: productDiscount.display,
              amount: productDiscount.amount,
              formattedAmount: productDiscount.formattedAmount
            }
          : undefined
      };
    });

  const totalNumberOfProducts = products.reduce((total, { quantity }) => total + quantity, 0);

  const productPrice = products.reduce((total, { price, quantity }) => total + price * quantity, 0);
  const discountPrice = discounts.reduce((total, { amount }) => total + amount, 0);
  const subTotalPrice = productPrice;
  const formattedSubTotalPrice = formatPrice(subTotalPrice);
  const totalPrice = productPrice - discountPrice;
  const formattedTotalPrice = formatPrice(totalPrice);

  return {
    products,
    totalNumberOfProducts,
    totalPrice,
    subTotalPrice,
    discounts,
    formattedTotalPrice,
    formattedSubTotalPrice
  };
}

export default getCheckoutData;
