import { PaginationVariant, Table } from '@montugroup/design-system';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { Column, Row } from '@tanstack/react-table';
import debounce from 'awesome-debounce-promise';
import { useEffect, useMemo, useState } from 'react';

import TableSearchInput from '@/components/common/TableSearchInput';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import { getPharmacistDetails } from '@/services/pharmacist.service';
import type { PharmacyProductInventory } from '@/types/pharmacy.types';

import useGetInventoryProducts from '../../../../hooks/pharmacist/useGetInventoryProducts';

import InventoryAdjustment from './InventoryAdjustment';

function ProductOverview() {
  const [search, setSearch] = useState('');
  const [showAdjustmentModal, setShowAdjustmentModal] = useState<boolean>(false);
  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel({ page: 0, pageSize: 25 });
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder();
  const [pharmacyId, setPharmacyId] = useState<null | number>(0);

  useEffect(() => {
    const fetchPharmacistDetails = async () => {
      const { data: pharmacistDetails } = await getPharmacistDetails();
      setPharmacyId(pharmacistDetails.pharmacy_id);
    };
    fetchPharmacistDetails();
  }, []);

  const { products, isLoading, refetch } = useGetInventoryProducts(pharmacyId);

  const handleSearch = debounce((value: string) => {
    setSearch(value);
    handlePaginationModelChange({ pageSize, page: 0 });
  }, 250);

  const { filteredProducts, total } = useMemo(() => {
    if (!products) {
      return { filteredProducts: [], total: 0 };
    }

    const lowercasedSearch = search.toLowerCase();
    const searchedProducts = products.filter((product: PharmacyProductInventory) =>
      product.name.toLowerCase().includes(lowercasedSearch)
    );

    const sortedProducts = [...searchedProducts].sort((a, b) => {
      const aValue = a[sortingOrder.name] as string | number;
      const bValue = b[sortingOrder.name] as string | number;

      if (aValue < bValue) {
        return sortingOrder.reverse ? 1 : -1;
      }
      if (aValue > bValue) {
        return sortingOrder.reverse ? -1 : 1;
      }
      return 0;
    });

    const paginatedProducts = sortedProducts.slice(page * pageSize, (page + 1) * pageSize);

    return {
      filteredProducts: paginatedProducts,
      total: searchedProducts.length
    };
  }, [products, search, sortingOrder, page, pageSize]);

  const renderSimpleValueTableCell = ({
    row,
    column
  }: {
    row: Row<PharmacyProductInventory>;
    column: Column<PharmacyProductInventory>;
  }) => <Box>{row.getValue(column.id)}</Box>;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Product Name',
        enableSorting: true,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'stock',
        header: 'Stock on Hand',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'allocatedStock',
        header: 'Allocated Stock',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'availableStock',
        header: 'Available Stock',
        enableSorting: false,
        cell: renderSimpleValueTableCell
      }
    ],
    [renderSimpleValueTableCell]
  );

  return (
    <Box>
      <Typography variant="h5" component="h2" paddingTop={5} paddingBottom={6}>
        Product Overview
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={(theme) => theme.spacing(4)}
        justifyContent="space-between"
        paddingBottom={6}
      >
        <TableSearchInput value={search} onChange={handleSearch} />
        <Box>
          <Button variant="contained" size="large" onClick={() => setShowAdjustmentModal(true)}>
            Add New Adjustment
          </Button>
        </Box>
      </Stack>
      <Table
        data={filteredProducts}
        columns={columns}
        showPagination
        manualPagination
        pageSize={pageSize}
        total={total}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        onSortingChange={handleSortingOrderChange}
        isLoading={isLoading}
      />
      {showAdjustmentModal && (
        <InventoryAdjustment
          handleClose={() => setShowAdjustmentModal(false)}
          refetchProducts={refetch}
          products={products}
          pharmacyId={pharmacyId?.toString() || ''}
          showAdjustmentModal={showAdjustmentModal}
        />
      )}
    </Box>
  );
}

export default ProductOverview;
