import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

function useGetOrderStatus(allowedStatuses: string[] = []) {
  return useQuery({
    queryKey: ['orderStatus', allowedStatuses],
    queryFn: async () => {
      try {
        const response = await OrderService.getOrderStatus();
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }

        if (allowedStatuses.length > 0) {
          return response.data.filter((status) => allowedStatuses.includes(status.name));
        }

        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch order statuses: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetOrderStatus;
