import type { DropdownOption, InputField, SupportTaskProps } from '@/components/supportSelfService/types';

export const buildIdFromEndpoint = (endpoint: SupportTaskProps['endpoint']) => {
  // Remove leading and trailing slashes
  let sanitized = endpoint.replace(/^\/+|\/+$/g, '');

  // Replace invalid characters with hyphens
  sanitized = sanitized.replace(/[^a-zA-Z0-9\-_:.]/g, '-');

  // Ensure the ID starts with a letter
  if (!sanitized.match(/^[a-zA-Z]/)) {
    sanitized = `id-${sanitized}`;
  }

  return sanitized;
};

type TaskFormValue = string | string[] | number | boolean;

export const buildFormDataRequestBody = (requestBody: Record<string, TaskFormValue>, file?: File | null) => {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    formData.append(key, value.toString());
  });

  if (file) {
    formData.append('file', file);
  }

  return formData;
};

export function buildJsonRequestBody(
  inputs: Record<string, string>,
  inputTextFields?: InputField[],
  dropdowns?: DropdownOption[]
) {
  return Object.keys(inputs).reduce(
    (acc, key) => {
      const field = inputTextFields?.find((el) => el.property === key) ?? dropdowns?.find((el) => el.property === key);
      let value: TaskFormValue = inputs[key];
      const isEmptyValue = !value?.trim();

      if (field?.optional && isEmptyValue) {
        return acc;
      }

      switch (field?.type) {
        case 'file': {
          return acc;
        }
        case 'string': {
          value = value.trim();
          break;
        }
        case 'number': {
          value = parseInt(value, 10);
          break;
        }
        case 'boolean': {
          value = JSON.parse(value);
          break;
        }
        case 'array': {
          value = value?.length ? value.split(',').map((val) => val.trim()) : [];
          break;
        }
        default: {
          break;
        }
      }
      acc[key] = value;
      return acc;
    },
    {} as Record<string, TaskFormValue>
  );
}

type GroupedSupportTasks = Record<SupportTaskProps['category'], SupportTaskProps[]>;

export const groupTasksByCategory = (tasks: SupportTaskProps[]): GroupedSupportTasks =>
  tasks.reduce(
    (groups, task) => ({ ...groups, [task.category]: [...(groups[task.category] || []), task] }),
    {} as GroupedSupportTasks
  );
