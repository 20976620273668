import { Dialog, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import eTokenExample from '../../../assets/images/eTokenExample.png';
import { StyledDialogContent, StyledDialogImage, StyledIconButton } from './TokenDialogStyles';

interface ITokenDialog {
  onClose: () => void;
  open: boolean;
}

export function TokenDialog({ onClose, open }: ITokenDialog) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        /* styling here is tbc, should we allow for the customisation or should we lock to the theme + use fullscreen prop */
        sx: isMobile
          ? {
              width: '100%',
              position: 'fixed',
              bottom: 0,
              margin: 0,
              borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`
            }
          : {
              minWidth: '420px'
            }
      }}
    >
      <DialogTitle sx={{ paddingRight: 16 }}>
        <Typography variant="subtitle1" fontWeight={600} component="span">
          Where to find your token
        </Typography>
      </DialogTitle>
      <StyledIconButton aria-label="close" data-testid="close-token-dialog" onClick={onClose} size="small" sx={{}}>
        <GridCloseIcon />
      </StyledIconButton>
      <StyledDialogContent>
        <StyledDialogImage src={eTokenExample} alt="E token example" />
        <Typography marginTop={6} variant="body2">
          You may have received your electronic prescription via email or SMS. The script token is an 18-digit code that
          accompanies a QR code.
        </Typography>
        <Typography marginTop={6} variant="body2">
          You can also find the script token in the link to your prescription.
        </Typography>
      </StyledDialogContent>
    </Dialog>
  );
}
