import PatientDiscounts from '@/components/admin/patient-profile/PatientDiscounts';
import { PatientNote } from '@/components/admin/patient-profile/PatientNote';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { useGetPatient } from '@/hooks/admin/useGetPatient';
import type { PatientDiscount, PatientNoteData } from '@/types';
import { BrandThemeProvider } from '@montugroup/themes';
import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

export function PatientProfile() {
  const { pathname } = useLocation();
  const { id } = useParams();

  const { data: patientData } = useGetPatient(id ?? '');
  const [patientDiscounts, setPatientDiscounts] = React.useState<PatientDiscount[]>([]);
  const [patientNote, setPatientNote] = React.useState<PatientNoteData[]>([]);
  const [patientID, setPatientID] = React.useState<number>(0);

  React.useEffect(() => {
    if (patientData) {
      setPatientDiscounts(patientData.data.patientDiscounts);
      setPatientNote(patientData.data.notes);
      setPatientID(patientData.data.id);
    }
  }, [patientData]);

  if (pathname.startsWith('/onboard/')) {
    return <Navigate to={`/patient/${id}`} replace />;
  }

  return (
    <BrandThemeProvider variant="montu">
      <AdminPageLayout title="Patient profile">
        {/* coming soon */}
        <PatientDiscounts patientDiscounts={patientDiscounts} />
        <PatientNote patientNote={patientNote} setPatientNote={setPatientNote} patientID={patientID} />
      </AdminPageLayout>
    </BrandThemeProvider>
  );
}
