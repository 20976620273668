import { getTsAdminClient } from '@/api/admin.client';

const client = getTsAdminClient();

export default function useUpdateHelpdeskSettings() {
  const { data, isPending, mutateAsync } = client.updateHelpdeskSettings.useMutation({
    mutationKey: ['updateHelpdeskSettings']
  });

  return {
    data: data?.body.data,
    isPending,
    mutateAsync
  };
}
