import { InfoSharp } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Accordion, Alert, AlertTitle, Box, Typography } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

import settings from '@/constants/constants';
import useGetOrderDetails from '@/hooks/order/useGetOrderDetails';
import useGetOrderReceipt from '@/hooks/order/useGetOrderReceipt';
import { Button } from '@/ui-library';

import { DateTime } from 'luxon';
import OrderStatusChip, { getOrderStatusChipProps } from '../OrderStatusChip/OrderStatusChip';
import OrderBreakdown, { getOrderBreakdownProps } from '../order-breakdown/OrderBreakdown';
import OrderBreakdownLoading from '../order-breakdown/OrderBreakdownLoading';
import type { OrderStage } from '../pharmacy-order-details/PharmacyOrderDetails';
import PharmacyOrderDetails from '../pharmacy-order-details/PharmacyOrderDetails';
import PharmacyOrderDetailsLoading from '../pharmacy-order-details/PharmacyOrderDetailsLoading';

import { FF_ORDER_INTERVAL_TIME } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import FAQButtons from './FAQButtons';
import {
  ActionText,
  DesktopReceiptButtonContainer,
  MobileReceiptButtonContainer,
  OrderDetailsContainer,
  OrderIntervalTimeContainer,
  OrderInvoiceButtonContainer,
  OrderItemsContainer,
  OrderNumberContainer,
  OrderStatusChipMobile,
  OrderStatusContainer,
  OrderSummaryContainer,
  OrderTrackingButtonContainer,
  PharmacyDetailsContainer,
  ReceiptButton,
  SplitOrderAlert,
  StyledAccordionDetails,
  StyledAccordionSummary,
  Wrapper
} from './OrderHistoryCard.styles';
import SplitOrderDetails from './SplitOrderDetails';

export type OrderHistoryCardProps = {
  orderNumber: string;
  orderLink: string;
  receiptLink?: string;
  numberOfItems: number;
  orderDate: Date;
  pharmacyName: string;
  pharmacyLogoUrl?: string;
  orderPrice: string;
  orderStatus: number;
  trackingLink?: string;
  delivered: boolean;
  dispensingFee: string;
  splitOrder?: boolean;
  cancelledDate?: string | null;
  defaultExpanded: boolean;
  intervalDate?: string | null;
};

function ViewReceiptButton({ orderCode, expanded }: { orderCode: string; expanded: boolean }) {
  const { data: receiptData, isError } = useGetOrderReceipt(orderCode, expanded);

  if (receiptData && !isError) {
    return (
      <OrderInvoiceButtonContainer>
        <ReceiptButton href={receiptData.signedUrl} fullWidth variant="outlined">
          <Typography
            variant="body1"
            sx={(theme) => ({
              fontWeight: 600,
              padding: theme.spacing(2),
              color: theme.palette.primary.main,
              borderColor: 'var(--mui-palette-primary-_states-disabled)'
            })}
          >
            View receipt
          </Typography>
        </ReceiptButton>
      </OrderInvoiceButtonContainer>
    );
  }

  return null;
}

export function OrderHistoryCard(props: OrderHistoryCardProps) {
  const {
    orderNumber,
    orderDate,
    pharmacyName,
    orderStatus,
    pharmacyLogoUrl,
    delivered,
    trackingLink,
    dispensingFee,
    splitOrder = false,
    cancelledDate,
    defaultExpanded,
    intervalDate
  } = props;

  const [expanded, setExpanded] = useState(defaultExpanded);
  const orderCancelled = orderStatus === settings.orderStatus.CANCELLED;
  const orderRefunded = orderStatus === settings.orderStatus.REFUNDED;
  const { flags } = useFeatureFlags();

  const {
    data: orderDetails,
    isError: orderDetailsError,
    isLoading: orderDetailsLoading
  } = useGetOrderDetails(orderNumber, expanded);

  const handleAccordionChange = (_e: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const readableOrderDate = orderDate
    .toLocaleDateString('en-GB', {
      weekday: 'short',
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    })
    .replace(/(\d{2}) (\w{3}) (\d{4})/, '$1 $2 $3');

  const formattedCancelledDate = cancelledDate
    ? new Date(cancelledDate)
        .toLocaleDateString('en-GB', {
          weekday: 'short',
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        })
        .replace(/(\d{2}) (\w{3}) (\d{4})/, '$1 $2 $3')
    : null;

  const orderStatusChipProps = getOrderStatusChipProps({ status: orderStatus, delivered, splitOrder });
  const renderReceiptButton = orderDetails && !orderDetailsError && !orderCancelled;
  const renderSplitOrderDetails = splitOrder && orderDetails && !orderDetailsError;
  const intervalTime = flags[FF_ORDER_INTERVAL_TIME] ? orderStatus === settings.orderStatus.INTERVAL_TIME : false;

  return (
    <Wrapper>
      <Accordion defaultExpanded={defaultExpanded} onChange={handleAccordionChange} elevation={0}>
        <StyledAccordionSummary>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <OrderNumberContainer>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    Order #{orderNumber}
                  </Typography>
                  <OrderStatusContainer>
                    <span>
                      <OrderStatusChip {...orderStatusChipProps} />
                    </span>
                  </OrderStatusContainer>
                </OrderNumberContainer>
                <OrderDetailsContainer>
                  <Typography variant="body2">Placed on {readableOrderDate}</Typography>
                </OrderDetailsContainer>
                {orderCancelled ? (
                  <OrderDetailsContainer>
                    <Typography variant="body2">Cancelled on {formattedCancelledDate}</Typography>
                  </OrderDetailsContainer>
                ) : null}
                <OrderStatusChipMobile>
                  <OrderStatusChip {...orderStatusChipProps} />
                </OrderStatusChipMobile>
              </Box>
              <Box>
                <KeyboardArrowDownIcon
                  sx={{
                    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease-in-out'
                  }}
                />
              </Box>
            </Box>
            {intervalTime && (
              <OrderIntervalTimeContainer>
                <Alert severity="info" sx={{ width: '100%' }}>
                  <AlertTitle sx={{ fontWeight: 'bold' }}>Order pending: Interval time</AlertTitle>
                  <Typography variant="body2">
                    This order contains medication in interval time. It will be processed on{' '}
                    {intervalDate && DateTime.fromJSDate(new Date(intervalDate)).toFormat('dd MMM yyyy')}.
                  </Typography>
                </Alert>
              </OrderIntervalTimeContainer>
            )}
          </Box>
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <OrderSummaryContainer>
            {splitOrder && (
              <SplitOrderAlert
                security="info"
                icon={<InfoSharp sx={(theme) => ({ color: theme.palette.info.main })} />}
              >
                <AlertTitle>Items in this order are being delivered separately</AlertTitle>
                <Typography variant="body2" fontWeight="semibold" sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                  This order is being delivered in separate shipments. Please check the status of each split order below
                  to track their delivery progress.{' '}
                </Typography>
              </SplitOrderAlert>
            )}
            {!orderCancelled &&
              !orderRefunded &&
              (renderSplitOrderDetails ? (
                <SplitOrderDetails orderDetails={orderDetails} isLoading={orderDetailsLoading} />
              ) : (
                <PharmacyDetailsContainer>
                  {orderDetailsLoading && <PharmacyOrderDetailsLoading />}
                  {!orderDetailsLoading && orderDetails && !orderDetailsError && (
                    <PharmacyOrderDetails
                      pharmacyName={pharmacyName}
                      pharmacyLogoUrl={pharmacyLogoUrl}
                      fullOrderCode={orderDetails[0].order_code}
                      stage={orderStatusChipProps.status as OrderStage}
                    />
                  )}
                  {trackingLink && (
                    <OrderTrackingButtonContainer>
                      <Button href={trackingLink} fullWidth variant="outlined">
                        <ActionText variant="body1">Track delivery</ActionText>
                      </Button>
                    </OrderTrackingButtonContainer>
                  )}
                </PharmacyDetailsContainer>
              ))}

            <OrderItemsContainer>
              {!orderDetailsLoading && orderDetails ? (
                <OrderBreakdown
                  {...getOrderBreakdownProps({
                    dispensingFee: Number(dispensingFee),
                    orderDetails,
                    isRefundedOrCancelled: orderCancelled || orderRefunded,
                    splitOrder
                  })}
                />
              ) : (
                <OrderBreakdownLoading />
              )}

              {renderReceiptButton && (
                <DesktopReceiptButtonContainer>
                  <ViewReceiptButton expanded={expanded} orderCode={orderDetails[0].order_code} />
                </DesktopReceiptButtonContainer>
              )}
            </OrderItemsContainer>
          </OrderSummaryContainer>

          {renderReceiptButton && (
            <MobileReceiptButtonContainer>
              <ViewReceiptButton expanded={expanded} orderCode={orderDetails[0].order_code} />
            </MobileReceiptButtonContainer>
          )}

          <FAQButtons />
        </StyledAccordionDetails>
      </Accordion>
    </Wrapper>
  );
}

export default OrderHistoryCard;
