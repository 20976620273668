import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Link } from '@mui/material';

type PatientIDTableCellProps = {
  patientId: string;
  patientUniqId: number;
};

function PatientIDTableCell({ patientId, patientUniqId }: PatientIDTableCellProps) {
  const { flags } = useFeatureFlags();

  return (
    <Link href={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${patientUniqId}`} target="_blank">
      {patientId}
    </Link>
  );
}

export default PatientIDTableCell;
