import type { AllocationSuggestion } from '@montugroup/circuit-api-contracts';
import { DateTime } from 'luxon';

// Same day shipping cutoff and order times relative to this TZ
const SHIPPING_TZ = 'Australia/Melbourne';

export const getDateTimeWithShippingTz = (date?: Date): DateTime =>
  date ? DateTime.fromJSDate(date).setZone(SHIPPING_TZ) : DateTime.now().setZone(SHIPPING_TZ);

export function groupByPharmacyId<T extends { pharmacyId: number }>(allocations: T[], pharmacies: { id: number }[]) {
  const allPharmacies = pharmacies.reduce(
    (acc, { id }) => {
      acc[id] = [];
      return acc;
    },
    {} as Record<number, T[]>
  );

  return allocations.reduce((acc, allocation) => {
    if (!acc[allocation.pharmacyId]) {
      acc[allocation.pharmacyId] = [];
    }

    acc[allocation.pharmacyId].push(allocation);
    return acc;
  }, allPharmacies);
}

export function averageDistanceInKmExcludingOrdersWithoutCoordinates(allocations: AllocationSuggestion[]) {
  const allocationsWithDistance = allocations.filter((a) => Boolean(a.distanceFromDeliveryAddressInMeters));

  const totalDistance = allocationsWithDistance.reduce(
    (acc, { distanceFromDeliveryAddressInMeters }) => acc + (distanceFromDeliveryAddressInMeters || 0),
    0
  );

  return Math.round(totalDistance / allocationsWithDistance.length / 1000);
}
